import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import config from "../../config";
import dayjs from "dayjs";
import {
  Space,
  Table,
  Popconfirm,
  Pagination,
  Row,
  Col,
  Breadcrumb,
  Button,
  Input,
  DatePicker,
  Form,
  Select,
  Spin,
} from "antd";
import { NavLink } from "react-router-dom";
import Header from "../Layout/Header";
import { PrinterOutlined } from "@ant-design/icons";
import ProductDropDown from "../Shared/ProductsDropDown";
import ReportsMenu from "./ReportsMenu";
import LoginChecked from "../Shared/LoginChecked";
import moment from "moment";
function DateWiseProductSummary() {
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);
  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");
  const [StartDate, SetStartDate] = useState(dayjs().subtract(1, "month"));
  const [EndDate, SetEndDate] = useState(dayjs());
  const [ProductID, setProductID] = useState(0);
  const [ProductSummary, setProductSummary] = useState([]);
  const ProductType = ProductDropDown();
  const [data, setData] = useState([]);

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  useEffect(() => {
    document.title = "Datewise Product Summary";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      PageNo: pageNo,
      PageSize: pageSize,
      StartDate: StartDate,
      EndDate: EndDate,
      ProductID: ProductID,
    };

   

    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetDateWiseProductSummary",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
     
        if (response.data.status_code == 1) {
          // Handle success
          setProductSummary(response.data.ListofSummary);
          setData(response.data);

          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [StartDate, EndDate, ProductID]);

  const columns = [
    {
      title: "Sr.#	",
      dataIndex: "SerialNo	",
      key: "SerialNo	",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Dated",
      dataIndex: "Dated",
      key: "Dated",
      render: (date) => moment(date).format(config.date_format),
    },
    {
      title: "Op. Stock",
      dataIndex: "OpeningStock",
      key: "OpStock",
    },
    {
      title: "Op.Value	",
      dataIndex: "OpeningStockValue	",
      key: "OpValue",
      align: "right",
    },
    {
      title: "Purchases",
      dataIndex: "Purchase",
      key: "Purchases",
      align: "right",
    },
    {
      title: "Purchases Value",
      dataIndex: "PurchaseValue",
      key: "PurchasesValue",
      align: "right",
    },
    {
      title: "Sales",
      dataIndex: "Sales",
      key: "Sales",
      align: "right",
    },
    {
      title: "Sales Value",
      dataIndex: "SalesValue",
      key: "SalesValue",
      align: "right",
    },
    {
      title: "Closing Stock	",
      dataIndex: "ClosingStock",
      key: "ClosingStock",
    },
    {
      title: "Closing Stock Value",
      dataIndex: "ClosingStockValue",
      key: "ClosingStockValue",
    },
  ];

  const handleDateChange = (date, value) => {
    console.log(value);
    SetStartDate(value[0]);
    SetEndDate(value[1]);
  };
  const disableDate=(current)=>{
    return current && current>moment().endOf('day');
  }

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Datewise Product Summary</h3>

            <div className="header-actions">
              <NavLink to={`/report-datewiseProductSummary-all/print-list/?StartDate=${StartDate}&EndDate=${EndDate}&ProductID=${ProductID}`} target="_blank">
                <Button
                  type="dashed"
                  size="large"
                  shape="round"
                  // style={{ marginBottom: 10 }}
                >
                  <PrinterOutlined />
                  Print Report
                </Button>
              </NavLink>
            </div>
          </div>

          <div className="filters-wrap">
            <Form>
              <Form.Item>
                <RangePicker allowClear onChange={handleDateChange} 
                disabledDate={disableDate}/>
              </Form.Item>
              <Form.Item>
                <Select
                  showSearch
                  style={{
                    width: "200px",
                  }}
                  placeholder="All Product"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value) => setProductID(value)}
                  options={ProductType}
                />
              </Form.Item>
            </Form>
          </div>

          <Table
            size="small"
            scroll={{
              x: "100%",
            }}
            bordered
            loading={loading}
            columns={columns}
            dataSource={ProductSummary}
            // onChange={handleTableChange}
            pagination={false}
          />
        </div>
      </div>
    </>
  );
}

export default DateWiseProductSummary;
