import { StyleSheet, Font } from '@react-pdf/renderer';

import fonts from './fonts/Roboto_Condensed/static/RobotoCondensed-Regular.ttf';
import fontRoboto from './fonts/Roboto_Condensed/static/Roboto-Regular.ttf';


Font.register({
  family: 'Roboto Condensed', 
  src: fonts, 
});
Font.register({
    family: 'Roboto',
    src: fontRoboto, 
  });

const pdfStyles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    fontFamily: 'Roboto Condensed',
  },
  section: {
    margin: 10,
    padding: 10,
    flexDirection: "column",
    marginBottom: 20,
  },
  header: {
    margin: 10,
    padding: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomWidth: 0.5,
  },
  logoside: {
    marginRight: "130px",
    marginLeft: "20px",
  },
  headerinfo:{
    fontFamily: 'Roboto Condensed',
    fontWeight:'bold',
    fontSize:'18pt'
  },
  headercontent: {
    width: 300,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  headertitle: {
    fontSize: "15pt",
    marginTop: "5px",
    fontFamily: 'Roboto Condensed',
    fontWeight:'bold'
  },
  contentwrap: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  contenttitle: {
    fontSize: "10pt",
    marginBottom: 5,
    marginRight: 5,
  },
  contentimg: {
    width: 10,
    height: 10,
    marginBottom: 4,
  },
  title: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  pagetitle:{
    fontSize:'15pt',
    fontWeight:'bold'
  },

  table: {
    display: "flex",
    width: "100%",
    marginBottom: 10,
  },
  tableHead: {
    flexDirection: "row",
    backgroundColor: "rgba(0,0,0,0.05)",
  },
  th: {
    padding: 5,
    borderStyle: "solid",
    borderWidth: 0.2,
    borderColor: "#000",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  thValue: {
    fontSize: "10pt",
    fontWeight: "bold",
    fontFamily:'Roboto'
  },
  row: {
    flexDirection: "row",
  },
  td: {
    padding: 5,
    borderStyle: "solid",
    borderWidth: 0.2,
    borderColor: "#000",
  },
  tdValue: {
    fontSize: "10pt",
    color: "black",
    fontFamily:'Roboto'
  },
  pageBreak: {
    marginBottom: 30,
    breakAfter: 'always',
  },
  footer: {
    padding: "10px 20px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderTopWidth: 1,
    borderTopColor: '#ccc',
    fontSize: '10pt',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  logoContainer: {
    flex: 1,
    alignItems: 'center',
  },
  footerText: {
    textAlign: 'center',
    color: '#333',
    fontSize: '9pt',
  },
  companyText: {
    marginTop: 5,
    fontSize: '9pt',
    color: '#555',
  },
});

export default pdfStyles;
