import React, { useState } from "react";
import { Table } from "antd";

const CloseChat = () => {
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      title: "SR",
      dataIndex: "Sr",
      key: "Sr",
      render: (_, record, index) => index + 1,
    },
    
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
    },
    {
        title: "Dated",
        dataIndex: "Dated",
        key: "Dated",
      },
    {
        title: "Name",
        dataIndex: "Name",
        key: "Name",
      },
    {
      title: "Type",
      dataIndex: "Type",
      key: "Type",
    },
    
    {
      title: "Status ",
      dataIndex: "Status",
      key: "Status",
    },
  ];

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <Table
          size="small"
          scroll={{
            x: "100%",
          }}
          loading={loading}
          columns={columns}
        //   dataSource={}
        //   pagination={false}
        //   onChange={}
          bordered
        />
      </div>
    </>
  );
};

export default CloseChat;
