import React from "react";
import Logo from "../../assets/images/Logo/Logo.svg";
import moment from "moment/moment";
import { Col, Row, Space, Button, theme, Dropdown } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import config from "../../config";

import { ChevronDownIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import {
  CreditCardOutlined,
  EditOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import Notifications from "./Notifications";

function ControlHeader(props) {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const pumpCode = localStorage.getItem("pumpCode");
  const PackageName = localStorage.getItem("PackageName");
  const PackageExpiry = localStorage.getItem("PackageExpiry");
  const Full_Name = localStorage.getItem("AdminFull_Name");
  const PumpName = localStorage.getItem("PumpName");
  const EmailAddress = localStorage.getItem("Email_Address");
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Profile, setProfile] = useState({});
  const [Logo_Login, setLogoLogin] = useState("");
  const [Email, setEmail] = useState("");
  const navigate = useNavigate();

  // useEffect(() => {
  //   setLoading(true);
  //   const data = {
  //     UserID: UserID,
  //     AccessKey: AccessKey,
  //   };
  //   var api_config = {
  //     method: "post",
  //     url: config.base_url + "Members/GetCompanyProfile",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   axios(api_config)
  //     .then(function (response) {
  //       // console.log("Header Response", response.data);
  //       if (response.data.status_code == 1) {
  //         setProfile(response.data);
  //         setLogoLogin(response.data.Logo_Title);
  //         setEmail(response.data.Email);
  //         setLoading(false);
  //       } else {
  //         // Handle error
  //       }
  //     })


  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }, []);

  const handleClick = () => {
    // setActive(!active);
    const side_bar = document.getElementById("side-bar");
    const menubutton = document.getElementById("menubutton");
    const overlaymenu = document.getElementById("overlaymenu");
    if (menubutton) {
      menubutton.classList.toggle("active");
    }

    if (overlaymenu) {
      overlaymenu.classList.toggle("active");
    }

    if (side_bar) {
      side_bar.classList.toggle("active");
    }
  };

  const handleSignOut = () => {
    localStorage.clear();
    navigate("/controlbase/login");
  };

  const items = [
   
    {
      key: "1",
      icon: <LogoutOutlined />,
      label: (
        <a type="button" rel="noopener noreferrer" onClick={handleSignOut}>
          Sign Out
        </a>
      ),
    },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric', year: 'numeric' }).format(date);
  };

  const formattedDate = PackageExpiry ? formatDate(PackageExpiry) : 'N/A';

  return (
    <>
      <header id="dashboard-header">
        <div>
          <div className="header-left">
          <img src={Logo} style={{width: 100}} /> 
          </div>
        </div>
        <div className="header-right">
         
          <Dropdown
            trigger={["click"]}
            menu={{
              items,
            }}
          >
            <div className="button-user-in">
              <div className="short-name">{Full_Name}</div>
              <ChevronDownIcon />
            </div>
          </Dropdown>
        </div>
        <svg
          className={`ham hamRotate ham8`}
          id="menubutton"
          onClick={handleClick}
          viewBox="0 0 100 100"
          width="80"
        >
          <path
            className="line top"
            d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
          />
          <path className="line middle" d="m 30,50 h 40" />
          <path
            className="line bottom"
            d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
          />
        </svg>
      </header>
    </>
  );
}

export default ControlHeader;
