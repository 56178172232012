import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import config from "../../config";
import {
  Col,
  Row,
  Space,
  Spin,
  Tooltip,
  Button,
  Modal,
  Form,
  Input,
  message,
  Checkbox,
  Table,
  Dropdown,
  Menu,
} from "antd";
import {

  DownloadOutlined,
  ShoppingCartOutlined,
  DollarCircleOutlined,
  StockOutlined,
} from "@ant-design/icons";

import dayjs from "dayjs";

import DieselPetrolSalesGraph from "./DieselPetrolSalesGraph";
import { Advances, CalendarIcon, Cash, Dollar, Dots, Expenses, FuelTank, GraphDown, GraphUp, Items, Nozzel, NozzleOil, Paper, PumpMachine, Stock, Tank, TodayCalendar, WeekCalendar, YesterdayCalendar } from "../UserControls/Icons";
import { NumberFormat } from "../Shared/Utility";
import YearlySalesGraph from "./YearlySalesGraph";
import SalesGraph from "./SalesGraph";

function SalesDashboard() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [NoOfAccounts, SetNoOfAccounts] = useState("");
  const [TotalSales, SetTotalSales] = useState("");
  const [TotalExpenses, SetTotalExpenses] = useState("");
  const [TotalPurchases, SetTotalPurchases] = useState("");

  const [data, setData] = useState({});
  const [loadingCahsAccountID, setloadingCahsAccountID] = useState(false);
  const [loadingProductId, setLoadingProductId] = useState(false);
  const [ListCashAccounts, setListCashAccounts] = useState([]);
  const [cashAccountId, setCashAccountId] = useState(0);
  const [productID, setProductID] = useState(0);
  const [listOfSaleProduct, setListOfSaleProduct] = useState([]);
  const [Dated, setDated] = useState(moment().format("YYYY-MM-DD"));
  const [dieselID, setDieselID] = useState(0);
  const [messageApi, contextHolder] = message.useMessage();
  const [ListofLoginLog, SetlistofLoginLog] = useState([]);
  const [ListOfTanks, setListOfTanks] = useState([]);
  const [Summaryreport, setSummaryreport] = useState([]);
  const [EndDate, SetEndDate] = useState(dayjs());
  const [StartDate, SetStartDate] = useState(dayjs().subtract(7, "day"));

  useEffect(() => {
    document.title = "Fuelbook - Dashboard";

    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Members/GetDashboardData",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };


    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          // console.log(response.data)
          setData(response.data);
          SetNoOfAccounts(response.data.NoOfAccounts);
          SetTotalSales(response.data.TotalSales);
          SetTotalExpenses(response.data.TotalExpenses);
          SetTotalPurchases(response.data.TotalPurchases);
          setListOfTanks(response.data.ListofTanks);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setloadingCahsAccountID(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Account_Type_ID: 0,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountsByType",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setListCashAccounts(response.data.listofAccounts);
          setloadingCahsAccountID(false);
        } else {
          setloadingCahsAccountID(false);
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
      });
  }, []);

  
  // useEffect(() => {
  //   setLoading(true);
  //   const data = {
  //     UserID: UserID,
  //     AccessKey: AccessKey,
      
  //   };
  //   console.log(data)
  //   var api_config = {
  //     method: "post",
  //     url: config.base_url + "Sale/GetSalesDashboard",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   axios(api_config)
   
  //     .then(function (response) {
        
  //       if (response.data.status_code == 1) {
  //         console.log(response.data,"------")
  //       } else {
  //         // setloadingCahsAccountID(false);
  //         // Handle error
  //       }
  //       //   setLoading(false);
  //     })
  //     .catch(function (error) {
  //     });
  // }, []);

  useEffect(() => {
    if (!loadingCahsAccountID) {
      const list = ListCashAccounts.filter(
        (item) =>
          item.Account_Type_ID === 1 || item.Account_Type_BE?.Name === "Cash"
      );

      if (list.length > 0) {
        setCashAccountId(list[0].ID);
      }
    }
  }, [ListCashAccounts]);

  useEffect(() => {
    const dashboardParms = {
      AccessKey: AccessKey,
      UserID: UserID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Members/ValidateAccessKey",
      headers: {
        "Content-Type": "application/json",
      },
      data: dashboardParms,
    };

    axios(api_config)
      .then(function (response) { })
      .catch(function (error) {
      });
  }, []);

  useEffect(() => {
    setLoadingProductId(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Created_Date: Dated,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Product/GetProducts",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setListOfSaleProduct(response.data.ListofProducts);
          setLoadingProductId(false);
        } else {
        }
      })
      .catch(function (error) {
      });
  }, []);

  useEffect(() => {
    if (!loadingProductId) {
      const petrolProduct = listOfSaleProduct.find(
        (item) => item.Name === "Petrol"
      );
      const dieselProduct = listOfSaleProduct.find(
        (item) => item.Name === "Hi Speed Diesel"
      );

      if (petrolProduct) {
        setProductID(petrolProduct.ID);
      }
      if (dieselProduct) {
        setDieselID(dieselProduct.ID);
      }
    }
  }, [listOfSaleProduct]);



  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Report/DateWiseSummaryReport ",
      header: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setSummaryreport(response.data.ListofSummary);
          setLoading(false);
        }
      })
      .catch(function (error) {
      });
  }, []);

  const columnsRatewise = [
    {
      title: "Products",
      dataIndex: "Products",
      key: "Products",
      render: (_, record) => (
        <div className="table-avatar">
          <div className="icon">
            <span>{record.Name.split(' ').map(word => word.charAt(0)).join('')}</span>
          </div>
          <div className="content">
            <span className="title">{record.Name}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Quantity Sold",
      dataIndex: "Quantity_Sold",
      key: "Quantity_Sold",
      render: (_, record) => (
        <>
          Ltr. {NumberFormat(record.Quantity_Sold, 2)}
        </>
      )
    },
    {
      title: "Value",
      dataIndex: "Value",
      key: "Value",
      render: (_, record) => (
        <>
          Rs. {NumberFormat(record.Quantity_Sold, 2)}
        </>
      )
    }

  ];
  const columnsItemNotSold = [
    {
      title: "Products",
      dataIndex: "Products",
      key: "Products",
      render: (_, record) => (
        <div className="table-avatar">
          <div className="icon">
            <span>{record.Name.split(' ').map(word => word.charAt(0)).join('')}</span>
          </div>
          <div className="content">
            <span className="title">{record.Name}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Available Quantity",
      dataIndex: "Available_Quantity",
      key: "Available_Quantity",
      render: (_, record) => (
        <>
          Ltr. {NumberFormat(record.Available_Quantity, 2)}
        </>
      )
    }
  ];

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetLoginLogs",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          // Handle success
          SetlistofLoginLog(response.data.listofLogs);
          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
      });
  }, []);


  const columnsCreditSale = [
    {
      title: "Accounts",
      dataIndex: "Accounts",
      key: "Sr",

      render: (_, record) => (
        <div className="table-avatar">
          <div className="icon">
            <span>{record.Name.split(' ').map(word => word.charAt(0)).join('')}</span>
          </div>
          <div className="content">
            <span className="title">{record.Name}</span>
            <span className="description">{record.Balance} {record?.Measure_Unit_BE.Name}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Sales",
      dataIndex: "Sales",
      key: "Sales",
      render: (_, record) => (
        <>
          Rs. {NumberFormat(record.Last_Purchase_Price, 2)}
        </>
      )
    }
  ];

  return (
    <>

      {contextHolder}

      <div className="right-side-contents">
        {loading ? (
          <div className="spin-center">
            <Space size="large">
              <Spin size="large" />
            </Space>
          </div>
        ) : (
          <div className="page-content bg-gray">
            <div className="page-header">
              <h3 className="page-title">Sales Dashboard</h3>
            </div>

            <Row gutter={[20, 20]} className="mb-5">
              <Col sm={24} md={6}>
                <div className="border-card blue">
                  <div className="card-head">
                    <div className="icon-wrap">
                      <TodayCalendar className="icon" />
                    </div>
                    <span>Today’s Sale</span>
                  </div>
                  <div className="card-content">
                    <p><span>Rs.</span> {TotalPurchases}</p>
                  </div>
                </div>
              </Col>
              <Col sm={24} md={6}>
                <div className="border-card yellow">
                  <div className="card-head">
                    <div className="icon-wrap">
                      <YesterdayCalendar className="icon" />
                    </div>
                    <span>Yesterday’s Sale</span>
                  </div>
                  <div className="card-content">
                    <p><span>Rs.</span> {TotalSales}</p>
                  </div>
                </div>
              </Col>
              <Col sm={24} md={6}>
                <div className="border-card purpole">
                  <div className="card-head">
                    <div className="icon-wrap">
                      <WeekCalendar className="icon" />
                    </div>
                    <span>Last Week Sale </span>
                  </div>
                  <div className="card-content">
                    <p><span>Rs.</span> {TotalExpenses}</p>
                  </div>
                </div>
              </Col>
              <Col sm={24} md={6}>
                <div className="border-card orange">
                  <div className="card-head">
                    <div className="icon-wrap">
                      <CalendarIcon className="icon" />
                    </div>
                    <span>Last Month Sale</span>
                  </div>
                  <div className="card-content">
                    <p><span>Rs.</span> {TotalExpenses}</p>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={24}>
                <SalesGraph />
              </Col>
              <Col sm={24} md={8}>
                <div className="fb-card">
                  <div className="fb-card-header">
                    <div className="fb-card-title">
                      <span>
                        <Stock />
                      </span>
                      <h4>Highest Credit Sale Accounts</h4>
                    </div>
                  </div>
                  <div className="fb-card-body table"
                    style={{ '--cardheight': '480px' }}
                  >
                    <Table
                      columns={columnsCreditSale}
                      dataSource={data.ListofStockDetails}
                      pagination={false}
                      scroll={{
                        x: "100%",
                      }}
                      loading={loading} />
                  </div>
                </div>
              </Col>
              <Col sm={24} md={16}>
                <DieselPetrolSalesGraph />
              </Col>
              <Col xs={24} md={24}>
                <YearlySalesGraph />
              </Col>
              <Col xs={24} md={16}>
                <div className="fb-card">
                  <div className="fb-card-header">
                    <div className="fb-card-title">
                      <span>
                        <Dollar />
                      </span>
                      <h4>Rate-wise Sales</h4>
                    </div>
                  </div>
                  <div className="fb-card-body table"
                    style={{ '--cardheight': '400px' }}
                  >
                    <Table
                      columns={columnsRatewise}
                      dataSource={data.ListofStockDetails}
                      pagination={false}
                      scroll={{
                        x: "100%",
                      }}
                      loading={loading} />
                  </div>
                </div>
              </Col>
              <Col xs={24} md={8}>
                <div className="fb-card">
                  <div className="fb-card-header">
                    <div className="fb-card-title">
                      <span>
                        <Items />
                      </span>
                      <h4>Items Not Sold in Last Month</h4>
                    </div>
                  </div>
                  <div className="fb-card-body table"
                    style={{ '--cardheight': '400px' }}
                  >
                    <Table
                      columns={columnsItemNotSold}
                      dataSource={data.ListofStockDetails}
                      pagination={false}
                      scroll={{
                        x: "100%",
                      }}
                      loading={loading} />
                  </div>
                </div>
              </Col>

            </Row>

          </div>
        )}
      </div>
    </>
  );
}

export default SalesDashboard;
