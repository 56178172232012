import React,{useState} from 'react'
import { Skeleton,Row,Col,Table } from 'antd';
import { ArchiveBoxArrowDownIcon, ArchiveBoxXMarkIcon, ShoppingCartIcon } from '@heroicons/react/24/outline';
import {Nozzel,Wallet,Cross} from "../UserControls/Icons";
import { NumberFormat } from '../Shared/Utility';
import ProductChart from './ProductChart';
import PurchaseAndSaleChart from './PurchaseAndSaleChart';
import SaleComperisionChart from './SaleComperisionChart';

function ProductDashboard() {

    const[loading,setLoading]=useState(false);


    const InActiveAccountcolumns = [
       
        {
          title: 'Name',
          dataIndex: 'Name',
          key: 'Name',
        //   render: (_, record) => (
        //     <div className="table-avatar">
        //       <div className="icon">
        //         <span>{"Petrol".split(' ').map(word => word.charAt(0)).join('')}</span>
        //       </div>
        //       <div className="content">
        //         <span className="title">{record.Name}</span>
        //       </div>
        //     </div>
        //   ),
        },
        {
            title: 'Available Quantity',
            dataIndex: 'Available Quantity',
            key: 'Available Quantity',
            
          }
    
      ];
      
  return (
    <div className="right-side-contents">
        <div className="page-content bg-gray">
          <div className="page-header">
            <h3 className="page-title main-dashboard-title">Products Dashboard</h3>
          </div>
          {
            loading ?
              <>
                <Skeleton active />
              </>
              :
              <>
                <Row gutter={[20, 20]}>
                  <Col sm={24} md={8}>
                    <div className="border-card blue">
                      <div className="card-head">
                        <div className="icon-wrap">
                          <ShoppingCartIcon style={{color:'rgb(64, 82, 137)'}} />
                        </div>
                        <span>Products Available</span>
                      </div>
                      <div className="card-content">
                       
                        <p><span>Ltr.</span> {NumberFormat(18260, 2)}</p>
                      </div>
                    </div>

                  </Col>
                  <Col sm={24} md={8}>
                    <div className="border-card yellow">
                      <div className="card-head">
                        <div className="icon-wrap">
                          <Nozzel />
                        </div>
                        <span>Nozzle Machines</span>
                      </div>
                      <div className="card-content">
                        
                        <p><span>No.</span>16</p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={24} md={8}>
                    <div className="border-card purpole">
                      <div className="card-head">
                        <div className="icon-wrap">
                          <Wallet />
                        </div>
                        <span>Stock Value</span>
                      </div>
                      <div className="card-content">
                        <p><span>Rs.</span> {NumberFormat(152100, 2)}</p>
                      </div>
                    </div>
                  </Col>
                  </Row>

            <div className="dash-card" style={{ marginBottom: 24, marginTop:24 }}>
              <Row gutter={24}>
                <Col xs={24} md={24}>
                <ProductChart />
                </Col>
              </Row>
            </div>
            <Row gutter={[24,24]}>
            <Col md={11}>
                    <div className="fb-card">
                      <div className="fb-card-header">
                        <div className="fb-card-title">
                          <span>
                            <ArchiveBoxXMarkIcon />
                          </span>
                          <h4>Products Not Sold in Last Month</h4>
                        </div>
                      </div>
                      <div className="fb-card-body table"
                        style={{ '--cardheight': '300px' }}
                      >
                        <Table 
                        columns={InActiveAccountcolumns}
                        //  dataSource={data.InActiveAccounts} 
                         pagination={false} />
                      </div>
                    </div>

                  </Col>
                  <Col md={13} sm={24} >
                  <PurchaseAndSaleChart />
                  </Col>
                  
            </Row>

            <div style={{ marginBottom: 24, marginTop:24 }}>
              <Row gutter={24}>
                <Col xs={24} md={24}>
                <SaleComperisionChart />
                </Col>
              </Row>
            </div>
              </>
          }
        </div>
      </div>
  )
}

export default ProductDashboard