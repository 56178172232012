import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
import { ArrowTrendingUpIcon } from "@heroicons/react/24/outline";

import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import dayjs from "dayjs";
import { Select, Skeleton } from "antd";
import { YearlySale } from "../UserControls/Icons";


ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

function SalesGraph(props) {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  const [StartDate, setStartDate] = useState(dayjs().subtract(30, "day").format("YYYY-MM-DD"));
  const [EndDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));

  const [Value, setValue] = useState(30);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
    };


    var api_config = {
      method: "post",
      url: config.base_url + "Sale/GetSalesGraph",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          // Handle success
          const data = response.data.SalesGraph;
          const labels = data.map((item) =>
            dayjs(item.Period).format("DD MMM")
          );
          const salesData = data.map((item) => item.TotalSales);

          setChartData({
            labels,
            datasets: [
              {
                label: false,
                data: salesData,
                backgroundColor: "rgb(32, 70, 205)",
                borderColor: "rgb(32, 70, 205)",
                borderWidth: 2,
                fill: false,
                stepped: true,
              },
            ],
          });

          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  }, [StartDate, EndDate]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,

      },
    },
    scales: {
      x: {
        display: true,
        beginAtZero: false,
        ticks: {
          color: 'rgb(71, 71, 71)',
        },
        grid: {
          color: 'rgb(207, 207, 207)',
          display: true,
        },
      },
      y: {
        display: true,
        beginAtZero: false,

        ticks: {
          color: 'rgb(71, 71, 71)',
          min: 1,
        },
        grid: {
          color: 'rgb(179, 179, 179)',
        },
      },
    },
  };

  const handleDateChange = (value) => {
    setValue(value);
    setEndDate(dayjs().format("YYYY-MM-DD"));
    setStartDate(dayjs().subtract(value, "day").format("YYYY-MM-DD"));
  };

  return (
    <>

      <div className="fb-card">
        <div className="fb-card-header">
          <div className="fb-card-title">
            <span>
              <ArrowTrendingUpIcon />
            </span>
            <h4>Sales</h4>
          </div>
          <div className="header-action">
            <Select
              style={{ width: 200 }}
              placeholder="Select duration"
              onChange={handleDateChange}
              value={Value}
              options={[
                {
                  label: "Last 15 Days",
                  value: 15,
                },
                {
                  label: "Last 30 Days",
                  value: 30,
                },
              ]}
            />
          </div>
        </div>
        <div className="fb-card-body table">
          {loading ? (
            <Skeleton active />
          ) : (
            <>
              <Line
                data={chartData}
                options={options}
              />
            </>
          )}
        </div>
      </div>

    </>
  );
}

export default SalesGraph;
