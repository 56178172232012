import React, { useState, useEffect } from "react";
import Header from "../../../Pdf/PrintHeaderAccount";
import axios from "axios";
import config from "../../../config";
import moment from "moment";
import pdfStyles from "../../../Pdf/pdfStyles";
import PrintFooter from "../../../Pdf/PrintFooter";
import { PrintFormateNumber } from "../../../Pdf/PrintFormateNumber";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { Spin, Space } from "antd";

function PrintProfitOnSale() {
  const [laodng, setLoading] = useState(false);
  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");
  const [ShowDetails, SetShowDetails] = useState(true);

  const urlParams = new URLSearchParams(window.location.search);
  const EndDate = urlParams.get("EndDate");
  const StartDate = urlParams.get("StartDate");
  const ProductID = urlParams.get("ProductID");
  const SupplierID = urlParams.get("SupplierID");
  // const ShowDetails = urlParams.get("ShowDetails");
  const [data, setData] = useState([]);

  console.log(ShowDetails)

  const [listProfitOnSale, setListProfitOnSale] = useState([]);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      ProductID: ProductID,
      SupplierID: SupplierID,
      ShowDetails: ShowDetails,
      PageNo: 1,
      PageSize: 2000,
    };

    console.log("this is data", data);
    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetProfitOnSalesReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          setListProfitOnSale(response.data.ListofProducts);
          setData(response.data);

          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);



  const generateData = () => {
    if (listProfitOnSale.length > 0) {
      return listProfitOnSale.map((item, index) => {
        return (
          <View key={index} style={pdfStyles.row}>
            <View style={[pdfStyles.td, { width: "40" }]}>
              <Text style={pdfStyles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[pdfStyles.td, { width: "60" }]}>
              <Text style={pdfStyles.tdValue}>
                {moment(item.Created_Date).format(config.date_format)}
              </Text>
            </View>
            <View style={[pdfStyles.td, { width: "80" }]}>
              <Text style={pdfStyles.tdValue}>{item.Name}</Text>
            </View>
            <View style={[pdfStyles.td, { width: "80" }]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.Balance,2)}</Text>
            </View>
            <View style={[pdfStyles.td, { width: "80", textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.Sale_Price,2)}</Text>
            </View>
            <View style={[pdfStyles.td, { width: "80", textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.Amount,2)}</Text>
            </View>
            <View style={[pdfStyles.td, { width: "80", textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.Last_Purchase_Price,2)}</Text>
            </View>
            <View style={[pdfStyles.td, { width: "80", textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.Profit,2)}</Text>
            </View>
          </View>
        );
      });
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <Header />
        <View style={pdfStyles.section}>
          <View style={pdfStyles.title}>
            <Text style={pdfStyles.pagetitle}>Profit On Sale</Text>
            <Text style={{fontSize: "10pt" }} >Start Date:{moment(StartDate).format(config.date_format)}   EndDate:{moment(EndDate).format(config.date_format)}</Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: "40" }]}>
                <Text style={pdfStyles.thValue}>
                  SR
                  <br /> No
                </Text>
              </View>
              <View style={[pdfStyles.th, { width: "60" }]}>
                <Text style={pdfStyles.thValue}> Dated</Text>
              </View>
              <View style={[pdfStyles.th, { width: "80" }]}>
                <Text style={pdfStyles.thValue}> Product</Text>
              </View>
              <View style={[pdfStyles.th, { width: "80" }]}>
                <Text style={pdfStyles.thValue}>Quantity</Text>
              </View>
              <View style={[pdfStyles.th, { width: "80", textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Sale Price</Text>
              </View>
              <View style={[pdfStyles.th, { width: "80", textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Amount</Text>
              </View>
              <View style={[pdfStyles.th, { width: "80", textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Buying Price</Text>
              </View>
              <View style={[pdfStyles.th, { width: "80", textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Profit</Text>
              </View>
            </View>

            {generateData()}
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: "500", textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Total Sale</Text>
              </View>
              <View style={[pdfStyles.th, { width: "80" }]}>
                <Text style={[pdfStyles.thValue, { textAlign: "right" }]}>
                {PrintFormateNumber(Number(data.TotalSales),2)}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: "500", textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Profit On Sale</Text>
              </View>
              <View style={[pdfStyles.th, { width: "80" }]}>
                <Text style={[pdfStyles.thValue, { textAlign: "right" }]}>
                {PrintFormateNumber(Number(data.ProfitOnSales),2)}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: "500", textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Stock Gains</Text>
              </View>
              <View style={[pdfStyles.th, { width: "80" }]}>
                <Text style={[pdfStyles.thValue, { textAlign: "right" }]}>
                {PrintFormateNumber(Number(data.totalGain),2)}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: "500", textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Other Income</Text>
              </View>
              <View style={[pdfStyles.th, { width: "80" }]}>
                <Text style={[pdfStyles.thValue, { textAlign: "right" }]}>
                {PrintFormateNumber(Number(0),2)}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: "500", textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Gross Profit</Text>
              </View>
              <View style={[pdfStyles.th, { width: "80" }]}>
                <Text style={[pdfStyles.thValue, { textAlign: "right" }]}>
                {PrintFormateNumber(Number(data.GrossProfit),2)}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: "500", textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}></Text>
              </View>
              <View style={[pdfStyles.th, { width: "80" }]}>
                <Text style={[pdfStyles.thValue, { textAlign: "right" }]}>
                ----------
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: "500", textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Total Expenses</Text>
              </View>
              <View style={[pdfStyles.th, { width: "80" }]}>
                <Text style={[pdfStyles.thValue, { textAlign: "right" }]}>
                {PrintFormateNumber(data.TotalExpenes,0)}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: "500", textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Total Discounts</Text>
              </View>
              <View style={[pdfStyles.th, { width: "80" }]}>
                <Text style={[pdfStyles.thValue, { textAlign: "right" }]}>
                {PrintFormateNumber(data.TotalDiscount,2)}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: "500", textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Total Withdrawal</Text>
              </View>
              <View style={[pdfStyles.th, { width: "80" }]}>
                <Text style={[pdfStyles.thValue, { textAlign: "right" }]}>
                {PrintFormateNumber(data.TotalWithDrawal,2)}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: "500", textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Stock Losses</Text>
              </View>
              <View style={[pdfStyles.th, { width: "80" }]}>
                <Text style={[pdfStyles.thValue, { textAlign: "right" }]}>
                {PrintFormateNumber(data.totalLoss,2)}
                </Text>
              </View>
            </View>
            

            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: "500", textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Gross Expense</Text>
              </View>
              <View style={[pdfStyles.th, { width: "80" }]}>
                <Text style={[pdfStyles.thValue, { textAlign: "right" }]}>
                {PrintFormateNumber(data.GrossExpenses,2)}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: "500", textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}></Text>
              </View>
              <View style={[pdfStyles.th, { width: "80" }]}>
                <Text style={[pdfStyles.thValue, { textAlign: "right" }]}>
                ----------
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: "500", textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Net Profit</Text>
              </View>
              <View style={[pdfStyles.th, { width: "80" }]}>
                <Text style={[pdfStyles.thValue, { textAlign: "right" }]}>
                {PrintFormateNumber((data.GrossProfit-data.GrossExpenses),0)}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{ marginBottom: 60, marginTop: 0, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
        <PrintFooter fixed/>
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {laodng ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="sample.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
}

export default PrintProfitOnSale;
