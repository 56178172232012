import React, { useEffect, useState } from "react";
import pdfStyles from "../../../Pdf/pdfStyles";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import moment from "moment";
import { Spin } from "antd";
import axios from "axios";
import config from "../../../config";
import PrintHeaderAccount from "../../../Pdf/PrintHeaderAccount";
import { useParams } from "react-router-dom";
import PrintFooter from "../../../Pdf/PrintFooter";
import { PrintFormateNumber } from "../../../Pdf/PrintFormateNumber";

const PrintProductList = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const Dated = urlParams.get("Date");
  const OrderBy = urlParams.get("OrderBy");
  const HideZeroBalance = urlParams.get("HideZeroBalance");
  const HideNegativeBalance = urlParams.get("HideNegativeBalance");

  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");

  const [ListofProducts, SetListofProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Date: Dated,
      OrderBy: OrderBy,
      HideZeroBalance: HideZeroBalance,
      HideNegativeBalance: HideNegativeBalance,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Product/GetProducts",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          // Handle success
          SetListofProducts(response.data.ListofProducts);
          setData(response.data);
          setLoading(false);
        } else {
          setLoading(false);
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const generateData = () => {
    if (ListofProducts && ListofProducts.length > 0) {
      return ListofProducts.map((item, index) => (
        <View key={index} style={pdfStyles.row}>
          <View style={[pdfStyles.td, { width: 40 }]}>
            <Text style={pdfStyles.tdValue}>{index + 1}</Text>
          </View>
          <View style={[pdfStyles.td, { width: 150 }]}>
            <Text style={pdfStyles.tdValue}>{item.Name}</Text>
          </View>
          <View style={[pdfStyles.td, { width: 60 }]}>
            <Text style={pdfStyles.tdValue}>{item.ProductCode}</Text>
          </View>
          <View style={[pdfStyles.td, { width: 60, textAlign: "right" }]}>
            <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.Sale_Price,2)}</Text>
          </View>
          <View style={[pdfStyles.td, { width: 90, textAlign: "right" }]}>
            <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.Last_Purchase_Price,2)}</Text>
          </View>
          <View style={[pdfStyles.td, { width: 80, textAlign: "right" }]}>
            <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.Balance,2)}</Text>
          </View>
          <View style={[pdfStyles.td, { width: 90, textAlign: "right" }]}>
            <Text style={pdfStyles.tdValue}>
              {PrintFormateNumber(item.Balance * item.Last_Purchase_Price,0)}
            </Text>
          </View>
        </View>
      ));
    } else {
      return null;
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <PrintHeaderAccount />
        <View style={pdfStyles.section}>
          <View style={pdfStyles.title}>
            <Text style={pdfStyles.pagetitle}>STOCK DETAILS</Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 40 }]}>
                <Text style={pdfStyles.thValue}>Sr No.</Text>
              </View>
              <View style={[pdfStyles.th, { width: 150 }]}>
                <Text style={pdfStyles.thValue}>Name</Text>
              </View>
              <View style={[pdfStyles.th, { width: 60 }]}>
                <Text style={pdfStyles.thValue}>Code</Text>
              </View>
              <View style={[pdfStyles.th, { width: 60, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Sale Price</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Last Purchase price</Text>
              </View>
              <View style={[pdfStyles.th, { width: 80, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Current Qty</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Stock Value</Text>
              </View>
            </View>
            {generateData()}
            <View style={pdfStyles.tableHead}>
              <View
                style={[pdfStyles.th, { width: "480", textAlign: "right" }]}
              >
                <Text style={[pdfStyles.thValue, { fontSize: "9pt" }]}>
                  Total Stock Value
                </Text>
              </View>
              <View
                style={[pdfStyles.th, { width: "90", textAlign: "right" }]}
              >
                <Text style={[pdfStyles.thValue, { fontSize: "9pt" }]}>
                  {PrintFormateNumber(data.TotalStockValue,0)}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{ marginBottom: 50, marginTop: 0, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
        <PrintFooter fixed />
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="sample.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
};

export default PrintProductList;
