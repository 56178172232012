import React, { useEffect, useState } from "react";
import config from "../../config";
import axios from "axios";
import {
  Space,
  Card,
  Form,
  Select,
  Spin,
  DatePicker,
  Table,
  Popconfirm,
  Pagination,
  Row,
  Col,
  Tooltip,
  Button,
  Input,
  message,
} from "antd";
import { NavLink, Link } from "react-router-dom";
import {
  CreditCardOutlined,
  DeleteOutlined,
  EditOutlined,
  ProfileOutlined,
  SearchOutlined,
  PlusOutlined,
  PrinterOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

const Packges = () => {
  const UserID = localStorage.getItem("AdminID");
  const AcccessKey = localStorage.getItem("AdminAccessKey");
  const MemberType = localStorage.getItem("MemberType");
  const [loading, setLoading] = useState(false);
  const [ListOfPackages, setListOfPackages] = useState("");

  useEffect(() => {
    document.title = "Packages";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AcccessKey,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "AdminUser/GetPackagesList",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {

        if (response.data.status_code == 1) {
          // console.log(response.data)
          setListOfPackages(response.data.ListofRecords);

          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        message.error("Network Error");
        console.log(error);
      });
  }, []);

  const columns = [
    // {
    //   title: "SR",
    //   dataIndex: "Sr",
    //   key: "Sr",
    //   render: (_, record, index) => index + 1,
    // },
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "name",
    },
    {
      title: "Price",
      dataIndex: "Price",
      key: "Price",
    },
    {
      title: "Savings",
      dataIndex: "Savings",
      key: "Savings",
    },

    {
      title: "Tags",
      dataIndex: "Tags",
      key: "Tags",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <div style={{ textAlign: "center", display: "flex", gap: 10 }}>
            <Tooltip title="Delete Packge">
              <NavLink to={`/`}>
                <DeleteOutlined />
              </NavLink>
            </Tooltip>
            <Tooltip title="Edit Packge">
              <NavLink to={`/controlbase/addpackges/?ID=${record.ID}`}>
                <EditOutlined />
              </NavLink>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <>
        <div className="right-side-contents">
          <div className="page-content">
            <div className="page-header">
              <h3 className="page-title">Packges</h3>

              <div className="header-actions">
                <NavLink to="/controlbase/addpackges">
                  <Button
                    type="primary"
                    size="large"
                    shape="round"
                    icon={<PlusOutlined style={{ color: "white" }} />}
                  >
                    Add New Packges
                  </Button>
                </NavLink>
              </div>
            </div>
            <Table
              size="small"
              scroll={{
                x: "100%",
              }}
              loading={loading}
              columns={columns}
              dataSource={ListOfPackages}
              // onChange={handleTableChange}
              pagination={false}
              bordered
            />
          </div>
        </div>
      </>
    </>
  );
};

export default Packges;
