import React, { useEffect, useState } from "react";
import { Space, Spin, Row, Col, Table } from "antd";
import {
  ArrowTrendingUpIcon,
  ShoppingCartOutlined,
  ChartPieIcon,
  CurrencyDollarIcon,
  HomeIcon,
  PresentationChartLineIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline";
import config from "../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import UsersChart from "./UsersChart";
import { DollarCircleOutlined } from "@ant-design/icons";
import users from "../assets/images/users.png";
import totalRevenue from "../assets/images/TotalRevenue.png";
import clalanderRevenue from "../assets/images/clalanderRevenue.png";
import subscription from "../assets/images/subscription.png";
import SubscriptionChart from "./SubscriptionChart";
import SubscriptionRateChart from "./SubscriptionRateChart";
function Admin() {
  const AccessKey = localStorage.getItem("AdminAccessKey");
  const UserID = localStorage.getItem("AdminID");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  let navigate = useNavigate();

  useEffect(() => {
    document.title = "Fuelbook Admin Dashboard";
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: "Admin",
    };

    var api_config = {
      method: "post",
      url: config.base_url + "AdminUser/GetMainDashboard",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setData(response.data);
          setLoading(false);
        } else if (
          response.data.status_code === 0 ||
          response.data.status_code === 2
        ) {
          navigate("/controlbase/login");
        } else if (response.data.status_message === "Invalid Access Key.") {
          navigate("/controlbase/login");
        } else {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const NewUsers = [
    {
      title: "SR",
      dataIndex: "Sr",
      render: (_, record, index) => index + 1,
    },
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
    },
    {
      title: "Dated",
      dataIndex: "Created_Date",
      key: "dated",
      render: (date) => dayjs(date).format(config.date_format),
    },
    {
      title: "Pump Code",
      dataIndex: "PumpCode",
      key: "PumpCode",
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "name",
    },

    {
      title: "Phone",
      dataIndex: "Phone",
      key: "Phone",
    },
    {
      title: "Mobile",
      dataIndex: "Mobile",
      key: "mobile",
    },

    {
      title: "Type",
      dataIndex: "PackageName",
      key: "type",
      align: "center",
      render: (text, record) => {
        if (record.PackageName == "Trial") {
          return (
            <div
              style={{
                backgroundColor: "#EF476F33",
                color: "#EF476F",
                borderRadius: "20px",
                textAlign: "center",
              }}
            >
              {text}
            </div>
          );
        } else {
          return text;
        }
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "action",
    },
  ];
  const columns = [
    {
      title: "SR",
      dataIndex: "Sr",
      key: "Sr",
      render: (_, record, index) => index + 1,
    },
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
    },
    {
      title: "Dated",
      dataIndex: "Created_Date",
      key: "dated",
      render: (date) => dayjs(date).format(config.date_format),
    },
    {
      title: "Pump Code",
      dataIndex: "PumpCode",
      key: "Pump_Code",
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "name",
    },

    {
      title: "Phone",
      dataIndex: "Phone",
      key: "phone",
    },
    {
      title: "Mobile",
      dataIndex: "Mobile",
      key: "mobile",
    },

    {
      title: "Type",
      dataIndex: "PackageName",
      key: "type",
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "action",
    },
  ];

  return (
    <>
      <div className="right-side-contents">
        {loading ? (
          <div className="spin-center">
            <Space size="large">
              <Spin size="large" />
            </Space>
          </div>
        ) : (
          <div className="page-content control-base-wrap">
            <div className="page-header">
              <h3 className="page-title">Admin Dashboard</h3>
            </div>

            <Row gutter={[16, 16]}>
              <Col xs={24} md={8}>
                <div className="fb-card">
                  <div className="fb-card-header">
                    <div className="fb-card-title">
                      <span>
                        <img src={users} className="img-icon" alt="" />
                      </span>
                      <h4>Overview</h4>
                    </div>
                  </div>
                  <div className="fb-card-body">
                    <UsersChart />
                  </div>

                  <div className="subscription-chart-wrap">
                    <ul className="subscription-list">
                      <li>
                        <p>Monthly Package </p>
                        <b>5</b>
                      </li>
                      <li>
                        <p>6-Month Package </p>
                        <b>0</b>
                      </li>
                      <li>
                        <p>Yearly Package </p>
                        <b>9</b>
                      </li>
                      <li>
                        <p>Lifetime Package </p>
                        <b>5</b>
                      </li>
                    </ul>
                    <ul className="subscription-grapht-list">
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>

                  {/* <SaleChart /> */}
                </div>
              </Col>
              <Col xs={24} md={16}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={12}>
                    <div className="border-card purpole">
                      <div className="card-head">
                        <span>
                          <img src={totalRevenue} className="img-icon" alt="" />
                        </span>
                        <span>Total Revenue</span>
                      </div>
                      <div className="card-content">
                        <p>
                          <span>Rs.</span>
                          22,9129 {""}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className="border-card orange">
                      <div className="card-head">
                        <span>
                          <img
                            src={clalanderRevenue}
                            className="img-icon"
                            alt=""
                          />
                        </span>
                        <span>Monthly Revenue</span>
                      </div>
                      <div className="card-content">
                        <p>
                          <span>Rs.</span>
                          287,897 {""}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} md={24}>
                    <div className="fb-card">
                      <div className="fb-card-header">
                        <div className="fb-card-title">
                          <span>
                            <img
                              src={subscription}
                              className="img-icon"
                              alt=""
                            />
                          </span>
                          <h4>Subscription Insights</h4>
                        </div>
                      </div>
                      <ul className="subscription">
                        <li style={{ width: "33.33%" }}>
                          <SubscriptionChart />
                          <div className="sub-content">
                            <p>Retention rate</p>
                            <h6>67%</h6>
                          </div>
                        </li>
                        <li
                          style={{
                            width: "33.33%",
                            borderRight: "1px solid #e7e7e7",
                          }}
                        >
                          <SubscriptionRateChart />
                          <div className="sub-content">
                            <p>Account Drop rate</p>
                            <h6>76%</h6>
                          </div>
                        </li>
                        <li style={{ width: "33.33%", paddingLeft: "10px" }}>
                          <div className="sub-content">
                            <p>Top Plan</p>
                            <h6>Monthly</h6>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <h4>Key Metrics Overview</h4>
            <div className="white-box">
              <ul className="list-info-items">
                <li>
                  <p>Total User</p>
                  <h1>{data.totalUsers}</h1>
                </li>

                <li>
                  <p>Active Users</p>
                  <h1>{data.activeUsers}</h1>
                </li>

                <li>
                  <p>Monthly Package</p>
                  <h1>{data.monthlyPackages}</h1>
                </li>

                <li>
                  <p>6-Month Package</p>
                  <h1>{data.sixMonthlyPackages}</h1>
                </li>

                <li>
                  <p>Yearly Package</p>
                  <h1>{data.yearlyPackages}</h1>
                </li>
                <li>
                  <p>Lifetime Package</p>
                  <h1>{data.lifetimePackages}</h1>
                </li>
              </ul>
            </div>
            <h4>Financial Overview</h4>
            <Row gutter={10}>
              <Col md={8}>
                <div className="white-box with-icon">
                  <div className="card-left-content">
                    <span>
                      <CurrencyDollarIcon
                        height={30}
                        width={30}
                        stroke="#1976D2"
                      />
                    </span>
                    <p>Total Revenue</p>
                  </div>
                  <h4>Rs. {data.revenueTotal}</h4>
                </div>
              </Col>
              <Col md={8}>
                <div className="white-box with-icon">
                  <div className="card-left-content">
                    <span>
                      <CurrencyDollarIcon
                        height={30}
                        width={30}
                        stroke="#1976D2"
                      />
                    </span>
                    <p>This Month</p>
                  </div>
                  <div className="right-content">
                    <h4>Rs. {data.revenueThisMonth}</h4>
                    <span>{dayjs().format("MMM YYYY")}</span>
                  </div>
                </div>
              </Col>
            </Row>

            <h4>Subscription Insights</h4>
            <Row gutter={10}>
              <Col md={8}>
                <div className="white-box with-icon">
                  <div className="card-left-content">
                    <span>
                      <ArrowTrendingUpIcon
                        height={28}
                        width={30}
                        stroke="#1976D2"
                      />
                    </span>
                    <p>Subscription Retention Rate</p>
                  </div>
                  <h4>{data.subscriptionRetentionRate}%</h4>
                </div>
              </Col>
              <Col md={8}>
                <div className="white-box with-icon">
                  <div className="card-left-content">
                    <span>
                      <ChartPieIcon height={28} width={30} stroke="#1976D2" />
                    </span>
                    <p>Account Drop Rate</p>
                  </div>
                  <h4>{data.subscriptionDropRate}%</h4>
                </div>
              </Col>
              <Col md={8}>
                <div className="white-box with-icon">
                  <div className="card-left-content">
                    <span>
                      <PresentationChartLineIcon
                        height={28}
                        width={30}
                        stroke="#1976D2"
                      />
                    </span>
                    <p>Top-performing Plan</p>
                  </div>
                  <h4>{data.subscriptionDropRate}</h4>
                </div>
              </Col>
            </Row>
            <h4>New Users (Current Month)</h4>
            <Table
              size="small"
              scroll={{
                x: "100%",
              }}
              loading={loading}
              dataSource={data.NewCompanies}
              columns={NewUsers}
              pagination={false}
              bordered
            />
            <h4>Suspended or Deactivated Accounts:</h4>
            <div style={{ marginBottom: "50px" }}>
              <Table
                size="small"
                scroll={{
                  x: "100%",
                }}
                dataSource={data.SuspendedCompanies}
                loading={loading}
                columns={columns}
                pagination={false}
                bordered
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Admin;
