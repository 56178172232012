import React from "react";
import { Row, Col, Card } from "antd";

const MainPage = () => {
  return (
    <div className="right-side-contents">
      <div
        className="main-page"
        style={{ maxWidth: "900px", margin: "100px auto" }}
      >
        <Row gutter={[10,10]}>
          <Col md={5}>
            <div className="info-box green">
              <a href=""></a>
              <div className="icon">
                <svg
                  width="38"
                  height="40"
                  viewBox="0 0 38 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M36.0156 32.3546C35.0893 30.1603 33.745 28.1672 32.0576 26.4863C30.3754 24.8005 28.3826 23.4564 26.1893 22.5282C26.1697 22.5184 26.15 22.5135 26.1304 22.5037C29.1898 20.2939 31.1786 16.6943 31.1786 12.6332C31.1786 5.90548 25.7277 0.45459 19 0.45459C12.2723 0.45459 6.82145 5.90548 6.82145 12.6332C6.82145 16.6943 8.81029 20.2939 11.8697 22.5086C11.85 22.5184 11.8304 22.5233 11.8107 22.5332C9.61074 23.4613 7.63663 24.7921 5.94243 26.4912C4.25663 28.1734 2.91254 30.1662 1.9844 32.3595C1.07259 34.5067 0.580827 36.8088 0.535737 39.1412C0.534426 39.1936 0.543619 39.2457 0.562774 39.2946C0.58193 39.3434 0.610659 39.3878 0.647271 39.4254C0.683882 39.4629 0.727635 39.4927 0.77595 39.5131C0.824265 39.5334 0.876166 39.5439 0.928594 39.5439H3.87502C4.09109 39.5439 4.26297 39.372 4.26788 39.1608C4.36609 35.3698 5.88841 31.8193 8.57949 29.1282C11.3639 26.3439 15.0616 24.8117 19 24.8117C22.9384 24.8117 26.6362 26.3439 29.4206 29.1282C32.1116 31.8193 33.6339 35.3698 33.7322 39.1608C33.7371 39.3769 33.909 39.5439 34.125 39.5439H37.0714C37.1239 39.5439 37.1758 39.5334 37.2241 39.5131C37.2724 39.4927 37.3162 39.4629 37.3528 39.4254C37.3894 39.3878 37.4181 39.3434 37.4373 39.2946C37.4564 39.2457 37.4656 39.1936 37.4643 39.1412C37.4152 36.7939 36.929 34.5104 36.0156 32.3546ZM19 21.0796C16.746 21.0796 14.6246 20.2006 13.0286 18.6046C11.4326 17.0086 10.5536 14.8872 10.5536 12.6332C10.5536 10.3791 11.4326 8.25771 13.0286 6.66173C14.6246 5.06575 16.746 4.18673 19 4.18673C21.254 4.18673 23.3755 5.06575 24.9714 6.66173C26.5674 8.25771 27.4465 10.3791 27.4465 12.6332C27.4465 14.8872 26.5674 17.0086 24.9714 18.6046C23.3755 20.2006 21.254 21.0796 19 21.0796Z"
                    fill="#78B7F2"
                  />
                </svg>
              </div>
              <div className="info">
                <h4>Account</h4>
              </div>
            </div>
          </Col>
          <Col md={5}>
            <div className="info-box green">
              <a href=""></a>
              <div className="icon">
                <svg
                  width="38"
                  height="40"
                  viewBox="0 0 38 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M36.0156 32.3546C35.0893 30.1603 33.745 28.1672 32.0576 26.4863C30.3754 24.8005 28.3826 23.4564 26.1893 22.5282C26.1697 22.5184 26.15 22.5135 26.1304 22.5037C29.1898 20.2939 31.1786 16.6943 31.1786 12.6332C31.1786 5.90548 25.7277 0.45459 19 0.45459C12.2723 0.45459 6.82145 5.90548 6.82145 12.6332C6.82145 16.6943 8.81029 20.2939 11.8697 22.5086C11.85 22.5184 11.8304 22.5233 11.8107 22.5332C9.61074 23.4613 7.63663 24.7921 5.94243 26.4912C4.25663 28.1734 2.91254 30.1662 1.9844 32.3595C1.07259 34.5067 0.580827 36.8088 0.535737 39.1412C0.534426 39.1936 0.543619 39.2457 0.562774 39.2946C0.58193 39.3434 0.610659 39.3878 0.647271 39.4254C0.683882 39.4629 0.727635 39.4927 0.77595 39.5131C0.824265 39.5334 0.876166 39.5439 0.928594 39.5439H3.87502C4.09109 39.5439 4.26297 39.372 4.26788 39.1608C4.36609 35.3698 5.88841 31.8193 8.57949 29.1282C11.3639 26.3439 15.0616 24.8117 19 24.8117C22.9384 24.8117 26.6362 26.3439 29.4206 29.1282C32.1116 31.8193 33.6339 35.3698 33.7322 39.1608C33.7371 39.3769 33.909 39.5439 34.125 39.5439H37.0714C37.1239 39.5439 37.1758 39.5334 37.2241 39.5131C37.2724 39.4927 37.3162 39.4629 37.3528 39.4254C37.3894 39.3878 37.4181 39.3434 37.4373 39.2946C37.4564 39.2457 37.4656 39.1936 37.4643 39.1412C37.4152 36.7939 36.929 34.5104 36.0156 32.3546ZM19 21.0796C16.746 21.0796 14.6246 20.2006 13.0286 18.6046C11.4326 17.0086 10.5536 14.8872 10.5536 12.6332C10.5536 10.3791 11.4326 8.25771 13.0286 6.66173C14.6246 5.06575 16.746 4.18673 19 4.18673C21.254 4.18673 23.3755 5.06575 24.9714 6.66173C26.5674 8.25771 27.4465 10.3791 27.4465 12.6332C27.4465 14.8872 26.5674 17.0086 24.9714 18.6046C23.3755 20.2006 21.254 21.0796 19 21.0796Z"
                    fill="#78B7F2"
                  />
                </svg>
              </div>
              <div className="info">
                <h4>Purchase</h4>
              </div>
            </div>
          </Col>
          <Col md={5}>
            <div className="info-box green">
              <a href=""></a>
              <div className="icon">
                <svg
                  width="38"
                  height="40"
                  viewBox="0 0 38 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M36.0156 32.3546C35.0893 30.1603 33.745 28.1672 32.0576 26.4863C30.3754 24.8005 28.3826 23.4564 26.1893 22.5282C26.1697 22.5184 26.15 22.5135 26.1304 22.5037C29.1898 20.2939 31.1786 16.6943 31.1786 12.6332C31.1786 5.90548 25.7277 0.45459 19 0.45459C12.2723 0.45459 6.82145 5.90548 6.82145 12.6332C6.82145 16.6943 8.81029 20.2939 11.8697 22.5086C11.85 22.5184 11.8304 22.5233 11.8107 22.5332C9.61074 23.4613 7.63663 24.7921 5.94243 26.4912C4.25663 28.1734 2.91254 30.1662 1.9844 32.3595C1.07259 34.5067 0.580827 36.8088 0.535737 39.1412C0.534426 39.1936 0.543619 39.2457 0.562774 39.2946C0.58193 39.3434 0.610659 39.3878 0.647271 39.4254C0.683882 39.4629 0.727635 39.4927 0.77595 39.5131C0.824265 39.5334 0.876166 39.5439 0.928594 39.5439H3.87502C4.09109 39.5439 4.26297 39.372 4.26788 39.1608C4.36609 35.3698 5.88841 31.8193 8.57949 29.1282C11.3639 26.3439 15.0616 24.8117 19 24.8117C22.9384 24.8117 26.6362 26.3439 29.4206 29.1282C32.1116 31.8193 33.6339 35.3698 33.7322 39.1608C33.7371 39.3769 33.909 39.5439 34.125 39.5439H37.0714C37.1239 39.5439 37.1758 39.5334 37.2241 39.5131C37.2724 39.4927 37.3162 39.4629 37.3528 39.4254C37.3894 39.3878 37.4181 39.3434 37.4373 39.2946C37.4564 39.2457 37.4656 39.1936 37.4643 39.1412C37.4152 36.7939 36.929 34.5104 36.0156 32.3546ZM19 21.0796C16.746 21.0796 14.6246 20.2006 13.0286 18.6046C11.4326 17.0086 10.5536 14.8872 10.5536 12.6332C10.5536 10.3791 11.4326 8.25771 13.0286 6.66173C14.6246 5.06575 16.746 4.18673 19 4.18673C21.254 4.18673 23.3755 5.06575 24.9714 6.66173C26.5674 8.25771 27.4465 10.3791 27.4465 12.6332C27.4465 14.8872 26.5674 17.0086 24.9714 18.6046C23.3755 20.2006 21.254 21.0796 19 21.0796Z"
                    fill="#78B7F2"
                  />
                </svg>
              </div>
              <div className="info">
                <h4>Sales</h4>
              </div>
            </div>
          </Col>
          <Col md={5}>
            <div className="info-box green">
              <a href=""></a>
              <div className="icon">
                <svg
                  width="38"
                  height="40"
                  viewBox="0 0 38 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M36.0156 32.3546C35.0893 30.1603 33.745 28.1672 32.0576 26.4863C30.3754 24.8005 28.3826 23.4564 26.1893 22.5282C26.1697 22.5184 26.15 22.5135 26.1304 22.5037C29.1898 20.2939 31.1786 16.6943 31.1786 12.6332C31.1786 5.90548 25.7277 0.45459 19 0.45459C12.2723 0.45459 6.82145 5.90548 6.82145 12.6332C6.82145 16.6943 8.81029 20.2939 11.8697 22.5086C11.85 22.5184 11.8304 22.5233 11.8107 22.5332C9.61074 23.4613 7.63663 24.7921 5.94243 26.4912C4.25663 28.1734 2.91254 30.1662 1.9844 32.3595C1.07259 34.5067 0.580827 36.8088 0.535737 39.1412C0.534426 39.1936 0.543619 39.2457 0.562774 39.2946C0.58193 39.3434 0.610659 39.3878 0.647271 39.4254C0.683882 39.4629 0.727635 39.4927 0.77595 39.5131C0.824265 39.5334 0.876166 39.5439 0.928594 39.5439H3.87502C4.09109 39.5439 4.26297 39.372 4.26788 39.1608C4.36609 35.3698 5.88841 31.8193 8.57949 29.1282C11.3639 26.3439 15.0616 24.8117 19 24.8117C22.9384 24.8117 26.6362 26.3439 29.4206 29.1282C32.1116 31.8193 33.6339 35.3698 33.7322 39.1608C33.7371 39.3769 33.909 39.5439 34.125 39.5439H37.0714C37.1239 39.5439 37.1758 39.5334 37.2241 39.5131C37.2724 39.4927 37.3162 39.4629 37.3528 39.4254C37.3894 39.3878 37.4181 39.3434 37.4373 39.2946C37.4564 39.2457 37.4656 39.1936 37.4643 39.1412C37.4152 36.7939 36.929 34.5104 36.0156 32.3546ZM19 21.0796C16.746 21.0796 14.6246 20.2006 13.0286 18.6046C11.4326 17.0086 10.5536 14.8872 10.5536 12.6332C10.5536 10.3791 11.4326 8.25771 13.0286 6.66173C14.6246 5.06575 16.746 4.18673 19 4.18673C21.254 4.18673 23.3755 5.06575 24.9714 6.66173C26.5674 8.25771 27.4465 10.3791 27.4465 12.6332C27.4465 14.8872 26.5674 17.0086 24.9714 18.6046C23.3755 20.2006 21.254 21.0796 19 21.0796Z"
                    fill="#78B7F2"
                  />
                </svg>
              </div>
              <div className="info">
                <h4>Vouchers</h4>
              </div>
            </div>
          </Col>
          <Col md={5}>
            <div className="info-box green">
              <a href=""></a>
              <div className="icon">
                <svg
                  width="38"
                  height="40"
                  viewBox="0 0 38 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M36.0156 32.3546C35.0893 30.1603 33.745 28.1672 32.0576 26.4863C30.3754 24.8005 28.3826 23.4564 26.1893 22.5282C26.1697 22.5184 26.15 22.5135 26.1304 22.5037C29.1898 20.2939 31.1786 16.6943 31.1786 12.6332C31.1786 5.90548 25.7277 0.45459 19 0.45459C12.2723 0.45459 6.82145 5.90548 6.82145 12.6332C6.82145 16.6943 8.81029 20.2939 11.8697 22.5086C11.85 22.5184 11.8304 22.5233 11.8107 22.5332C9.61074 23.4613 7.63663 24.7921 5.94243 26.4912C4.25663 28.1734 2.91254 30.1662 1.9844 32.3595C1.07259 34.5067 0.580827 36.8088 0.535737 39.1412C0.534426 39.1936 0.543619 39.2457 0.562774 39.2946C0.58193 39.3434 0.610659 39.3878 0.647271 39.4254C0.683882 39.4629 0.727635 39.4927 0.77595 39.5131C0.824265 39.5334 0.876166 39.5439 0.928594 39.5439H3.87502C4.09109 39.5439 4.26297 39.372 4.26788 39.1608C4.36609 35.3698 5.88841 31.8193 8.57949 29.1282C11.3639 26.3439 15.0616 24.8117 19 24.8117C22.9384 24.8117 26.6362 26.3439 29.4206 29.1282C32.1116 31.8193 33.6339 35.3698 33.7322 39.1608C33.7371 39.3769 33.909 39.5439 34.125 39.5439H37.0714C37.1239 39.5439 37.1758 39.5334 37.2241 39.5131C37.2724 39.4927 37.3162 39.4629 37.3528 39.4254C37.3894 39.3878 37.4181 39.3434 37.4373 39.2946C37.4564 39.2457 37.4656 39.1936 37.4643 39.1412C37.4152 36.7939 36.929 34.5104 36.0156 32.3546ZM19 21.0796C16.746 21.0796 14.6246 20.2006 13.0286 18.6046C11.4326 17.0086 10.5536 14.8872 10.5536 12.6332C10.5536 10.3791 11.4326 8.25771 13.0286 6.66173C14.6246 5.06575 16.746 4.18673 19 4.18673C21.254 4.18673 23.3755 5.06575 24.9714 6.66173C26.5674 8.25771 27.4465 10.3791 27.4465 12.6332C27.4465 14.8872 26.5674 17.0086 24.9714 18.6046C23.3755 20.2006 21.254 21.0796 19 21.0796Z"
                    fill="#78B7F2"
                  />
                </svg>
              </div>
              <div className="info">
                <h4>Reports</h4>
              </div>
            </div>
          </Col>
          <Col md={5}>
            <div className="info-box green">
              <a href=""></a>
              <div className="icon">
                <svg
                  width="38"
                  height="40"
                  viewBox="0 0 38 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M36.0156 32.3546C35.0893 30.1603 33.745 28.1672 32.0576 26.4863C30.3754 24.8005 28.3826 23.4564 26.1893 22.5282C26.1697 22.5184 26.15 22.5135 26.1304 22.5037C29.1898 20.2939 31.1786 16.6943 31.1786 12.6332C31.1786 5.90548 25.7277 0.45459 19 0.45459C12.2723 0.45459 6.82145 5.90548 6.82145 12.6332C6.82145 16.6943 8.81029 20.2939 11.8697 22.5086C11.85 22.5184 11.8304 22.5233 11.8107 22.5332C9.61074 23.4613 7.63663 24.7921 5.94243 26.4912C4.25663 28.1734 2.91254 30.1662 1.9844 32.3595C1.07259 34.5067 0.580827 36.8088 0.535737 39.1412C0.534426 39.1936 0.543619 39.2457 0.562774 39.2946C0.58193 39.3434 0.610659 39.3878 0.647271 39.4254C0.683882 39.4629 0.727635 39.4927 0.77595 39.5131C0.824265 39.5334 0.876166 39.5439 0.928594 39.5439H3.87502C4.09109 39.5439 4.26297 39.372 4.26788 39.1608C4.36609 35.3698 5.88841 31.8193 8.57949 29.1282C11.3639 26.3439 15.0616 24.8117 19 24.8117C22.9384 24.8117 26.6362 26.3439 29.4206 29.1282C32.1116 31.8193 33.6339 35.3698 33.7322 39.1608C33.7371 39.3769 33.909 39.5439 34.125 39.5439H37.0714C37.1239 39.5439 37.1758 39.5334 37.2241 39.5131C37.2724 39.4927 37.3162 39.4629 37.3528 39.4254C37.3894 39.3878 37.4181 39.3434 37.4373 39.2946C37.4564 39.2457 37.4656 39.1936 37.4643 39.1412C37.4152 36.7939 36.929 34.5104 36.0156 32.3546ZM19 21.0796C16.746 21.0796 14.6246 20.2006 13.0286 18.6046C11.4326 17.0086 10.5536 14.8872 10.5536 12.6332C10.5536 10.3791 11.4326 8.25771 13.0286 6.66173C14.6246 5.06575 16.746 4.18673 19 4.18673C21.254 4.18673 23.3755 5.06575 24.9714 6.66173C26.5674 8.25771 27.4465 10.3791 27.4465 12.6332C27.4465 14.8872 26.5674 17.0086 24.9714 18.6046C23.3755 20.2006 21.254 21.0796 19 21.0796Z"
                    fill="#78B7F2"
                  />
                </svg>
              </div>
              <div className="info">
                <h4>Products</h4>
              </div>
            </div>
          </Col>
          <Col md={5}>
            <div className="info-box green">
              <a href=""></a>
              <div className="icon">
                <svg
                  width="38"
                  height="40"
                  viewBox="0 0 38 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M36.0156 32.3546C35.0893 30.1603 33.745 28.1672 32.0576 26.4863C30.3754 24.8005 28.3826 23.4564 26.1893 22.5282C26.1697 22.5184 26.15 22.5135 26.1304 22.5037C29.1898 20.2939 31.1786 16.6943 31.1786 12.6332C31.1786 5.90548 25.7277 0.45459 19 0.45459C12.2723 0.45459 6.82145 5.90548 6.82145 12.6332C6.82145 16.6943 8.81029 20.2939 11.8697 22.5086C11.85 22.5184 11.8304 22.5233 11.8107 22.5332C9.61074 23.4613 7.63663 24.7921 5.94243 26.4912C4.25663 28.1734 2.91254 30.1662 1.9844 32.3595C1.07259 34.5067 0.580827 36.8088 0.535737 39.1412C0.534426 39.1936 0.543619 39.2457 0.562774 39.2946C0.58193 39.3434 0.610659 39.3878 0.647271 39.4254C0.683882 39.4629 0.727635 39.4927 0.77595 39.5131C0.824265 39.5334 0.876166 39.5439 0.928594 39.5439H3.87502C4.09109 39.5439 4.26297 39.372 4.26788 39.1608C4.36609 35.3698 5.88841 31.8193 8.57949 29.1282C11.3639 26.3439 15.0616 24.8117 19 24.8117C22.9384 24.8117 26.6362 26.3439 29.4206 29.1282C32.1116 31.8193 33.6339 35.3698 33.7322 39.1608C33.7371 39.3769 33.909 39.5439 34.125 39.5439H37.0714C37.1239 39.5439 37.1758 39.5334 37.2241 39.5131C37.2724 39.4927 37.3162 39.4629 37.3528 39.4254C37.3894 39.3878 37.4181 39.3434 37.4373 39.2946C37.4564 39.2457 37.4656 39.1936 37.4643 39.1412C37.4152 36.7939 36.929 34.5104 36.0156 32.3546ZM19 21.0796C16.746 21.0796 14.6246 20.2006 13.0286 18.6046C11.4326 17.0086 10.5536 14.8872 10.5536 12.6332C10.5536 10.3791 11.4326 8.25771 13.0286 6.66173C14.6246 5.06575 16.746 4.18673 19 4.18673C21.254 4.18673 23.3755 5.06575 24.9714 6.66173C26.5674 8.25771 27.4465 10.3791 27.4465 12.6332C27.4465 14.8872 26.5674 17.0086 24.9714 18.6046C23.3755 20.2006 21.254 21.0796 19 21.0796Z"
                    fill="#78B7F2"
                  />
                </svg>
              </div>
              <div className="info">
                <h4>Dips</h4>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MainPage;
