import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Button, Tooltip } from "antd";
import { Menu, Switch } from "antd";
import {
  PercentageOutlined,
  SettingOutlined,
  AppstoreOutlined,
  UserOutlined,
  CreditCardOutlined,
  FilePdfOutlined,
  LineChartOutlined,
  IdcardOutlined,
  ShoppingCartOutlined,
  LockOutlined,
  DashboardOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { UserGroupIcon } from "@heroicons/react/24/outline";

const items = [
  {
    key: "sub1",
    label: <NavLink to="/controlbase/dashboard">Dashboard</NavLink>,
    icon: <AppstoreOutlined />,
  },
  {
    key: "sub2",
    label: <NavLink to="/controlbase/petrolpump">Petrol Pumps</NavLink>,
    icon: <DashboardOutlined />,
  },
  {
    key: "sub3",
    label: <NavLink to="/controlbase/packges">Packages</NavLink>,
    icon: <CreditCardOutlined />,
  },
 
 
  {
    key: "sub5",
    label: <NavLink to="/controlbase/petrolpump/subscriptions/">Subscriptions</NavLink>,
    icon: <IdcardOutlined />,
  },
  {
    key: "sub6",
    label: <NavLink to="/controlbase/fuelprovider">Fuel Providers</NavLink>,
    icon: <UsergroupAddOutlined />,
  },
  {
    key: "sub4",
    label: "Security",
    icon: <LockOutlined />,
    children: [

      {
        key: "d-3",
        label: <NavLink to="/controlbase/changepassword">Change Password</NavLink>,
      },
      {
        key: "d-1",
        label: <NavLink to="/controlbase/changepincode">Change Pin Code</NavLink>,
      },


      {
        key: "d-2",
        label: <NavLink to="/controlbase/usergroup">User Groups</NavLink>,
      },
    ],
  },
  
];

function LeftMenu() {

  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      const handleClick = (e) => {
        const menuItems = document.getElementsByClassName("ant-menu-item");
        const sideBar = document.getElementById("side-bar");
        const menuButton = document.getElementById("menubutton");
        const overlayMenu = document.getElementById("overlaymenu");

        const menuItemsArray = Array.from(menuItems);
        const menuItemClicked = menuItemsArray.some((menuItem) =>
          menuItem.contains(e.target)
        );

        if (menuItemClicked) {
          if (menuButton) {
            menuButton.classList.toggle("active");
          }

          if (overlayMenu) {
            overlayMenu.classList.toggle("active");
          }

          if (sideBar) {
            sideBar.classList.toggle("active");
          }
        }
      };

      if (width <= 767) {
        window.addEventListener("click", handleClick);
      } else {
        window.removeEventListener("click", handleClick);
      }

      // Clean up event listener on component unmount
      return () => {
        window.removeEventListener("click", handleClick);
      };
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className={`left-side`} id="side-bar">
        {/* <Button
          type="primary"
          onClick={toggleCollapsed}
          style={{
            marginBottom: 16,
          }}
        >
          {collapsed ?
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
            :
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
            </svg>
          }
        </Button> */}

        <div className="side-wrap">
          <Menu mode="inline"
            // inlineCollapsed={collapsed}
            defaultOpenKeys={["sub1"]} items={items} />
        </div>
      </div>
    </>
  );
}

export default LeftMenu;
