import { Table, Pagination, Checkbox, Space } from "antd";
import React, { useState } from "react";
import LoginChecked from "../Shared/LoginChecked";

function ResetAccount() {
  const [loading, setLoading] = useState(false);

  // Pagination states
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalRecords, setTotalRecords] = useState(14);

  const data = [
    {
      key: "1",
      moduleName: "Purchase",
      records: "40/101",
    },
    {
      key: "2",
      moduleName: "Sales",
      records: "34/223",
    },
    {
      key: "3",
      moduleName: "Vouchers",
      records: "79",
    },
    {
      key: "4",
      moduleName: "General",
      records: "794",
    },
    {
      key: "5",
      moduleName: "Product",
      records: "320",
    },
    {
      key: "6",
      moduleName: "Products",
      records: "8",
    },
    {
      key: "7",
      moduleName: "Accounts",
      records: "62",
    },
    {
      key: "8",
      moduleName: "Login Logs",
      records: "407",
    },
    {
      key: "9",
      moduleName: "Dip Readings",
      records: "4/2",
    },
    {
      key: "10",
      moduleName: "Nozzle Readings",
      records: "276",
    },
    {
      key: "11",
      moduleName: "Pump Machines",
      records: "10",
    },
    {
      key: "12",
      moduleName: "Customer Rates",
      records: "369",
    },
    {
      key: "13",
      moduleName: "Investment Summary",
      records: "0",
    },
  ];

  const columns = [
    {
      title: "Module Name",
      dataIndex: "moduleName",
      key: "moduleName",
    },
    {
      title: "Sub Module Name",
      dataIndex: "subModuleName",
      key: "subModuleName",
    },
    {
      title: "No. of Records",
      dataIndex: "records",
      key: "records",
    },
  ];

  const onPageChange = (page, pageSize) => {
    setPageNo(page);
  };

  const onPageSizeChange = (current, size) => {
    setPageNo(1);
    setPageSize(size);
  };

  return (
    <>

      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">
              ResetAccount
            </h3>
          </div>

          <Table
            rowSelection={{
              type: "checkbox",
            }}
            size="small"
            scroll={{
              x: "100%",
            }}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={false}
            bordered
          />

          <div className="text-end mt-5">
            <Pagination
              current={pageNo}
              pageSize={pageSize}
              size="small"
              total={totalRecords}
              onChange={onPageChange}
              onShowSizeChange={onPageSizeChange}
              showSizeChanger
              showQuickJumper
              showTotal={(total) => `Total ${total} items`}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetAccount;
