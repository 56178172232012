import React from 'react';
import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import pdfStyles from './pdfStyles';
// import Logo from "../../assets/images/Print-image.png"
import Logo from "../assets/images/Print-image.png"

const PrintFooter = () => {
  

    const styles = StyleSheet.create({
        
    });

    return (
        <View style={pdfStyles.footer} fixed>
            <View style={{display:'flex', alignItems:'center', flexDirection:'row', gap:'5px'}} >
                <Text style={pdfStyles.footerText}>Powered By: </Text>
                <Image src={Logo} style={{ width: 40, height:'auto'}}   />
            </View>
            <View style={{ alignItems: 'center' }}>
                <Text style={pdfStyles.footerText}>Manage your Petrol Pump Accounts with: www.fuelbook.pk</Text>
                {/* <Text style={pdfStyles.companyText}>www.fuelbook.pk</Text> */}
            </View>
        </View>
    );
};

export default PrintFooter;
