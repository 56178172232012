import React, { useState } from "react";
import { Row, Col, Button, Form, Input, Select, Card, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const { Option } = Select;

function CreateTicket() {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <Row>
          <Col md={16} sm={24}>
            <Card>
              <Form layout="vertical">
                <Form.Item
                  label="Subject"
                  name="Subject"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Subject",
                    },
                  ]}
                >
                  <Input placeholder="Subject" />
                </Form.Item>

                <Form.Item
                  label="Department"
                  name="Department"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select Department"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    loading={loading}
                    options={[
                      {
                        value: "1",
                        label: "Billing & Payments",
                      },
                      {
                        value: "2",
                        label: "Technical Support",
                      },
                      {
                        value: "3",
                        label: "Account Management",
                      },
                      {
                        value: "4",
                        label: "Sales & Subscriptions",
                      },
                      {
                        value: "5",
                        label: "Product Support",
                      },
                      {
                        value: "6",
                        label: "Training & Onboarding",
                      },
                      {
                        value: "7",
                        label: "Feedback & Suggestions",
                      },
                      {
                        value: "8",
                        label: "Compliance & Security",
                      },
                      {
                        value: "9",
                        label: "Partnerships & Integrations",
                      },
                    ]}
                  />
                </Form.Item>

                <Form.Item label="Message" name="Message">
                  <Input.TextArea
                    placeholder="Message"
                    style={{ height: "150px" }}
                  />
                </Form.Item>

                <Form.Item label="Attachment" name="attachment">
                  <Upload>
                    <Button icon={<UploadOutlined />}>Upload File</Button>
                  </Upload>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    shape="round"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CreateTicket;
