import React, { useState, useEffect } from "react";
import ProductsDropDown from "../Shared/ProductsDropDown";
import axios from "axios";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import config from "../../config";
import LoginChecked from "../Shared/LoginChecked";
import {
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Input,
  Select,
  message,
  Spin,
} from "antd";
import { NavLink, useParams } from "react-router-dom";

const EditMachine = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const productTypesArray = ProductsDropDown();
  const [loadingGetMachine, setLoadingGetMachine] = useState(false);
  const [Machine, SetMachine] = useState({});
  const [update, setUpdate] = useState(false);

  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  let params = useParams();

  useEffect(() => {
    document.title = "Edit Machine";
    setLoadingGetMachine(true);
    setUpdate(false);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: params.id,
    };

    console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "Product/GetPumpMachineByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          setLoadingGetMachine(false);
          SetMachine(response.data.PumpMachine);
        } else {
          setLoadingGetMachine(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [update]);

  const handleSubmit = (formData) => {
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: params.id,
      ...formData,
    };

    console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "/Product/UpdateNozzle",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    // console.log(api_config);

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          setLoading(false);
          setUpdate(true);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });

          // Handle success
        } else {
          // Handle error
          setLoading(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <LoginChecked />
      {contextHolder}

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h6 className="page-title">
              <NavLink to="/products/manage-machines">
                <ChevronLeftIcon />
              </NavLink>
              Edit Nozzle
            </h6>

            <div className="header-actions">
              <NavLink to="/products/manage-machines">
                <Button
                  type="primary"
                  size="large"
                  shape="round"
                  icon={<PlusOutlined style={{ color: "#fff" }} />}
                >
                  Manage Nozzle
                </Button>
              </NavLink>
            </div>


          </div>
          <Row gutter={[24, 24]} justify={"center"}>
            <Col md={12} xs={24}>
              <Card title="Update Pump Machine" loading={loadingGetMachine}>
                <Form
                  name="basic"
                  layout="horizontal"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={handleSubmit}
                  autoComplete="off"
                  fields={[
                    {
                      name: "Pump_No",
                      value: Machine?.Pump_No,
                    },
                    {
                      name: "Product_ID",
                      value: Machine?.Product_ID,
                    },
                  ]}
                >
                  <Form.Item
                    label="Pump No"
                    name="Pump_No"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Nozzle number",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Nozzle Number" />
                  </Form.Item>
                  <Form.Item
                    label="Product Type"
                    name="Product_ID"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Product!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Product"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      loading={productTypesArray === false ? true : false}
                      options={productTypesArray}
                    />
                  </Form.Item>
                  <Form.Item
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Button
                      type="primary"
                      size="large"
                      shape="round"
                      htmlType="submit"
                      loading={loading}
                    >
                      {loading ? <Spin /> : "Update"}
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default EditMachine;
