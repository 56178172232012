import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import {
  Row,
  Col,
  Card,
  DatePicker,
  Button,
  Form,
  Input,
  Select,
  message,
  Spin,
} from "antd";

const AddNewPump = () => {
    const [loading, setLoading] = useState(false);
    const UserID = localStorage.getItem("AdminID");
    const AccessKey = localStorage.getItem('AdminAccessKey');
    const MemberType = localStorage.getItem('MemberType');
    const [messageApi, contextHolder] = message.useMessage();
    const [data,setData] = useState('');
    useEffect(()=>{
        document.title = 'Add Pump'
    },[])

    // const handelSubmit=(formData)=>{
    //     setLoading(true);
    //     const data = {
    //         UserID:UserID,
    //         AccessKey:AccessKey,
    //     }
    //     console.log(data);
    //     var api_config = {
    //         method: "post",
    //         url: config.base_url + "",
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //         data: data,
    //       };
    //       axios(api_config)
    //   .then(function (response) {
    //     if (response.data.status_code == 1) {
    //       setLoading(false);
    //       messageApi.open({
    //         type: "success",
    //         content: response.data.status_message,
    //       });
          
    //       navigate("/controlbase/dashboard");
    //     } else {
    //       // Handle error
    //       setLoading(false);
    //       messageApi.open({
    //         type: "error",
    //         content: response.data.status_message,
    //       });
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    // }

  return (
    <>
    <>
      {/* {contextHolder} */}

      <div className="right-side-contents">
        <div className="page-content">
          <Row>
            <Col span={12}>
              <div className="page-header">
                <h6 className="page-title">
                  <NavLink to="/controlbase/petrolpump">
                    <ChevronLeftIcon />
                  </NavLink>
                  Add New Pump
                </h6>
              </div>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={12}>
              
                <Form
                  name="basic"
                  layout="horizontal"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                    // onFinish={handleSubmit}
                  autoComplete="off"
                  size="large"
                >
                  <Form.Item
                    label="Petrol Pump Name"
                    name="PumpName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Petrol Pump Name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Petrol Pump Name" />
                  </Form.Item>

                  <Form.Item
                    label="Petrol Pump Code"
                    name="PumpCode"
                    rules={[
                      {
                        required: true,
                        message: "Please input Petrol Pump Code",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Petrol Pump Code" />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    name="Email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Email",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Email" />
                  </Form.Item>
                  <Form.Item
                    label="Phone Number"
                    name="PhoneNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Phone Number",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Phone Number" />
                  </Form.Item>
                  <Form.Item label="Description" name="Description">
                    <Input placeholder="Enter Your Description" />
                  </Form.Item>

                  <Form.Item
                    label="Savings"
                    name="Savings"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Savings",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Savings" />
                  </Form.Item>

                  <Form.Item
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Button
                      type="primary"
                      size="large"
                      shape="round"
                      htmlType="submit"
                      loading={loading}
                    >
                      {loading ? <Spin /> : "Add Petrol Pump"}
                    </Button>
                  </Form.Item>
                </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
    </>
  )
}

export default AddNewPump