import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
import { Nozzel } from "../UserControls/Icons";

import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import dayjs from "dayjs";
import { Select, Skeleton } from "antd";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

function ProductChart(props) {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  const [StartDate, setStartDate] = useState(
    dayjs().subtract(30, "day").format("YYYY-MM-DD")
  );
  const [EndDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));

  const [Value, setValue] = useState(30);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Sale/GetSalesGraph",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          // Handle success
          const data = response.data.SalesGraph;
          const labels = data.map((item) =>
            dayjs(item.Period).format("DD-MM-YYYY")
          );
          const salesData = data.map((item) => item.TotalSales);

          setChartData({
            labels,
            datasets: [
              {
                label: false,
                data: salesData,
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                borderColor: "rgb(252, 109, 0)",
                borderWidth: 2,
                fill: true,
                tension: 0.4,
              },
            ],
          });

          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  }, [StartDate, EndDate]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        beginAtZero: false,
        ticks: {
          color: "#474747",
        },
        grid: {
          color: "#fff",
          display: true,
        },
      },
      y: {
        display: true,
        beginAtZero: false,

        ticks: {
          color: "#474747",
          min: 1,
        },
        grid: {
          color: "#CFCFCF",
        },
      },
    },
  };

  // const handleDateChange = (value) => {
  //   setValue(value);
  //   setEndDate(dayjs().format("YYYY-MM-DD"));
  //   setStartDate(dayjs().subtract(value, "day").format("YYYY-MM-DD"));
  // };

  const handleDateChange = (value) => {
    setValue(value);
    if (value === 15 || value === 30) {
      setStartDate(dayjs().subtract(value, "day").format("YYYY-MM-DD"));
    } else {
      setStartDate(dayjs().subtract(value, "month").format("YYYY-MM-DD"));
    }
  
    setEndDate(dayjs().format("YYYY-MM-DD"));
  };
  

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          <div className="dash-card-header">
            <div className="dash-card-title">
              <p className="icons-box">
                <Nozzel className="dashboard-icon" height={24} width={24} />
              </p>
              <h3>Nozzle Sales</h3>
            </div>
            <Select
              style={{ width: 200 }}
              placeholder="Select duration"
              onChange={handleDateChange}
              value={Value}
              options={[
                { label: "Last 15 Days", value: 15 },
                { label: "Last 30 Days", value: 30 },
                { label: "Last 3 Months", value: 90 }, 
                { label: "Last 6 Months", value: 180 }, 
                { label: "Last 12 Months", value: 365 }, 
              ]}
            />
          </div>

          <Line
            data={chartData} 
            options={options}
          />
        </>
      )}
    </>
  );
}

export default ProductChart;
