import React, { useState,useEffect } from 'react'
import config from '../../config';
import axios from 'axios';
import {Row, Col, Form, Button, Input,Card, message} from 'antd';
import{Link, useNavigate} from 'react-router-dom'

const ChangePinCode = () => {
    const[loading,setLoading] = useState(false);
    const UserID = localStorage.getItem("AdminID");
    const AccessKey = localStorage.getItem('AdminAccessKey');
    const MemberType = localStorage.getItem('MemberType');
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();

    useEffect(()=>{
        document.title="Change Password";
    },[])

    const handelSubmit=(formData)=>{
        setLoading(true);
        const data={
            UserID:UserID,
            AccessKey:AccessKey,
            ...formData,
        }
     
        var api_config = {
            method: "post",
            url: config.base_url + "AdminUser/ChangePassword",
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
          };
          axios(api_config)
          .then(function (response) {
            if (response.data.status_code == 1) {
              setLoading(false);
              messageApi.open({
                type: "success",
                content: response.data.status_message,
              });
              
              navigate("/controlbase/dashboard");
            } else {
              // Handle error
              setLoading(false);
              messageApi.open({
                type: "error",
                content: response.data.status_message,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });

    }
  return (
    <>
      {contextHolder}

     <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h6 className="page-title">Change PIN Code</h6>
          </div>
          <Row>
            <Col md={12} xs={24}>
            
              <Form
                name="basic"
                layout="horizontal"
                size="large"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={handelSubmit}
               
                autoComplete="off"
              >
                <Form.Item
                  label="Old Pin Code"
                  name="OldPin"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Old PIN Code!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label="New PIN Code"
                  name="PinCode"
                  rules={[
                    {
                      required: true,
                      message: "Please input your New PIN Code!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button
                    type="primary"
                    size="large"
                    shape="round"
                    htmlType="submit"
                    loading={loading}
                  >
                    Change Password
                  </Button>
                </Form.Item>
              </Form>
             
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default ChangePinCode