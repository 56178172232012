import axios from 'axios';
import React, { useEffect, useState } from 'react'
import config from '../../config';
import moment from 'moment';


function AccountDropDown(props) {

    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const [listofAccounts, setlistofAccounts] = useState([])
    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(50); // Change this to your desired page size
    const [orderby, SetOrderBy] = useState("1");
    const [AccountTypeID, SetAccountTypeID] = useState(0);
    const [BalanceType, SetBalanceType] = useState("All");
    const [searchedName, setSearchedName] = useState('');
    const [Dated, setDated] = useState();

    useEffect(() => {
        setLoading(true);
        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            Account_Type_ID: 0,
        }
        var api_config = {
            method: 'post',
            url: config.base_url + 'account/GetAccountsByType',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                // console.log('get accounts by type id', response.data);
                if (response.data.status_code == 1) {
                    // Handle success
                    setlistofAccounts(response.data.listofAccounts);
                    setLoading(false);
                } else {
                    // Handle error

                }
                //   setLoading(false);
            })
            .catch(function (error) {

                console.log(error);
            });

    }, []);


    if (!loading) {

        // Return the array of objects in the specified format
        const result = listofAccounts.map((item) => ({
            value: item.ID,
            label: item.Name,
        }));

        return result;

    }
    else {
        return false;
    }




}

export default AccountDropDown
