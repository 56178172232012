import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import config from "../../config";
import moment from "moment";
import {
  Space,
  Table,
  Popconfirm,
  Pagination,
  Row,
  Col,
  Breadcrumb,
  Button,
  Input,
  DatePicker,
  Tooltip,
  Form,
  Select,
} from "antd";
import PrintDatewiseSummary from "./Prints/PrintDatewiseSummary";
import { PrinterOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";
import LoginChecked from "../Shared/LoginChecked";
function DateWiseSummary() {
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);
  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");
  const [EndDate, SetEndDate] = useState(dayjs());
  const [StartDate, SetStartDate] = useState(dayjs().subtract(1, "month"));
  const [summaryReport, setSummaryreport] = useState([]);

  useEffect(() => {
    document.title = "Datewise Summary";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
    };
    console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "Report/DateWiseSummaryReport ",
      header: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          setSummaryreport(response.data.ListofSummary);
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [StartDate, EndDate]);

  const onDateChange = (date, value) => {
    SetStartDate(value[0]);
    SetEndDate(value[1]);
  };

  const columns = [
    {
      title: "Sr.#",
      dataIndex: "Sr",
      key: "Sr",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Dated",
      dataIndex: "Dated",
      key: "Dated",
      render: (_, record) => dayjs(record.Dated).format(config.date_format),
    },
    {
      title: "Opening Cash",
      dataIndex: "Opening_Cash",
      key: "OpeningCash",
      align: "right",
    },
    {
      title: "Cash Sales",
      dataIndex: "Cash_Sales",
      key: "CashSales",
      align: "right",
    },
    {
      title: "Credit Sales",
      dataIndex: "Credit_Sales",
      key: "CreditSales",
      align: "right",
    },
    {
      title: "Cash Purchases",
      dataIndex: "Cash_Purchase",
      key: "CashPurchases",
      align: "right",
    },
    {
      title: "Credit Purchases",
      dataIndex: "Credit_Purchase",
      key: "CreditPurchases",
      align: "right",
    },
    {
      title: "Total Receipts",
      dataIndex: "Total_Receipts",
      key: "TotalReceipts",
      align: "right",
    },
    {
      title: "Total Payments",
      dataIndex: "Total_Payments",
      key: "TotalPayments",
      align: "right",
    },
    {
      title: "Total Expenses",
      dataIndex: "Total_Expenses",
      key: "TotalExpenses",
      align: "right",
    },
    {
      title: "Closing Cash",
      dataIndex: "Closing_Cash",
      key: "ClosingCash",
      align: "right",
    },
    // {
    //   title: "Action",
    //   dataIndex: "Action",
    //   key: "Action",
    // },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Tooltip title="Print 1">
            <NavLink
              to={`/report-datewiseSummary/print-list/?Dated=${dayjs(
                record.Dated
              ).format("YYYY-MM-DD")}`}
              target="_blank"
            >
              <PrinterOutlined /> 1
            </NavLink>
          </Tooltip>
          <br />
          <Tooltip title="Print 2">
            <NavLink to={`/report-datewiseSummary2/print-list/?Dated=${dayjs(
                record.Dated
              ).format("YYYY-MM-DD")}`} target="_blank">
              <PrinterOutlined /> 2
            </NavLink>
          </Tooltip>
        </>
      ),
    },
  ];


const disableDate=(current)=>{
  return current && current>moment().endOf('day');
}

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">DateWise Summary</h3>

            <div className="header-actions">
              <NavLink to={`/report-datewiseSummary-all/print-list/?StartDate=${StartDate}&EndDate=${EndDate}`} target="_blank">
                <Button
                  type="dashed"
                  size="large"
                  shape="round"
                  // style={{ marginBottom: 10 }}
                >
                  <PrinterOutlined />
                  Print Report
                </Button>
              </NavLink>
            </div>
          </div>
          <div className="filter-wrap">
            <Form>
              <Form.Item>
                <RangePicker onChange={onDateChange} 
                disabledDate={disableDate}/>
              </Form.Item>
            </Form>
          </div>
          <Table
            size="small"
            scroll={{
              x: "100%",
            }}
            loading={loading}
            columns={columns}
            dataSource={summaryReport}
            // onChange={handleTableChange}
            pagination={false}
            bordered
          />
        </div>
      </div>
    </>
  );
}

export default DateWiseSummary;
