import React, { useEffect, useState } from "react";
import axios from "axios";
import StatBox from "./StatBox";
import config from "../../config";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  DatePicker,
  Form,
  Skeleton,
  Card,
  Table,
} from "antd";
import { NavLink } from "react-router-dom";

import { PrinterOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import LoginChecked from "../Shared/LoginChecked";
function SummarySheet() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [StartDate, SetStartDate] = useState(dayjs().subtract(1, "month"));
  const [summaryData, setSummaryData] = useState({});
  const [EndDate, SetEndDate] = useState(dayjs());

  useEffect(() => {
    document.title = "Summary Sheet";

    setLoading(true);
    const data = {
      UserId: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
    };
    console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetSummarySheet",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(summaryData);
        console.log("this is summarysheet data", response.data);
        if (response.data.status_code == 1) {
          setSummaryData(response.data);
          // SetStartDate(response.data)
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [EndDate, StartDate]);

  console.log("summarydata", summaryData);

  const { RangePicker } = DatePicker;

  const handleDateChange = (date, value) => {
    console.log(value);
    SetStartDate(value[0]);
    SetEndDate(value[1]);
  };

  const disabledDate=(current)=>{
    return current && current > moment().endOf('day');
  }

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">
              {/* <NavLink to="/company/manage">
                <ChevronLeftIcon />
              </NavLink> */}
              Summary Sheet
            </h3>

            <div className="header-actions">
              {/* <NavLink to={`/accounts/print-account-list/?UserID=${UserID}&AccessKey=${AccessKey}&OrderBy=${orderby}&PageNo=${currentPage}&PageSize=${pageSize}&BalanceType=${BalanceType}&Account_Type_ID=${AccountTypeID}&Dated=${Dated}&Name=${searchedName}`} target='blank'> */}
              <NavLink
                to={`/summary-report/print-list/?StartDate=${StartDate}&EndDate=${EndDate}`}
                target="blank"
              >
                <Button
                  type="dashed"
                  shape="round"
                  size="large"
                  icon={<PrinterOutlined />}
                >
                  Print Report
                </Button>
              </NavLink>
            </div>
          </div>
          <div className="filter-wrap">
            <Form>
              <Form.Item>
                <RangePicker allowClear onChange={handleDateChange}
                disabledDate={disabledDate} />
              </Form.Item>
            </Form>
          </div>
          {loading ? (
            <>
              <Row>
                <Col xs={24} md={16}>
                  <Skeleton active />
                  <Skeleton active />
                </Col>
              </Row>
            </>
          ) : (
            <>
              {/* <Row gutter={[15, 15]}>
                <Col xs={24} md={6}>
                  <StatBox
                    label="Opening Cash"
                    value={
                      summaryData.OpeningCash ? summaryData.OpeningCash : 0
                    }
                  />
                </Col>
                <Col xs={24} md={6}>
                  <StatBox
                    label="Opening Cash at Banks"
                    value={
                      summaryData.OpeningCashatBanks
                        ? summaryData.OpeningCashatBanks
                        : 0
                    }
                  />
                </Col>
                <Col xs={24} md={6}>
                  <StatBox
                    label="Cash Sales"
                    value={Math.ceil(
                      summaryData.CashSales ? summaryData.CashSales : 0
                    )}
                  />
                </Col>
                <Col xs={24} md={6}>
                  <StatBox
                    label="Credit Sales"
                    value={Math.ceil(
                      summaryData.CreditSales ? summaryData.CreditSales : 0
                    )}
                  />
                </Col>
                <Col xs={24} md={6}>
                  <StatBox
                    label="Cash Purchase"
                    value={Math.ceil(
                      summaryData.CashPurchase ? summaryData.CashPurchase : 0
                    )}
                  />
                </Col>
                <Col xs={24} md={6}>
                  <StatBox
                    label="Credit Purchase"
                    value={Math.ceil(
                      summaryData.CreditPurchase
                        ? summaryData.CreditPurchase
                        : 0
                    )}
                  />
                </Col>
                <Col xs={24} md={6}>
                  <StatBox
                    label="Total Received"
                    value={
                      summaryData.TotalReceived ? summaryData.TotalReceived : 0
                    }
                  />
                </Col>
                <Col xs={24} md={6}>
                  <StatBox
                    label="Total Payments"
                    value={Math.ceil(
                      summaryData.TotalPayments ? summaryData.TotalPayments : 0
                    )}
                  />
                </Col>
                <Col xs={24} md={6}>
                  <StatBox
                    label="Total Expenses"
                    value={
                      summaryData.TotalExpenses ? summaryData.TotalExpenses : 0
                    }
                  />
                </Col>
                <Col xs={24} md={6}>
                  <StatBox
                    label="Total Discounts"
                    value={Math.ceil(
                      summaryData.TotalDiscounts
                        ? summaryData.TotalDiscounts
                        : 0
                    )}
                  />
                </Col>
                <Col xs={24} md={6}>
                  <StatBox
                    label="Closing Cash"
                    value={
                      summaryData.ClosingCash ? summaryData.ClosingCash : 0
                    }
                  />
                </Col>
                <Col xs={24} md={6}>
                  <StatBox
                    label="Closing Cash at Banks"
                    value={
                      summaryData.ClosingCashatBanks
                        ? summaryData.ClosingCashatBanks
                        : 0
                    }
                  />
                </Col>
              </Row> */}
              <div
                style={{
                  border: "0.5px solid rgb(229 229 229 / 89%)",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  fontFamily: "Roboto",
                }}
              >
                <p style={{ color: "rgb(165 164 164 / 89%)" }}>
                  SUMMARY SHEET DETAILS
                </p>
                <table
                  style={{
                    borderCollapse: "collapse",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                >
                  <tr>
                    <td
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Opening Cash
                    </td>
                    <td
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      {summaryData.OpeningCash ? summaryData.OpeningCash : 0}
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "rgb(235 235 235 / 38%)" }}>
                    <td
                      style={{
                        padding: "5px 10px",

                        width: "50%",
                      }}
                    >
                      Opening Cash at Banks
                    </td>
                    <td
                      style={{
                        padding: "5px 10px",

                        width: "50%",
                      }}
                    >
                      {summaryData.OpeningCashatBanks
                        ? summaryData.OpeningCashatBanks
                        : 0}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Cash Sales
                    </td>
                    <td
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      {summaryData.CashSales ? summaryData.CashSales : 0}
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "rgb(235 235 235 / 38%)" }}>
                    <td
                      style={{
                        padding: "5px 10px",

                        width: "50%",
                      }}
                    >
                      Credit Sales
                    </td>
                    <td
                      style={{
                        padding: "5px 10px",

                        width: "50%",
                      }}
                    >
                      {summaryData.CreditSales ? summaryData.CreditSales : 0}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Cash Purchase
                    </td>
                    <td
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      {summaryData.CashPurchase ? summaryData.CashPurchase : 0}
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "rgb(235 235 235 / 38%)" }}>
                    <td
                      style={{
                        padding: "5px 10px",

                        width: "50%",
                      }}
                    >
                      Credit Purchase
                    </td>
                    <td
                      style={{
                        padding: "5px 10px",

                        width: "50%",
                      }}
                    >
                      {summaryData.CreditPurchase
                        ? summaryData.CreditPurchase
                        : 0}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Total Received
                    </td>
                    <td
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      {summaryData.TotalReceived
                        ? summaryData.TotalReceived
                        : 0}
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "rgb(235 235 235 / 38%)" }}>
                    <td
                      style={{
                        padding: "5px 10px",

                        width: "50%",
                      }}
                    >
                      Total Payments
                    </td>
                    <td
                      style={{
                        padding: "5px 10px",

                        width: "50%",
                      }}
                    >
                      {summaryData.TotalPayments
                        ? summaryData.TotalPayments
                        : 0}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Total Expenses
                    </td>
                    <td
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      {summaryData.TotalExpenses
                        ? summaryData.TotalExpenses
                        : 0}
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "rgb(235 235 235 / 38%)" }}>
                    <td
                      style={{
                        padding: "5px 10px",

                        width: "50%",
                      }}
                    >
                      Total Discounts
                    </td>
                    <td
                      style={{
                        padding: "5px 10px",

                        width: "50%",
                      }}
                    >
                      {Math.ceil(
                        summaryData.TotalDiscounts
                          ? summaryData.TotalDiscounts
                          : 0
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Closing Cash
                    </td>
                    <td
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      {summaryData.ClosingCash ? summaryData.ClosingCash : 0}
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "rgb(235 235 235 / 38%)" }}>
                    <td
                      style={{
                        padding: "5px 10px",

                        width: "50%",
                      }}
                    >
                      Closing Cash at Banks
                    </td>
                    <td
                      style={{
                        padding: "5px 10px",

                        width: "50%",
                      }}
                    >
                      {summaryData.ClosingCashatBanks
                        ? summaryData.ClosingCashatBanks
                        : 0}
                    </td>
                  </tr>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default SummarySheet;
