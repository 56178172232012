import React, { useEffect, useState } from "react";
import moment from "moment";
import config from "../../config";
import axios from "axios";
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  message,
  Pagination,
  Table,
  Space,
  Tooltip,
  Popconfirm,
} from "antd";
import { NavLink } from "react-router-dom";
import LoginChecked from "../Shared/LoginChecked";
import { DeleteOutlined, EditOutlined, PlusOutlined, PrinterOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

function CreditPurchase() {

  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");

  const VoucherMode = 'CreditPurchase';

  const [voucherNo, setVoucherNo] = useState(0);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [StartDate, SetStartDate] = useState("");
  const [EndDate, SetEndDate] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const { RangePicker } = DatePicker;

  const [isDeleted, setIsDeleted] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [open, setOpen] = useState(false);
  const [listofVouchers, SetlistofVouchers] = useState([]);
  const [OrderBy, SetOrderBy] = useState("1");
  const [isVoucherUpdated, setIsVoucherUpdated] = useState(false);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [Button_Lodding, setButton_Lodding] = useState(false);


  const [ModalType, setModalType] = useState("");


  // customer accounts
  const [CustomerAccounts, setCustomerAccounts] = useState([]);
  const [loadingCustomerAccounts, setloadingCustomerAccounts] = useState(false);
  const [CustomerAccount, setCustomerAccount] = useState({});
  const [loadingCustomerAccount, setloadingCustomerAccount] = useState(false);

  // received in
  const [ListOfRecAccounts, setListOfRecAccounts] = useState([]);
  const [loadingRecAccounts, setLoadingRecAccounts] = useState(false);
  const [RecAccountsDropdown, setRecAccountsDropdown] = useState([]);
  const [loadingRecAccount, setLoadingRecAccount] = useState(false);
  const [RecAccount, setRecAccount] = useState({});

  const [debitAccountID, setdebitAccountID] = useState(0);
  const [creditAccountID, setcreditAccountID] = useState(0);
  const [VoucherId, setVoucherId] = useState('');


  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);


  const [total, setTotal] = useState(0);


  useEffect(() => {
    document.title = "Credit Purchase Vouchers";
    setIsDeleted(false);
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      OrderBy: OrderBy,
      PageNo: pageNo,
      PageSize: pageSize,
      AccountID: 0,
      Debit_Account_ID: 0,
      Credit_Account_ID: 0,

      VoucherMode: VoucherMode,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Voucher/GetVouchersWithType",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };


    axios(api_config)
      .then(function (response) {
        console.log(response.data)
        if (response.data.status_code == 1) {
          SetlistofVouchers(response.data.listofVouchers);
          setTotalRecords(response.data.totalRecords);
          setTotal(response.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
      });
  }, [
    OrderBy,
    isVoucherUpdated,
    StartDate,
    EndDate,
    pageSize,
    pageNo,
    isDeleted,
  ]);

  useEffect(() => {
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      VoucherMode: VoucherMode,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "/Voucher/GetNextVoucherNo",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setVoucherNo(response.data.NextVoucherNo);
        }
      })
      .catch(function (error) {
      });
  }, []);


  const fetchAccountsByType = (accountTypeID, setAccounts, setLoading) => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Account_Type_ID: accountTypeID,
    };
    const api_config = {
      method: "post",
      url: `${config.base_url}account/GetAccountsByType`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then((response) => {
        if (response.data.status_code === 1) {
          const result = response.data.listofAccounts.map((item) => ({
            value: item.ID,
            label: item.Name,
          }));
          setAccounts(result);
        }
      })
      .catch((error) => {
        console.error("Error fetching accounts:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAccountsByType(4, setRecAccountsDropdown, setLoadingRecAccounts);
  }, []);

  useEffect(() => {
    fetchAccountsByType(-3, setCustomerAccounts, setloadingCustomerAccounts);
  }, []);




  const onSelectDebitAccount = (item) => {
    setdebitAccountID(item);
    setLoadingRecAccount(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: item,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountbyIDWithBalance",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setRecAccount(response.data);
          setLoadingRecAccount(false);
        } else {
          setLoadingRecAccount(false);
        }
      })
      .catch(function (error) {
      });
  };

  const onSelectCreditAccount = (item) => {
    setcreditAccountID(item);
    setloadingCustomerAccount(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: item,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountbyIDWithBalance",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          // Handle success
          // setlistofAccounts(response.data.listofAccounts);
          setCustomerAccount(response.data);
          setloadingCustomerAccount(false);
        } else {
          // Handle error
          setloadingCustomerAccount(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
      });
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };


  const columns = [
    {
      title: "SR",
      render: (_, record, index) => index + 1 + pageSize * (pageNo - 1),
      key: "Sr",
    },
    {
      title: "Dated",
      dataIndex: "Dated",
      key: "dated",
      render: (date) => moment(date).format(config.date_format),
      sorter: true,
    },
    {
      title: "No",
      dataIndex: "VoucherNo",
      key: "VoucherNo",
      sorter: true,
    },
    {
      title: "Customer name",
      dataIndex: "Debit_Account",
      key: "Debit_Account",
      render: (_, record) => {
        return record.Debit_Account ? (
          <a
            target="_blank"
            href={`/accounts/account-ledger/?account_id=${record.Debit_Account_ID}`}
          >
            {record.Debit_Account.Name}
          </a>
        ) : (
          ""
        );
      },
    },
    {
      title: "From Account",
      dataIndex: "Credit_Account",
      key: "Credit_Account",
      render: (_, record) => {
        return record.Credit_Account ? (
          <a
            target="_blank"
            href={`/accounts/account-ledger/?account_id=${record.Credit_Account_ID}`}
          >
            {record.Credit_Account.Name}
          </a>
        ) : (
          ""
        );
      },
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "description",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      align: "right",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a type="button" onClick={() => handelShowModal('Edit', record)}>
            <Tooltip title="Edit Voucher">
              <EditOutlined />
            </Tooltip>
          </a>
          <Popconfirm
            title="Sure to delete?"
            description="Are You sure to delete this data"
            onConfirm={() => handelDelete(record.ID)}
          >
            <NavLink to={`#`}>
              <Tooltip title="Delete Voucher">
                <DeleteOutlined />
              </Tooltip>
            </NavLink>
          </Popconfirm>
          <NavLink
            to={`/accounts/print-voucher-list/?voucher_id=${record.ID}`}
            target="_blank"
          >
            <Tooltip title="Print Voucher">
              <PrinterOutlined />
            </Tooltip>
          </NavLink>
        </Space>
      ),
    }

  ];
  const getFooterContent = () => (
    <>
      <Row gutter={[16, 16]}>
        {/* First Column */}
        <Col md={24} xs={24}>
          <Row>
            <Col md={7} xs={20}>
            </Col>
            <Col md={8} xs={20}>
              <p>
                <b>Cash Total:</b>
              </p>
            </Col>
            <Col md={6} xs={4}>
              <p>{total.Total}</p>
            </Col>
          </Row>
        </Col>

      </Row>
    </>
  );

  const handelDelete = (ID) => {
    setIsDeleted(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: ID,
    };
    const api_config = {
      method: "post",
      url: config.base_url + "Voucher/DeleteVoucher",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {
          setIsDeleted(false);
          message.success(response.data.status_message);
        } else {
          setIsDeleted(false);
          message.error(response.data.status_message);
        }
      })
      .catch(function (error) {
        setIsDeleted(false);
      });
  };
  const handleDateChange = (date, value) => {
    SetStartDate(value[0]);
    SetEndDate(value[1]);
  };
  const disableDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    // Make API request with sorting parameters
    const sortField = sorter.field;

    const sortOrder = sorter.order === "descend" ? "desc" : "asc";

    if (sortField == "Dated") {
      if (sortOrder == "asc") {
        SetOrderBy(6);
      } else {
        SetOrderBy(5);
      }
    } else if (sortField == "VoucherNo") {
      if (sortOrder == "asc") {
        SetOrderBy(2);
      } else {
        SetOrderBy(1);
      }
    }
    else {

    }

  };

  const handelShowModal = (type, record) => {

    setModalType(type);
    const Voucher = record;
    Voucher.Dated = Voucher.Dated ? dayjs(Voucher.Dated) : null;
    Voucher.NextVoucherNo = Voucher.NextVoucherNo ? Voucher.NextVoucherNo : voucherNo;

    form.setFieldsValue(Voucher);
    setVoucherId(Voucher.ID)

    setOpen(true);
  }

  const handleCancel = () => {
    setSelectedVoucher(null);
    setOpen(false);
    setButton_Lodding(false);
    form.resetFields();
    setVoucherId('');
  };
  const handelVoucher = (formData) => {
    setIsVoucherUpdated(true); // Corrected line
    setButton_Lodding(true);
    setLoading(true);

    formData["Dated"] = dayjs(formData["Dated"]).format("YYYY-MM-DD");

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      VoucherMode: VoucherMode,

      Credit_Account_ID: creditAccountID,
      Debit_Account_ID: debitAccountID,


      ...formData,
    };

    if (ModalType === 'Edit') {
      data.ID = VoucherId;
    }

    const api_config = {
      method: "post",
      url: config.base_url + (ModalType === 'Edit' ? "Voucher/UpdateVoucher" : 'Voucher/AddNewVoucher'),
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {
          setIsVoucherUpdated(false);
          setButton_Lodding(false);
          setLoading(false);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });

          setConfirmLoading(true);
          setTimeout(() => {
            setOpen(false);
            form.resetFields();
            setConfirmLoading(false);
          }, 2000);
        } else {
          setLoading(false);
          setButton_Lodding(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {
        setLoading(false);
        setButton_Lodding(false);
        messageApi.open({
          type: "error",
          content: "Network Error",
        });
      });
  };


  return (
    <>
      {contextHolder}
      <Modal
        title={ModalType == 'Edit' ? 'Update Voucher' : 'Add Credit Purchase'}
        open={open}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="basic"
          layout="vertical"
          onFinish={handelVoucher}
          autoComplete="off"
          form={form}
        >
          <Row align="bottom" gutter={24}>
            <Col xs={24} md={6}>
              <Form.Item
                className="input-vertical"
                name="NextVoucherNo"
                label="Voucher No"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={9}>
              <Form.Item
                label="From Account"
                name="Credit_Account_ID"
                className="input-vertical"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Select Account"
                  optionFilterProp="children"
                  onChange={onSelectDebitAccount}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                  loading={loadingRecAccounts}
                  options={RecAccountsDropdown}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={9}>
              <Form.Item
                label="Given To"
                name="Debit_Account_ID"
                className="input-vertical"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Select Account"
                  optionFilterProp="children"
                  onChange={onSelectCreditAccount}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                  loading={loadingCustomerAccounts}
                  options={CustomerAccounts}
                />
              </Form.Item>
            </Col>



            <Col xs={24} md={12}>
              <Form.Item
                className="input-vertical"
                name="Dated"
                label="Date"
                rules={[{ required: true }]}
              >
                <DatePicker style={{ width: "100%" }}
                  disabledDate={disabledDate} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                className="input-vertical"
                name="Amount"
                label="Amount"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                className="input-vertical"
                name="Description"
                label="Description"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className="text-right">
                <Button
                  type="primary"
                  size="large"
                  shape="round"
                  loading={Button_Lodding}
                  htmlType="submit"
                >
                  {ModalType == 'Edit' ? 'Update Credit Purchase' : 'Add Credit Purchase'}

                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Credit Purchase</h3>
            <div className="header-actions">
              <NavLink
                to={`/vouchers/print-voucher-details/?StartDate=${StartDate}&EndDate=${EndDate}&AccountID=${0}&VoucherMode=${VoucherMode}&DebitAccountID=${0}&creditAccountID=${0}`}
                target="blank"
              >
                <Button
                  type="dashed"
                  shape="round"
                  size="large"
                  icon={<PrinterOutlined />}
                >
                  Download / Print
                </Button>
              </NavLink>
              <Button type="primary" size="large" shape="round" icon={<PlusOutlined />} onClick={() => handelShowModal('Add', {})}>
                Credit Purchase
              </Button>
            </div>
          </div>

          <div className="filters-wrap">
            <Form>
              <Form.Item name="">
                <RangePicker
                  style={{
                    width: "100%",
                    marginLeft: 5,
                  }}
                  onChange={handleDateChange}
                  disabledDate={disableDate}
                />
              </Form.Item>
            </Form>
          </div>
          <Table
            size="small"
            columns={columns}
            dataSource={listofVouchers}
            pagination={false}
            loading={loading}
            onChange={handleTableChange}
            bordered
            scroll={{
              x: "100%",
            }}
            summary={(pageData) => {
              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={6} align="right">
                    <b>Total Amount:</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    {total.Total}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }}
          />
          <div className="text-end mt-5">
            <Pagination
              current={pageNo}
              pageSize={pageSize}
              size="small"
              total={totalRecords}
              onChange={(page) => setPageNo(page)}
              onShowSizeChange={(current, size) => {
                setPageNo(1);
                setPageSize(size)
              }}
              showSizeChanger
              showQuickJumper
              showTotal={(total) => `Total ${total} items`}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CreditPurchase