import React, { useState, useEffect } from "react";
import axios from "axios";
import PurchaseHeader from "../../../Pdf/PrintHeaderAccount";
import config from "../../../config";
import dayjs from "dayjs";
import pdfStyles from "../../../Pdf/pdfStyles";
import PrintFooter from "../../../Pdf/PrintFooter"
import { PrintFormateNumber } from "../../../Pdf/PrintFormateNumber";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { Spin } from "antd";

function PrintPurchaseReport() {
  const [loading, setLoading] = useState(false);

  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");

  const urlParams = new URLSearchParams(window.location.search);
  const EndDate = urlParams.get("EndDate");
  const StartDate = urlParams.get("StartDate");
  const OrderBy = urlParams.get("Orderby");
  const ProductID = urlParams.get("ProductID");
  const SupplierID = urlParams.get("SupplierID");
  const VehicleNo = urlParams.get("VehicleNo")

  const [purchaseList, setPurchaseList] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    document.title = "Purchase Report";

    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      OrderBy: OrderBy,
      PageNo: 1,
      PageSize: 2000,
      StartDate: StartDate,
      EndDate: EndDate,
      ProductID: ProductID,
      SupplierID: SupplierID,
      VehicleNo: VehicleNo,
    };

    console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetPurchaseReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log("Purchase report", response.data);
        if (response.data.status_code == 1) {
          setPurchaseList(response.data.listofPurchase);
          setData(response.data);

          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

 

  const generateData = () => {
    if (purchaseList.length > 0) {
      return purchaseList.map((item, index) => {
        return (
          <View key={index} style={pdfStyles.row}>
            <View style={[pdfStyles.td, { width: 50, textAlign: "center" }]}>
              <Text style={pdfStyles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 100 }]}>
              <Text style={pdfStyles.tdValue}>
                {dayjs(item.Created_Date).format("YYYY-MM-DD")}
              </Text>
            </View>
            <View style={[pdfStyles.td, { width: 50 }]}>
              <Text style={pdfStyles.tdValue}>{item.Product_BE?.ProductCode}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 100 }]}>
              <Text style={pdfStyles.tdValue}>{item.Product_BE?.Name}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 60, textAlign: "center" }]}>
              <Text style={pdfStyles.tdValue}>{item.Vehicle_No}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 50, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.Price,2)}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 60, textAlign: "center" }]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.Quantity,2)}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 80, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.Amount,0)}</Text>
            </View>
          </View>
        );
      });
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <PurchaseHeader />
        <View style={pdfStyles.section}>
          <View style={pdfStyles.title}>
            <Text style={pdfStyles.pagetitle}>Purchase Report</Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 50 }]}>
                <Text style={pdfStyles.thValue}>SR.</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100 }]}>
                <Text style={pdfStyles.thValue}>Dated</Text>
              </View>
              <View style={[pdfStyles.th, { width: 50 }]}>
                <Text style={pdfStyles.thValue}>Supplier</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100 }]}>
                <Text style={pdfStyles.thValue}>Product</Text>
              </View>
              <View style={[pdfStyles.th, { width: 60 }]}>
                <Text style={pdfStyles.thValue}>Vehicle No</Text>
              </View>
              <View style={[pdfStyles.th, { width: 50, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Price</Text>
              </View>
              <View style={[pdfStyles.th, { width: 60 }]}>
                <Text style={pdfStyles.thValue}>Quantity</Text>
              </View>
              <View style={[pdfStyles.th, { width: 80, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Amount</Text>
              </View>
            </View>

            {generateData()}
            <View style={pdfStyles.tableHead}>
              <View
                style={[pdfStyles.th, { width:470, textAlign: "right" }]}
              >
                <Text style={pdfStyles.thValue}>
                  Total Purchase
                </Text>
              </View>
              <View
                style={[pdfStyles.th, { width:80, textAlign: "right" }]}
              >
                <Text style={pdfStyles.thValue}>
                   {data.totalPurchase}
                </Text>
                
              </View>
            </View>
            <View style={pdfStyles.tableHead}>
              <View
                style={[pdfStyles.th, { width:470, textAlign: "right" }]}
              >
                <Text style={pdfStyles.thValue}>
                Total Petrol (Ltr)
                </Text>
              </View>
              <View
                style={[pdfStyles.th, { width:80, textAlign: "right" }]}
              >
                <Text style={pdfStyles.thValue}>
                {data.totalPetrol}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.tableHead}>
              <View
                style={[pdfStyles.th, { width:470, textAlign: "right" }]}
              >
                <Text style={pdfStyles.thValue}>
                Total Diesel (Ltr)
                </Text>
              </View>
              <View
                style={[pdfStyles.th, { width:80, textAlign: "right" }]}
              >
                <Text style={pdfStyles.thValue}>
                {data.totalDiesel}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{ marginBottom: 60, marginTop: 0, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
        <PrintFooter  fixed/>
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="sample.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
}

export default PrintPurchaseReport;
