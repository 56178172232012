import React from "react";

function StatBox(props) {
  return (
    <div className={`dash-card no-shadow`} style={props.style}>
      <div className="stat-box">
        <p>{props.label}</p>
        <h4>Rs. {props.value}</h4>
        <a href={props.link}> {props.linktext}</a>
      </div>
    </div>
  );
}

export default StatBox;
