import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
import ProductsDropDown from "../Shared/ProductsDropDown";
import AccountDropDown from "../Shared/AccountDropDown";
import BoxCard from "../Shared/BoxCard";
import {
  Row,
  Col,
  message,
  Form,
  Input,
  Select,
  Checkbox,
  Button,
  Skeleton,
  DatePicker,
  Table,
  Space,
} from "antd";

import moment from "moment";
import { DeleteOutlined, PlusCircleFilled } from "@ant-design/icons";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import LoginChecked from "../Shared/LoginChecked";
import dayjs from "dayjs";
import { NumberFormat } from "../Shared/Utility";
import { NozzleOil } from "../UserControls/Icons";

function EditSales() {
  let navigate = useNavigate();
  let params = useParams();


  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [ListofNozzels, setListofNozzels] = useState([]);
  const [ListofNozzelsCard, setListofNozzelsCard] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAddSale, setLoadingAddSale] = useState(false);
  const [WithoutNozzel, setWithoutNozzel] = useState(false);
  const [Is_Cash, setIs_Cash] = useState(false);

  const [CashAccountID, setCashAccountID] = useState(0);

  const [Quantity, setQuantity] = useState({});

  const uniqueProductNames = new Set();

  const accountTypesArray = AccountDropDown();

  const productTypesArray = ProductsDropDown();
  const [ProductName, setProductName] = useState("");
  const [AccountName, setAccountName] = useState("");
  const [Price, setPrice] = useState(0);

  const [form] = Form.useForm();

  const [purchaseList, setPurchaseList] = useState([]);

  const [priceGroup, setpriceGroup] = useState([]);

  const [totals, setTotals] = useState({
    cashSaleTotal: 0,
    creditSaleTotal: 0,
    totalPrice: 0,
  });

  const [Dated, setDated] = useState(null);
  const [Description, setDescription] = useState("");
  const [InvoiceNo, setInvoiceNo] = useState("");

  const [ListofReadings, setListofReadings] = useState([]);
  const [ListOfSummary, setListOfSummary] = useState([]);



  useEffect(() => {
    document.title = "Edit Sale";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: params.id,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Sale/GetSaleByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };


    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          // Handle success

          let listOfSale = response.data.listofSale;
          let listOfNozzel = response.data.listofReadings;

          const result = listOfSale.map((item) => ({
            ProductName: item.Product_BE?.Name,
            AccountName: item.Account_BE?.Name,
            Product_ID: item.Product_ID,
            Account_ID: item.Account_ID,
            Vehicle_No: item.Vehicle_No,
            Receipt_No: item.Receipt_No,
            Quantity: item.Quantity,
            Price: item.Price,
            Is_Cash: item.Is_Cash,
          }));

          // const Nozzel = listOfNozzel.map((item) => ({
            
          //   ProductCode: item.ProductCode,
          //   Last_Reading: item.Last_Reading,
          //   Current_Reading: item.Current_Reading,
          //   Returned: item.Returned,
          //   Quantity: item.Quantity,
          // }));

          setInvoiceNo(response.data.InvoiceNo);
          setPurchaseList(result);
          setDescription(response.data.Description);
          setDated(dayjs(response.data.Dated));
          // setListofNozzels(Nozzel);
           setListofNozzels(response.data.listofReadings);
          setListofNozzelsCard(response.data.listofReadings);
          setListofReadings(response.data.listofReadings);

          setLoading(false);
        } else {
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  console.log(ListofNozzels);

  useEffect(() => {
    if (accountTypesArray) {
      let cash = accountTypesArray.find((item) => item.label === "Cash");
      if (cash) {
        setCashAccountID(cash.value);
      }
    }

    //console.log(accountTypesArray);
  }, [accountTypesArray]);



  useEffect(() => {
    const groupedData = purchaseList.reduce((acc, obj) => {
      const key = obj.Product_ID;
      if (!acc[key]) {
        acc[key] = {
          Product_ID: obj.Product_ID,
          ProductName: obj.ProductName,
          Total_Price: 0,
        };
      }
      acc[key].Total_Price += parseInt(obj.Quantity) * parseInt(obj.Price);
      return acc;
    }, {});

    setpriceGroup(Object.values(groupedData));
  }, [purchaseList]);

  useEffect(() => {
    const cashSaleTotal = purchaseList.reduce((acc, obj) => {
      if (obj.Is_Cash) {
        return acc + parseFloat(obj.Quantity) * parseFloat(obj.Price);
      }
      return acc;
    }, 0);

    const creditSaleTotal = purchaseList.reduce((acc, obj) => {
      if (!obj.Is_Cash) {
        return acc + parseFloat(obj.Quantity) * parseFloat(obj.Price);
      }
      return acc;
    }, 0);

    const totalPrice = purchaseList.reduce((acc, obj) => {
      return acc + parseFloat(obj.Quantity) * parseFloat(obj.Price);
    }, 0);

    setTotals({
      cashSaleTotal,
      creditSaleTotal,
      totalPrice,
    });
  }, [purchaseList]);

  useEffect(() => {
    // Convert Price and Quantity to numbers and calculate the total whenever they change
    const priceValue = parseFloat(Price);
    const quantityValue = parseFloat(Quantity);
    const totalValue = priceValue * quantityValue;
    const total = isNaN(totalValue) ? "" : totalValue.toString();
    form.setFieldsValue({
      total: total,
    });
  }, [Price, Quantity]);

  function handleCheckboxChange(index) {
    console.log(index)
    setPurchaseList((prevData) => {
      // Create a new copy of the purchase list
      const updatedList = [...prevData];

      // Create a new copy of the object at the given index
      const updatedItem = { ...updatedList[index] };

      // Toggle the Is_Cash value
      updatedItem.Is_Cash = !updatedItem.Is_Cash;

      // Update the list with the modified item
      updatedList[index] = updatedItem;

      return updatedList;
    });
  }


  const handleSubmit = (formData) => {
    // console.log(formData)
    // formData["Is_Cash"] = formData["Is_Cash"] == "on" ? true : false;
    formData["Vehicle_No"] =
      formData["Vehicle_No"] == undefined ? "" : formData["Vehicle_No"];
    formData["Receipt_No"] =
      formData["Receipt_No"] == undefined ? "" : formData["Receipt_No"];

    const data = {
      Is_Cash: Is_Cash,
      ProductName: ProductName,
      AccountName: AccountName,
      ...formData,
    };

    // console.log(data);
    setPurchaseList((prevPurchaseList) => {
      const newPurchaseList = [...prevPurchaseList, data];
      setIs_Cash(false);
      return newPurchaseList;
    });

    form.resetFields();
  };

  useEffect(() => {
    // Step 1: Reduce purchaseList to get the total quantity sold for each product
    const reducedData = Object.values(
      purchaseList.reduce((acc, item) => {
        if (!acc[item.ProductName]) {
          acc[item.ProductName] = {
            ProductName: item.ProductName,
            Price: item.Price,
            Quantity: 0,
          };
        }
        acc[item.ProductName].Quantity += parseFloat(item.Quantity);
        return acc;
      }, {})
    );

    //console.log('reducedData', reducedData);

    // const nozzel = ListofNozzels.find(
    //   (item) => item.ProductName === ProductName
    // );

    // if (nozzel) {
    // Steps 2: Calculate total sales for each product from ListofNozzels
    const productTotals = {};
    ListofNozzels.forEach((item) => {
      const productName = item.ProductName;
      const totalSale = parseFloat(
        item.Current_Reading - item.Last_Reading - item.Returned || 0
      );

      if (productName in productTotals) {
        productTotals[productName] += totalSale;
      } else {
        productTotals[productName] = totalSale;
      }
    });

    // Convert the productTotals object to an array
    const productTotalsArray = Object.entries(productTotals).map(
      ([ProductName, TotalSale]) => ({
        ProductName,
        TotalSale,
      })
    );

    //console.log('productTotalsArray', productTotalsArray);
    //console.log('purchaseList', purchaseList);

    // Step 3: Calculate the summary array
    const summaryArray = productTotalsArray.map((productTotal) => {
      const soldProduct = reducedData.find(
        (item) => item.ProductName === productTotal.ProductName
      );
      const totalSold = soldProduct ? soldProduct.Quantity : 0;
      const price = soldProduct ? soldProduct.Price : 0;
      return {
        ProductName: productTotal.ProductName,
        TotalSale: productTotal.TotalSale,
        TotalSold: totalSold,
        Price: price,
        PendingQuantity: productTotal.TotalSale - totalSold,
      };
    });

    setListOfSummary(summaryArray);

    // console.log("summaryArray", summaryArray);
    // } else {
    //   console.log("no nozzel");
    // }
  }, [purchaseList, ListofNozzels, ProductName]);

  const handleTotalChange = () => {
    const total = form.getFieldValue("Total");
    const price = form.getFieldValue("Price");
    if (total && price) {
      const quantity = total / price;
      form.setFieldsValue({ Quantity: quantity.toFixed(2) });
    }
  };


  useEffect(() => {
    // Convert Price and Quantity to numbers and calculate the total whenever they change
    const priceValue = parseFloat(Price);
    const quantityValue = parseFloat(Quantity);
    const totalValue = priceValue * quantityValue;
    const total = isNaN(totalValue) ? "" : totalValue.toString();
    form.setFieldsValue({
      total: total,
    });
  }, [Price, Quantity]);

  const handleQuantity = (e) => {
    setQuantity(e.target.value);

    const quantity = form.getFieldValue("Quantity");
    const price = form.getFieldValue("Price");
    if (quantity && price) {
      const total = quantity * price;
      form.setFieldsValue({ Total: total.toFixed(2) });
    }
  };


  const handlePriceChange = (e) => {
    const price = e.target.value;
    const quantity = form.getFieldValue("Quantity");

    if (price && quantity) {
      const total = price * quantity;
      form.setFieldsValue({ Total: total.toFixed(2) });
    }
    setPrice(price);
  };

  const handleProductChange = (value, option) => {
    setProductName(option.label);
    form.setFieldValue("Price", option.price);
  };
  const handleAccountChange = (value, option) => {
    //console.log(value);
    setAccountName(option.label);
  };
   // Calculate Quantity function
   const calculateQuantity = (item) => {
    return item.Current_Reading - item.Last_Reading - item.Returned;
  };


  const handleReturnedChange = (index, value) => {
    const newList = [...ListofNozzels];
    newList[index].Returned = parseFloat(value) || 0;
    setListofNozzels(newList);

    const updatedList = [...ListofReadings];
    const existingIndex = updatedList.findIndex(
      (item) => item.PumpMachineID === ListofNozzels[index].PumpMachineID
    );

    if (existingIndex !== -1) {
      updatedList[existingIndex].Returned = value;
    } else {
      updatedList.push({
        PumpMachineID: ListofNozzels[index].PumpMachineID,
        Last_Reading: ListofNozzels[index].Last_Reading,
        Current_Reading: ListofNozzels[index].Current_Reading,
        Returned: value,
      });
    }

    setListofReadings(updatedList);
  };

 console.log("list of nozzel", )

  const calculateTotalQuantity = (productName) => {
    return ListofNozzels.filter(
      (item) => item.ProductName === productName
    ).reduce((total, item) => total + calculateQuantity(item), 0);
  };
 
  const totalsByProductCode = {
    PET: calculateTotalQuantity("Petrol"),
    SPET: calculateTotalQuantity("Super Petrol"),
    HSD: calculateTotalQuantity("Hi Speed Diesel"),
    LDO: calculateTotalQuantity("Light Diesel Oil"),
  };

  //console.log(totalsByProductCode)

  const handleDate = (date, value) => {
    setDated(value);
  };


  const handleSaveInvoice = () => {
    if (Dated == null) {
      // console.log(Dated);
      message.error("Please Select Date");
      return;
    }

    const hasPendingQuantity = ListOfSummary.some(
      (ListOfSummary) => ListOfSummary.PendingQuantity > 0
    );

    if (hasPendingQuantity) {
      message.error("Please add remaining quantity into sale detail");
      return false;
    }

    if (ListofReadings.length == 0) {
      message.error("Please enter machine readings");
      return false;
    }

    if (purchaseList.length == 0) {
      message.error("Please add cash details");
      return false;
    }

    setLoadingAddSale(true);
    const modifiedData = purchaseList.map(
      ({ AccountName, ProductName, ...rest }) => rest
    );

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Dated: dayjs(Dated).format("YYYY-MM-DD"),
      Description: Description,
      ListofReadings: ListofReadings,
      ListofSaleDetails: modifiedData,
      ID: params.id,
    };

    // console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Sale/UpdateSale",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log("add sale", response.data);
        if (response.data.status_code == 1) {
          // Handle success
          message.success(response.data.status_message);
          setLoadingAddSale(false);
        } else {
          // Handle error
          setLoadingAddSale(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };



  const handleDelete = (index) => {
    const updatedItems = [...purchaseList];
    // Remove the item at the specified index
    updatedItems.splice(index, 1);
    // Update the state with the new array
    setPurchaseList(updatedItems);
  };

  const AddCashSale = () => {
    const productTotalsArray = [];

    // Accumulate total sales for each product
    ListofNozzels.forEach((item) => {
      const productName = item.ProductName;
      const totalSale = parseFloat(
        item.Current_Reading - item.Last_Reading - item.Returned || 0
      );

      const productIndex = productTotalsArray.findIndex(
        (product) => product.ProductName === productName
      );

      if (productIndex > -1) {
        productTotalsArray[productIndex].totalSale += totalSale;
      } else {
        productTotalsArray.push({
          Price: item.Last_Purchase_Price,
          ProductID: item.ProductID,
          ProductName: productName,
          totalSale,
        });
      }
    });

    const groupedTotalsArray = [];

    purchaseList.forEach((item) => {
      const { Product_ID, Price, Quantity, ProductName } = item;
      const quantity = parseFloat(Quantity);

      const existingProductIndex = groupedTotalsArray.findIndex(
        (product) => product.Product_ID === Product_ID
      );

      if (existingProductIndex > -1) {
        groupedTotalsArray[existingProductIndex].TotalQuantity += quantity;
      } else {
        groupedTotalsArray.push({
          Product_ID,
          ProductName,
          TotalPrice: Price,
          TotalQuantity: quantity,
        });
      }
    });

    const mergedArray = [];

    groupedTotalsArray.forEach((groupedItem) => {
      const matchingProduct = productTotalsArray.find(
        (product) => product.ProductName === groupedItem.ProductName
      );

      const remainingQuantity = matchingProduct
        ? matchingProduct.totalSale - groupedItem.TotalQuantity
        : 0;

      mergedArray.push({
        ...groupedItem,
        Price: matchingProduct ? matchingProduct.Price : 0,
        totalSale: matchingProduct ? matchingProduct.totalSale : 0,
        remainingQuantity: remainingQuantity,
      });
    });

    productTotalsArray.forEach((productItem) => {
      const isProductInGrouped = groupedTotalsArray.some(
        (groupedItem) => groupedItem.ProductName === productItem.ProductName
      );

      if (!isProductInGrouped) {
        mergedArray.push({
          Product_ID: productItem.ProductID,
          ProductName: productItem.ProductName,
          TotalPrice: 0,
          TotalQuantity: 0,
          totalSale: productItem.totalSale,
          remainingQuantity: productItem.totalSale,
          Price: productItem.Price,
        });
      }
    });

    // Filter out products with remainingQuantity equal to 0
    const newPurchaseList = mergedArray
      .filter((item) => item.remainingQuantity > 0) // Filter condition
      .map((item) => ({
        ProductName: item.ProductName,
        AccountName: "Cash",
        Product_ID: item.Product_ID,
        Account_ID: CashAccountID,
        Vehicle_No: "",
        Receipt_No: "",
        Quantity: item.remainingQuantity,
        Price: item.Price,
        Is_Cash: false,
      }));

    setPurchaseList((prevList) => [...prevList, ...newPurchaseList]);
  };

  const handleCurrentReadingChange = (index, value) => {
    const newList = [...ListofNozzels];
    newList[index].Current_Reading = parseFloat(value) || 0;
    setListofNozzels(newList);

    const updatedList = [...ListofReadings];
    const existingIndex = updatedList.findIndex(
      (item) => item.PumpMachineID === ListofNozzels[index].PumpMachineID
    );

    if (existingIndex !== -1) {
      updatedList[existingIndex].Current_Reading = parseFloat(value) || 0;
    } else {
      updatedList.push({
        PumpMachineID: ListofNozzels[index].PumpMachineID,
        Last_Reading: ListofNozzels[index].Last_Reading,
        Current_Reading: parseFloat(value) || 0,
        Returned: ListofNozzels[index].Returned,
      });
    }

    setListofReadings(updatedList);
  };

  const handleLastReadingChange = (index, value) => {
    const newList = [...ListofNozzels];
    newList[index].Last_Reading = parseFloat(value) || 0;
    setListofNozzels(newList);

    const updatedList = [...ListofReadings];
    const existingIndex = updatedList.findIndex(
      (item) => item.PumpMachineID === ListofNozzels[index].PumpMachineID
    );

    if (existingIndex !== -1) {
      updatedList[existingIndex].Last_Reading = parseFloat(value) || 0;
    } else {
      updatedList.push({
        PumpMachineID: ListofNozzels[index].PumpMachineID,
        Last_Reading: ListofNozzels[index].Last_Reading,
        Current_Reading: parseFloat(value) || 0,
        Returned: ListofNozzels[index].Returned,
      });
    }

    setListofReadings(updatedList);
  };
  
  const columnsPumpReadings = [
    {
      title: "Sr.No.",
      dataIndex: "Sr.No.",
      key: "Sr.No.",
      render: (_, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <div className="table-avatar">
            <div className="icon">
              <span>{index + 1}</span>
            </div>
          </div>
          <NozzleOil />
        </div>
      ),
    },
    {
      title: "Product",
      dataIndex: "ProductCode",
      key: "ProductCode",
    },
    {
      title: "Last Reading",
      dataIndex: "Last_Reading",
      key: "Last_Reading",
      render: (_, record, index) => (
        <Input
          value={record.Last_Reading}  // Controlled input value
          placeholder="Last Reading"
          onChange={(e) =>
            handleLastReadingChange(index, e.target.value)  // Pass index and new value
          }
        />
      ),
    },
    {
      title: "Current Reading",
      dataIndex: "Current_Reading",
      key: "Current_Reading",
      render: (_, record, index) => (
        <Input
          value={record.Current_Reading}  // Controlled input value
          placeholder="Current Reading"
          onChange={(e) =>
            handleCurrentReadingChange(index, e.target.value)  // Pass index and new value
          }
        />
      ),
    },
    // {
    //   title: "Current Reading",
    //   dataIndex: "Current_Reading",
    //   key: "Current_Reading",
    //   render: (_, record, index) => (
    //     <Input
    //       value={record.Current_Reading}  // Make sure the input value is controlled by record.Current_Reading
    //       placeholder="Current Reading"
    //       onChange={(e) =>
    //         handleCurrentReadingChange(index, e.target.value) // Handle the change correctly
    //       }
    //     />
    //   ),
    // },
    
    {
      title: "Returned",
      dataIndex: "Returned",
      key: "Returned",
      render: (_, record, index) => (
        <Input
          value={record.Returned}
          placeholder="Returned"
          onChange={(e) => handleReturnedChange(index, e.target.value)}
        />
      ),
    },
    {
      title: "Quantity",
      dataIndex: "Quantity",
      key: "Quantity",
      render: (_, record) => {
        const quantity = calculateQuantity(record);
        return quantity < 0 ? (
          <span style={{ color: "red", fontSize: "12px" }}>{quantity}</span>
        ) : (
          quantity
        );
      },
    },
  ];
 
 

 
 

  

  const columnsCreditSale = [
    {
      title: "Sr.No.",
      dataIndex: "Sr.No.",
      key: "Sr.No.",
      render: (_, record, index) => (
        <div className="table-avatar">
          <div className="icon">
            <span>{index + 1}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Product",
      dataIndex: "ProductName",
      key: "ProductName",
      render: (_, record) =>
        record.isFormRow ? (
          <Form.Item
            name="Product_ID"
            rules={[{ required: true, message: "Please select a product!" }]}
          >
            <Select
              showSearch
              style={{ width: '200px' }}
              placeholder="Select Product"
              loading={!productTypesArray}
              options={productTypesArray}
              onChange={handleProductChange}
              optionFilterProp="label"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
        ) : (
          record.ProductName
        ),
    },
    {
      title: "Account",
      dataIndex: "AccountName",
      key: "AccountName",
      render: (_, record) =>
        record.isFormRow ? (
          <Form.Item
            name="Account_ID"
            rules={[{ required: true, message: "Please select an account!" }]}
          >
            <Select
              showSearch
              style={{ width: '150px' }}
              placeholder="Select Account"
              loading={!accountTypesArray}
              options={accountTypesArray}
              onChange={handleAccountChange}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
        ) : (
          record.AccountName
        ),
    },
    {
      title: "Vehicle No",
      dataIndex: "Vehicle_No",
      key: "Vehicle_No",
      render: (_, record) =>
        record.isFormRow ? (
          <Form.Item name="Vehicle_No">
            <Input placeholder="Vehicle Number" />
          </Form.Item>
        ) : (
          record.Vehicle_No
        ),
    },
    {
      title: "Receipt No",
      dataIndex: "Receipt_No",
      key: "Receipt_No",
      render: (_, record) =>
        record.isFormRow ? (
          <Form.Item name="Receipt_No">
            <Input placeholder="Receipt No" />
          </Form.Item>
        ) : (
          record.Receipt_No
        ),
    },
    {
      title: "Quantity",
      dataIndex: "Quantity",
      key: "Quantity",
      render: (_, record) =>
        record.isFormRow ? (
          <Form.Item
            name="Quantity"
            rules={[{ required: true, message: "Please enter quantity!" }]}
          >
            <Input placeholder="Quantity" onChange={handleQuantity} />
          </Form.Item>
        ) : (
          // record.Quantity
          NumberFormat(parseFloat(record.Quantity), 2)
        ),
    },
    {
      title: "Price",
      dataIndex: "Price",
      key: "Price",
      render: (_, record) =>
        record.isFormRow ? (
          <Form.Item
            name="Price"
            rules={[{ required: true, message: "Please enter price!" }]}
          >
            <Input placeholder="Price" onChange={handlePriceChange} />
          </Form.Item>
        ) : (
          NumberFormat(record.Price, 4)
        ),
    },
    {
      title: "Total",
      dataIndex: "Total",
      key: "Total",
      render: (_, record) =>
        record.isFormRow ? (
          <Form.Item name="Total">
            <Input placeholder="Total" onChange={handleTotalChange} />
          </Form.Item>
        ) : (
          NumberFormat(parseFloat(record.Price) * parseFloat(record.Quantity), 2)
        ),
    },
    {
      title: "Cash",
      dataIndex: "Is_Cash",
      key: "Is_Cash",
      render: (_, record, index) =>
        record.isFormRow ? (
          <Form.Item>

            <Checkbox checked={Is_Cash} onChange={(e) => setIs_Cash(e.target.checked)}>
              Cash
            </Checkbox>
          </Form.Item>
        ) : (
          <Checkbox
            checked={record.Is_Cash}
            onChange={() => handleCheckboxChange(index)}
          />
        ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) =>
        record.isFormRow ? (
          <Button type="primary" shape="round" htmlType="submit" size="small">
            Add
          </Button>
        ) : (
          <DeleteOutlined onClick={() => handleDelete(record.key)} />
        ),
    },
  ];
  const columnsSaleSummary = [
    {
      title: "Sr.No.",
      dataIndex: "Sr.No.",
      key: "Sr.No.",
      render: (_, record, index) => (
        <div className="table-avatar">
          <div className="icon">
            <span>{index + 1}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Product",
      dataIndex: "ProductName",
      key: "ProductName",
    },
    {
      title: "Price / Ltr.",
      dataIndex: "Price",
      key: "Price",
      render:(_,record)=>
        NumberFormat(parseFloat(record.Price), 4)
    },
    {
      title: "Out From Machine",
      dataIndex: "TotalSale",
      key: "TotalSale",
    },
    {
      title: "Total Cost",
      dataIndex: "Total",
      key: "Total",
      render: (_, record) =>
        NumberFormat(parseFloat(record.TotalSale) * parseFloat(record.Price), 2)
    },
    {
      title: "Sale Qty",
      dataIndex: "TotalSold",
      key: "TotalSold",
      render: (_, record) =>
        NumberFormat(parseFloat(record.TotalSold), 2)
    },
    {
      title: "Sale Cost",
      dataIndex: "Total",
      key: "Total",
      render: (_, record) =>
        NumberFormat(parseFloat(record.Price) * parseFloat(record.TotalSold), 2)
    },
    {
      title: "Remaining Qty",
      dataIndex: "PendingQuantity",
      key: "PendingQuantity",
      render: (_, record) =>
        NumberFormat(parseFloat(record.PendingQuantity), 2)
    },
    {
      title: "Remaining Sale",
      dataIndex: "Total",
      key: "Total",
      render: (_, record) =>
        NumberFormat((parseFloat(record.TotalSale) * parseFloat(record.Price) - parseFloat(record.TotalSold) * parseFloat(record.Price)), 2)
    },
  ];

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">
              <NavLink to="/sales/view-sales">
                <ChevronLeftIcon />
              </NavLink>
              Edit Sale
            </h3>
          </div>

          {loading ? (
            <>
              <Row gutter={[34, 0]}>
                <Col xs={24} md={16}>
                  <Skeleton active />
                  <Skeleton active />
                </Col>
                <Col xs={24} md={8}>
                  <Skeleton active />
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row gutter={[24, 10]}>
                <Col md={3} xs={24}>
                  <Form.Item className="input-vertical" label="Invoice No">
                    <Input value={InvoiceNo} readOnly />
                  </Form.Item>
                </Col>
                <Col md={4} xs={24}>
                  <Form.Item className="input-vertical" label="Date">
                    <DatePicker
                      defaultValue={Dated}
                      style={{ width: "100%" }}
                      onChange={handleDate}
                    />
                  </Form.Item>
                </Col>
                <Col md={17} xs={24}>
                  <Form.Item className="input-vertical" label="Description" >
                    <Input
                      value={Description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {!WithoutNozzel && (
                <Row gutter={[24, 24]} className="mb-5">
                  <Col md={16}>
                    <div className="fb-card">
                      <div className="fb-card-header">
                        <div className="fb-card-title">
                          <h4>Step 1 Enter Pump Readings</h4>
                        </div>
                      </div>
                      <div className="fb-card-body table">
                        <Table
                          columns={columnsPumpReadings}
                          dataSource={[...ListofNozzels]}
                          pagination={false}
                          loading={loading}
                          scroll={{ x: "100%" }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={8}>

                    {ListofNozzelsCard.filter((item) => {
                      const isProductCodeValid = [
                        "PET",
                        "SPET",
                        "HSD",
                        "LDO",
                      ].includes(item.ProductCode);

                      if (
                        isProductCodeValid && !uniqueProductNames.has(item.ProductName)) {
                        uniqueProductNames.add(item.ProductName);
                        return true;
                      }
                      return false;
                    }).map((item, index) => (
                      <div style={{display:'flex', flexDirection:'column', marginTop:'10px'}} key={index}>
                      <Space direction="vertical" size={10}>
                        <BoxCard
                          title={`Total ${item.ProductName} Sale`}
                          loading={loading}
                          amount={totalsByProductCode[item.ProductCode]}
                          suffix="Ltr"
                          icon={<NozzleOil />}
                        />
                      </Space>
                      </div>
                    ))}
                  </Col>
                </Row>
              )}
              <Row gutter={[24, 24]} className="mb-5">
                <Col md={24}>
                  <div className="fb-card">
                    <div className="fb-card-header">
                      <div className="fb-card-title">
                        <h4>Step 2 Enter Credit Sale Details</h4>
                      </div>
                    </div>
                    <div className="fb-card-body table">
                      <Form onFinish={handleSubmit} form={form}>
                        <Table
                          columns={columnsCreditSale}
                          dataSource={[...purchaseList, { isFormRow: true }]} // Add the form as the last row
                          pagination={false}
                          loading={loading}
                          scroll={{ x: "100%" }}
                          summary={() => (totals.totalPrice > 0 &&
                            <>
                              <Table.Summary.Row className="table-footer">
                                <Table.Summary.Cell colSpan={9} align="right">
                                  <b>Total Sale</b>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell align="right">
                                  {NumberFormat(totals.totalPrice, 2)}
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                              <Table.Summary.Row>
                                <Table.Summary.Cell colSpan={9} align="right">
                                  <b>Total Cash Sale</b>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell align="right">
                                  {NumberFormat(totals.cashSaleTotal, 2)}
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                              <Table.Summary.Row>
                                <Table.Summary.Cell colSpan={9} align="right">
                                  <b>Total Credit Sale</b>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell align="right">
                                  {NumberFormat(totals.creditSaleTotal, 2)}
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            </>
                          )}
                        />
                      </Form>
                    </div>
                  </div>
                </Col>
                <Col md={24}>
                  <Row justify="end">
                    <Space size={10}>
                      <Button
                        type="default"
                        size="large"
                        shape="round"
                        onClick={AddCashSale}
                      >
                        Add Cash Sale
                      </Button>
                      <Button
                        onClick={handleSaveInvoice}
                        type="primary"
                        size="large"
                        shape="round"
                        loading={loadingAddSale}
                        disabled={
                          ListofReadings.length == 0 || purchaseList.length == 0 || ListOfSummary.some(item => item.PendingQuantity < 0)
                        }
                      >
                        Save Invoice
                      </Button>
                    </Space>
                  </Row>
                </Col>
                <Col md={24}>
                  <div className="fb-card">
                    <div className="fb-card-header">
                      <div className="fb-card-title">
                        <h4>Sale Summary</h4>
                      </div>
                    </div>
                    <div className="fb-card-body table"
                    >
                      <Form onFinish={handleSubmit} form={form}>
                        <Table
                          columns={columnsSaleSummary}
                          dataSource={ListOfSummary}
                          pagination={false}
                          loading={loading}
                          scroll={{ x: "100%" }}
                        />
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default EditSales;
