import React, { useEffect, useState } from "react";
import LoginChecked from "../Shared/LoginChecked";
import Header from "../Layout/Header";
// import SalesMenu from "./SalesMenu";
import axios from "axios";
import config from "../../config";
import {
  Space,
  Table,
  Popconfirm,
  Pagination,
  Row,
  Col,
  Breadcrumb,
  Button,
  Input,
  DatePicker,
  Form,
  Select,
  message,
  Tooltip,
} from "antd";
import { Link, NavLink } from "react-router-dom";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { NumberFormat } from "../Shared/Utility";

function ViewSales() {
  const [currentPage, setCurrentPage] = useState(1);

  const { RangePicker } = DatePicker;
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);

  const [StartDate, SetStartDate] = useState("");
  const [EndDate, SetEndDate] = useState("");

  const [ListofSale, SetListofSale] = useState([]);
  const [ListofSaleWithTotal, SetListofSaleWithTotal] = useState([]);
  const [TotalSales, SetTotalSales] = useState("");
  const [CashTotal, SetCashTotal] = useState("");
  const [CreditTotal, SetCreditTotal] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    document.title = "Manage Sales";

    setLoading(true);
    setIsDeleted(false);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      PageNo: pageNo,
      PageSize: pageSize,
    };
    // console.log("🚀 ~ useEffect ~ data:", data);

    var api_config = {
      method: "post",
      url: config.base_url + "Sale/GetSales",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    //console.log(api_config);

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          SetListofSale(response.data.listofSale);
          SetTotalSales(response.data.TotalSales);
          SetCashTotal(response.data.CashTotal);
          SetCreditTotal(response.data.CreditTotal);
          setTotalRecords(response.data.totalRecords);

          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [StartDate, EndDate, pageNo, pageSize, isDeleted]);

  useEffect(() => {
    // const totals = [
    //   {
    //     sr: null,
    //     TotalDebits: 0,
    //   },
    //   {
    //     sr: null,
    //     TotaCredits: 0,
    //   },
    // ];

    // const total = {
    //   sr: null,
    //   ID: null,
    //   InvoiceNo: null,
    //   Dated: null,
    //   Description: null,
    //   Cash_Total: Math.ceil(CashTotal),
    //   Credit_Total: Math.ceil(CreditTotal),
    //   TotalSales: Math.ceil(TotalSales),
    // };

    // let newlist = [...ListofSale,total];

    let newlist = [...ListofSale];
    SetListofSaleWithTotal(newlist);
  }, [ListofSale]);

  const handelDelete = (ID) => {
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: ID,
    };
    // console.log("data", data);
    const api_config = {
      method: "post",
      url: config.base_url + "Sale/DeleteSales",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //  console.log("response", response.data);
        if (response.data.status_code === 1) {
          setIsDeleted(true);
          setLoading(false);
          message.success(response.data.status_message);
        } else {
          setLoading(false);
          message.error(response.data.status_message);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "Sr.#",
      dataIndex: "sr",
      key: "sr",
      render: (_, record, index) =>
        record.ID ? index + 1 + pageSize * (pageNo - 1) : null,
    },

    {
      title: "Invoice No",
      dataIndex: "InvoiceNo",
      key: "InvoiceNo",
    },

    {
      title: "Dated",
      dataIndex: "Dated",
      key: "Dated",
      render: (_, record) =>
        record.ID != null ? moment(record.Dated).format("DD MMM YYYY") : "",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      // render: (_, record) =>
      //   record.ID != null ? (
      //     record.Description
      //   ) : (
      //     <strong style={{ display: "block", textAlign: "right" }}>
      //       Total
      //     </strong>
      //   ),
    },
    {
      title: "Cash",
      dataIndex: "Cash_Total",
      key: "Cash_Total",
      align: "right",
      render:(_,record)=>{
        return NumberFormat(record.Cash_Total,0)
      }
      // render: (_, record) =>
      //   record.ID != null ? (
      //     record.Cash_Total
      //   ) : (
      //     <strong>{record.Cash_Total}</strong>
      //   ),
    },
    {
      title: "Credit",
      dataIndex: "Credit_Total",
      key: "Credit_Total",
      align: "right",
      render:(_,record)=>{
        return NumberFormat(record.Credit_Total,0)
      }
      //   render: (_, record) =>
      //     record.ID != null ? (
      //       record.Credit_Total
      //     ) : (
      //       <strong>{record.Credit_Total}</strong>
      //     ),
    },
    {
      title: "Total",
      dataIndex: "TotalSales",
      key: "TotalSales",
      // align: "right",
      render: (_, record) =>
        record.ID != null ? (
          NumberFormat(record.TotalSales,0)
        ) : (
          <strong>{NumberFormat(record.TotalSales,0)}</strong>
        ),
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) =>
        record.ID != null ? (
          <Space size="middle">
            <NavLink to={`/sales/edit-sales/${record.ID}`}>
              <Tooltip title="Edit Sale">
                <EditOutlined />
              </Tooltip>
            </NavLink>
            <Popconfirm title="Sure to delete?"
              discription="Are You sure to delete this data"
              onConfirm={(e) => handelDelete(record.ID)}
            >
              <NavLink to={`#`}>
                <Tooltip title="Delete Sale">
                  <DeleteOutlined />
                </Tooltip>
              </NavLink>
            </Popconfirm>
            <NavLink to={`/sales/printnewsale/${record.ID}`} target="blank">
              <Tooltip title="Print Sale">
                <PrinterOutlined />
              </Tooltip>
            </NavLink>
          </Space>
        ) : (
          ""
        ),
    },
  ];

  const getFooterContent = () => (
    <>
      <Row gutter={[16, 16]}>
        {/* First Column */}
        <Col md={8} xs={24}>
          <Row>
            <Col md={8} xs={20}>
              <p>
                <b>Cash Total:</b>
              </p>
            </Col>
            <Col md={6} xs={4}>
              <p>{NumberFormat(CashTotal,0)}</p>
            </Col>
          </Row>
        </Col>
        <Col md={8} xs={24}>
          <Row>
            <Col md={8} xs={20}>
              <p>
                <b>Credit Total:</b>
              </p>
            </Col>
            <Col md={6} xs={4}>
              <p>{NumberFormat(CreditTotal,0)}</p>
            </Col>
          </Row>
        </Col>
        <Col md={8} xs={24}>
          <Row>
            <Col md={7} xs={20}>
              <p>
                <b>Total Sale:</b>
              </p>
            </Col>
            <Col md={6} xs={4}>
              <p>{NumberFormat(TotalSales,0)}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );


  const onPageChange = (page, pageSize) => {
    setPageNo(page);
  };

  const onPageSizeChange = (current, size) => {
    setPageNo(1); // Reset to the first page when changing page size
    setPageSize(size);
  };



  const handleDateChange = (date, value) => {
    SetStartDate(value[0]);
    SetEndDate(value[1]);
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Sales</h3>

            <div className="header-actions">
              <NavLink
                to={`/sales/print-sale-list/?StartDate=${StartDate}&EndDate=${EndDate}`}
                target="blank"
              >
                <Button type="dashed" size="large" shape="round">
                  <PrinterOutlined /> Print Report
                </Button>
              </NavLink>
              <NavLink to="/sales/add-sales">
                <Button type="primary" size="large" shape="round">
                  <PlusOutlined style={{ color: "white" }} /> Add Sale
                </Button>
              </NavLink>
            </div>
          </div>
          <div className="filters-wrap">
            <Form>
              <RangePicker allowClear onChange={handleDateChange}
                disabledDate={disabledDate} />
            </Form>
          </div>

          <Table
            size="small"
            scroll={{
              x: "100%",
            }}
            loading={loading}
            columns={columns}
            dataSource={ListofSaleWithTotal}
            bordered
            pagination={false} // Remove table pagination
            footer={getFooterContent}
          />
          <div className="text-end mt-5">
            <Pagination
              current={pageNo}
              pageSize={pageSize}
              size="small"
              total={totalRecords}
              onChange={onPageChange}
              onShowSizeChange={onPageSizeChange} // Handle page size change
              showSizeChanger
              showQuickJumper
              showTotal={(total) => `Total ${total} items`}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewSales;
