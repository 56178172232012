import React, { useState, useEffect } from 'react';
import config from '../../config';
import axios from 'axios';
import dayjs from 'dayjs';


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Select, Skeleton } from "antd";
import { Cash } from '../UserControls/Icons';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


const CashInHandChart = () => {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  const [StartDate, setStartDate] = useState(dayjs().subtract(7, "day").format("YYYY-MM-DD"));
  const [EndDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));

  const [Value, setValue] = useState(7);
  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Sale/GetCashFlowGraph",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    // console.log(data)

    axios(api_config)
      .then(function (response) {
        // console.log(response.data)
        if (response.data.status_code == 1) {
          // Handle success
          const Cash_in_hand = response.data.ListofCashFlow;
          const Cash_in_bank = response.data.ListofBankFlow;

          const data = response.data.ListofDates;

          const labels = data.map((item) =>
            dayjs(item).format("DD-MM-YYYY")
          );

          setChartData({
            labels: labels,
            datasets: [
              {
                label: "Cash in Hand",
                data: Cash_in_hand,
                backgroundColor: "rgb(119, 195, 253)",
                borderColor: "rgb(119, 195, 253)",
                borderWidth: 0,
                fill: false,
                tension: 0.3,
              },
              {
                label: "Cash in Bank Accounts",
                data: Cash_in_bank,
                backgroundColor: "rgb(198, 219, 236)",
                borderColor: "rgb(198, 219, 236)",
                borderWidth: 0,
                fill: false,
                tension: 0.3,
              },
            ],
          });

          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  }, [StartDate, EndDate]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          usePointStyle: true,
        },
      },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: false,
        grid: {
          color: '#ededeb',
          lineWidth: 1,
        },
      },
    },
  };

  const handleDateChange = (value) => {
    setValue(value);
    setEndDate(dayjs().format("YYYY-MM-DD"));
    setStartDate(dayjs().subtract(value, "day").format("YYYY-MM-DD"));
  };

  return (
    <>

      <div className="fb-card">
        <div className="fb-card-header">
          <div className="fb-card-title">
            <span>
              <Cash />
            </span>
            <h4>Cash Flow</h4>
          </div>
          <div className="header-action">
            <Select
              style={{ width: 200 }}
              placeholder="Select duration"
              onChange={handleDateChange}
              value={Value}
              options={[
                {
                  label: "Last 7 Days",
                  value: 7,
                },
                {
                  label: "Last 15 Days",
                  value: 15,
                },
                {
                  label: "Last 30 Days",
                  value: 30,
                },
              ]}
            />
          </div>
        </div>
        <div className="fb-card-body table">
          {loading ? (
            <>
              <Skeleton active />
              <Skeleton active />
            </>
          ) : (
            <>
              <Bar
                data={chartData}
                options={options}
              />
            </>
          )}
        </div>
      </div>

    </>
  );
};

export default CashInHandChart;
