import React, { useState, useRef } from "react";
import { Layout, Input, Button, List, Avatar, Upload } from "antd";
import { AudioOutlined,LinkOutlined } from "@ant-design/icons";
import moment from "moment";


const { Header, Content } = Layout;

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [fileList, setFileList] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const sendMessage = () => {
    if (inputValue.trim() || fileList.length > 0) {
      const newMessage = {
        content: inputValue,
        sender: "Me",
        time: moment().format("LT"),
        files: fileList,
      };
      setMessages([...messages, newMessage]);
      setInputValue("");
      setFileList([]);
    }
  };

  const handleFileChange = (file) => {
    setFileList([...fileList, file]);
  };

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);

    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };

    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
      const audioFile = new File(
        [audioBlob],
        `voice_message_${Date.now()}.wav`,
        { type: "audio/wav" }
      );
      handleFileChange(audioFile);
      audioChunksRef.current = []; // Reset for next recording
    };

    mediaRecorderRef.current.start();
    setIsRecording(true);
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setIsRecording(false);
  };

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <Layout
          style={{ background: "rgb(241, 245, 249)", borderRadius: "10px" }}
        >
          <Header
            style={{
              background: "rgb(148 239 228)",
              color: "rgb(2 122 106)",
              textAlign: "center",
            }}
          >
            <h2 style={{ margin: 0 }}>Customer Support Chat</h2>
          </Header>
          <Content
            style={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                flex: 1,
                overflowY: "auto",
                marginBottom: "20px",
                padding: "10px",
              }}
            >
              <List
                dataSource={messages}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar>{item.sender.charAt(0)}</Avatar>}
                      title={
                        <span
                          style={{
                            color: item.sender === "Me" ? "#25d366" : "#000",
                          }}
                        >
                          {item.sender}
                        </span>
                      }
                      description={
                        <div
                          style={{
                            textAlign: item.sender === "Me" ? "right" : "left",
                          }}
                        >
                          <div
                            style={{
                              display: "inline-block",
                              padding: "10px",
                              borderRadius: "8px",
                              background:
                                item.sender === "Me" ? "#dcf8c6" : "#fff",
                              maxWidth: "70%",
                              margin: "5px 0",
                              boxShadow: "0 1px 5px rgba(0,0,0,0.2)",
                            }}
                          >
                            {item.content}
                            {item.files &&
                              item.files.map((file, index) => (
                                <div key={index} style={{ marginTop: "5px" }}>
                                  <a
                                    href={URL.createObjectURL(file)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {file.name}
                                  </a>
                                </div>
                              ))}
                          </div>
                          <div
                            style={{
                              fontSize: "12px",
                              color: "#888",
                              marginTop: "2px",
                            }}
                          >
                            {item.time}
                          </div>
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
            </div>
            <div style={{ display: "flex" }}>
              <Input
                placeholder="Type a message"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onPressEnter={sendMessage}
                style={{ flex: 1, marginRight: "10px" }}
              />

              {isRecording ? (
                <Button
                  type="danger"
                  onClick={stopRecording}
                  style={{
                    marginLeft: "10px",
                    border: "none",
                    background: "none",
                  }} // Custom styling
                >
                  Stop
                </Button>
              ) : (
                <Button
                  type="default"
                  onClick={startRecording}
                  style={{
                    marginLeft: "10px",
                    border: "none", 
                    background: "none", 
                    padding: 0, 
                  }}
                  icon={<AudioOutlined style={{ fontSize: "24px" }} />} 
                />
              )}
              <Upload
                beforeUpload={(file) => {
                  handleFileChange(file);
                  return false;
                }}
                showUploadList={false}
              >
                <Button
                  type="primary"
                  shape="round"
                  style={{
                    marginLeft: "10px",
                    border: "none", 
                    background: "none", 
                    padding: 0, 
                  }}
                  icon={<LinkOutlined  style={{ fontSize: "24px", color:'black' }}/>}
                />
                  
                
              </Upload>
              <Button
                type="primary"
                size="large"
                shape="round"
                onClick={sendMessage}
              >
                Send
              </Button>
            </div>
          </Content>
        </Layout>
      </div>
    </>
  );
};

export default Chat;
