import React, { useState } from "react";
import { Card, Menu } from "antd";
import {
  CarryOutOutlined,
  FolderOpenOutlined,
  FormOutlined,
  MailOutlined,
} from "@ant-design/icons";
import CreateTicket from "./CreateTicket";
import Inbox from "./Inbox";
import OpenChat from "./OpenChat";
import CloseChat from "./CloseChat";
import Chat from "./Chat";

const Support = () => {
  const [current, setCurrent] = useState("create");

  const onClick = (e) => {
    setCurrent(e.key);
    console.log("click ", e.key);
  };

  const items = [
    {
      label: "Create Ticket",
      key: "create",
      icon: <FormOutlined />,
    },
    {
      label: "Inbox",
      key: "inbox",
      icon: <MailOutlined />,
    },
    {
      label: "Closed",
      key: "closed",
      icon: <CarryOutOutlined />,
    },
    {
      label: "Open",
      key: "open",
      icon: <FolderOpenOutlined />,
    },
  ];

  const renderContent = () => {
    switch (current) {
      case "create":
        return <CreateTicket />;
      case "inbox":
        return <Inbox />;
      case "closed":
        return (
          <CloseChat />
        );
      case "open":
        return (
         <OpenChat />
        // <Chat />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Support</h3>
          </div>
          <Menu
            onClick={onClick}
            selectedKeys={[current]}
            mode="horizontal"
            items={items}
          />
          {renderContent()}
        </div>
      </div>
    </>
  );
};

export default Support;
