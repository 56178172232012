import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Button, Tooltip } from "antd";
import { Menu, Switch } from "antd";
import {
  PercentageOutlined,
  SettingOutlined,
  AppstoreOutlined,
  UserOutlined,
  CreditCardOutlined,
  FilePdfOutlined,
  LineChartOutlined,
  IdcardOutlined,
  ShoppingCartOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";

const items = [
  {
    key: "sub1",
    label: <NavLink to="/dashboard/analytics"> Dashboard</NavLink>,
    icon: <AppstoreOutlined />,
  },
  {
    key: "sub2",
    label: <NavLink to="/accounts/manage-accounts"> Accounts</NavLink>,
    icon: <UserOutlined />,
  },
  {
    key: "sub3",
    label: <NavLink to="/purchase/manage-purchase">Purchase</NavLink>,
    icon: <CreditCardOutlined />,
  },
  {
    key: "sub4",
    label: <NavLink to="/sales/view-sales">Sales</NavLink>,
    icon: <LineChartOutlined />,
  },
  {
    key: "sub5",
    label: 'Vouchers',
    icon: <IdcardOutlined />,

    children: [

      {
        key: "d-3",
        label: <NavLink to="/vouchers/manage-vouchers">General Voucher</NavLink>,
      },
      {
        key: "d-1",
        label: <NavLink to="/vouchers/payment">Payments</NavLink>,
      },


      {
        key: "d-2",
        label: <NavLink to="/vouchers/receipts">Receipts</NavLink>,
      },
      {
        key: "d-4",
        label: <NavLink to="/vouchers/expense">Expenses</NavLink>,
      },
      {
        key: "d-5",
        label: <NavLink to="/vouchers/credit-sale">Credit Sales</NavLink>,
      },
      {
        key: "d-6",
        label: <NavLink to="/vouchers/credit-purchase">Credit Purchase</NavLink>,
      },
    ],


  },
  {
    key: "sub6",
    label: "Reports",
    icon: <FilePdfOutlined />,
    children: [
      {
        key: "r-1",

        label: (
          <NavLink to="/reports/cash-receiveable">Cash Receiveable</NavLink>
        ),
      },
      {
        key: "r-2",

        label: <NavLink to="/reports/cash-payable">Cash Payable</NavLink>,
      },
      {
        key: "r-3",

        label: <NavLink to="/reports/summary-sheet">Summary Sheet</NavLink>,
      },
      {
        key: "r-4",

        label: (
          <NavLink to="/reports/purchases-report">Purchase Report</NavLink>
        ),
      },
      {
        key: "r-5",

        label: <NavLink to="/reports/sales-report">Sales Report</NavLink>,
      },
      {
        key: "r-6",

        label: (
          <NavLink to="/reports/trading-accounts">Trading Account</NavLink>
        ),
      },
      {
        key: "r-7",

        label: <NavLink to="/reports/profit-on-sales">Profit on Sale</NavLink>,
      },
      {
        key: "r-8",

        label: <NavLink to="/reports/expenses-report">Expenses Report</NavLink>,
      },

      {
        key: "r-10",

        label: (
          <NavLink to="/reports/date-wise-summary">Datewise Summary</NavLink>
        ),
      },
      {
        key: "r-11",

        label: (
          <NavLink to="/reports/date-wise-product-summary">
            Datewise Product Summary
          </NavLink>
        ),
      },
      {
        key: "r-9",

        label: (
          <NavLink to="/reports/datewise-productwise-sale">
            Date Wise Product Wise Sale Report
          </NavLink>
        ),
      },
    ],
  },

  {
    key: "sub7",
    label: "Dip Setting",
    icon: <PercentageOutlined />,
    children: [

      {
        key: "d-3",
        label: <NavLink to="/dipsettings">Add DIP</NavLink>,
      },
      {
        key: "d-1",
        label: <NavLink to="/dipsettings/summary">DIP Summary</NavLink>,
      },


      {
        key: "d-2",
        label: <NavLink to="/dipsettings/manage-tanks">Manage Tanks</NavLink>,
      },
    ],
  },
  {
    key: "sub8",
    label: "Products",
    icon: <ShoppingCartOutlined />,
    children: [
      {
        key: "p-2",

        label: <NavLink to="/products/manage-product">Manage Products</NavLink>,
      },

      {
        key: "p-4",

        label: (
          <NavLink to="/products/manage-machines">Manage Nozzles</NavLink>
        ),
      },
      {
        key: "p-5",

        label: (
          <NavLink to="/products/rate-adjust-for-income">
            Rate Adjustment for Income
          </NavLink>
        ),
      },
    ],
  },
  {
    key: "sub9",
    label: "Settings",
    icon: <SettingOutlined />,
    children: [
      {
        key: "s-1",
        label: (
          <NavLink to="/security/change-password">Change Password</NavLink>
        ),
      },
      {
        key: "s-2",
        label: (
          <NavLink to="/security/change-company-info">
            Change Company Info
          </NavLink>
        ),
      },
      {
        key: "s-3",
        label: <NavLink to="/security/login-logs">Login Logs</NavLink>,
      },
      {
        key: "s-4",
        label: <NavLink to="/security/manage-users">Manage Users</NavLink>,
      },
    ],
  },
];

function LeftMenu() {

  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      const handleClick = (e) => {
        const menuItems = document.getElementsByClassName("ant-menu-item");
        const sideBar = document.getElementById("side-bar");
        const menuButton = document.getElementById("menubutton");
        const overlayMenu = document.getElementById("overlaymenu");

        const menuItemsArray = Array.from(menuItems);
        const menuItemClicked = menuItemsArray.some((menuItem) =>
          menuItem.contains(e.target)
        );

        if (menuItemClicked) {
          if (menuButton) {
            menuButton.classList.toggle("active");
          }

          if (overlayMenu) {
            overlayMenu.classList.toggle("active");
          }

          if (sideBar) {
            sideBar.classList.toggle("active");
          }
        }
      };

      if (width <= 767) {
        window.addEventListener("click", handleClick);
      } else {
        window.removeEventListener("click", handleClick);
      }

      // Clean up event listener on component unmount
      return () => {
        window.removeEventListener("click", handleClick);
      };
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className={`left-side`} id="side-bar">
        {/* <Button
          type="primary"
          onClick={toggleCollapsed}
          style={{
            marginBottom: 16,
          }}
        >
          {collapsed ?
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
            :
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
            </svg>
          }
        </Button> */}

        <div className="side-wrap">
          <Menu mode="inline"
            // inlineCollapsed={collapsed}
            defaultOpenKeys={["sub1"]} items={items} />
        </div>
        <div className="sidear-bottom-info">
          <p>&copy; 2024 Fuelbook. All rights reserved.<br /> Version 1.2</p>
        </div>
      </div>
    </>
  );
}

export default LeftMenu;
