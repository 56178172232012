import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
import LoginChecked from "../Shared/LoginChecked";
import ProductsDropDown from "../Shared/ProductsDropDown";
import { Table, Row, Button, DatePicker, Form,Select ,Col} from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import moment from "moment";
import BoxCard from "../Shared/BoxCard";

function Summary() {
  const { RangePicker } = DatePicker;
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [StartDate, SetStartDate] = useState("");
  const [EndDate, SetEndDate] = useState("");
  const [listofMachine, SetlistofMachine] = useState([]);
  const [PageSize,setPageSize]=useState(50);
  const [PageNo,setPageNo]=useState(1);
  const [ProductID,setProductID]=useState(0);
  const[data,setDate]=useState("");
  const ProductType = ProductsDropDown();
  

  useEffect(() => {
    document.title = "Summary";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      PageNo: PageNo,
      PageSize:PageSize,
      ProductID:ProductID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Product/GetDIPSummary",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    console.log(
      "🚀 ~ useEffect ~yyyyyyyyyyyy api_config.data:",
      api_config.data
    );
    axios(api_config)
      .then(function (response) {
        console.log("response",response.data);
        if (response.data.status_code == 1) {
          // Handle success
          SetlistofMachine(response.data.listofLedger);
          setDate(response.data);

          setLoading(false);
        } else {
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [StartDate, EndDate,PageNo,PageSize,ProductID]);

  console.log("data",data)

  const columns = [
    {
      title: "SR",
      dataIndex: "Sr",
      key: "Sr",
      render: (_, record, index) => index + 1 + PageSize * (PageNo - 1),
    },
    {
      title: "Dated",
      dataIndex: "Dated",
      key: "Dated",
      render: (date) => moment(date).format(config.date_format),
    },
    {
      title: "Product",
      dataIndex: "Name",
      key: "Product",
    },
    {
      title: "Dip Stock",
      dataIndex: "TotalPhysicalStock",
      key: "Dip Stock",
    },
    {
      title: "Actual Stock",
      dataIndex: "TotalSystemStock",
      key: "Actual Stock",
    },
    {
      title: "Difference",
      dataIndex: "DifferenceQuantity",
      key: "Difference",
    },
    {
      title: "Adjustment Rate",
      dataIndex: "AdjustmentRate",
      key: "Adjustment Rate",
      align: "right",
    },
    {
      title: "Amount",
      dataIndex: "AdjustmentAmount",
      key: "Amount",
      align: "right",
    },
  ];

  // const getFooterContent = () => (
  //   <Row justify="space-between">
  //     <h1>Total Gain:</h1>
  //     <h1>Total Loss:</h1>
  //     <h1>Difference:</h1>
  //   </Row>
  // );

  const handleDateChange = (date, value) => {
    console.log(value);
    SetStartDate(value[0]);
    SetEndDate(value[1]);
  };
  const disabledDate=(current)=>{
    return current && current>moment().endOf('day');
  }

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">DIP Summary</h3>

            <div className="header-actions">
              <NavLink to={`/dipsetting/printsummary/?StartDate=${StartDate}&EndDate=${EndDate}&ProductID=${ProductID}`}
              target="_blank">
                <Button type="dashed" shape="round" size="large">
                  <PrinterOutlined />
                  Print Summary
                </Button>
              </NavLink>
            </div>
          </div>
          <div className="card-total-detal">
            <Row gutter={[16,16]} style={{ marginBottom: "16px" }}>
              <Col md={6}>
                <BoxCard
                  loading={loading}
                  title="Total Gain"
                  amount={data.totalGain}
                  prefix="Rs."
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      stroke="#6FB2FF"
                      class="size-6"
                      viewBox="0 0 30 30"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  }
                />
              </Col>
              <Col md={6}>
                <BoxCard
                loading={loading}
                  title="Total Loss"
                  amount={data.totalLoss}
                  prefix="Rs."
                  icon={
                    <svg
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#6FB2FF"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m15 11.25-3-3m0 0-3 3m3-3v7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  }
                />
              </Col>
            </Row>
          </div>
          <div className="filters-wrap">
            <Form>
              <Form.Item>
                <RangePicker
                  onChange={handleDateChange}
                  disabledDate={disabledDate}
                />
              </Form.Item>
              <Form.Item>
                <Select
                  showSearch
                  style={{
                    width: "200px",
                  }}
                  placeholder="All Product"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value) => setProductID(value)}
                  options={ProductType}
                />
              </Form.Item>
            </Form>
          </div>

          <Table
            size="small"
            scroll={{
              x: "100%",
            }}
            bordered
            loading={loading}
            columns={columns}
            dataSource={listofMachine}
            // onChange={handleTableChange}
            pagination={false}
            // footer={getFooterContent}
          />
        </div>
      </div>
    </>
  );
}

export default Summary;
