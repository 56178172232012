import React, { useState, useEffect } from "react";
import config from "../../config";
import axios from "axios";
import { Button, Table, message, Form, Input, DatePicker, Select, Pagination, Tooltip } from "antd";
import { PlusOutlined, DeleteOutlined, IdcardOutlined, ToolOutlined, EditOutlined } from "@ant-design/icons";
import { Link, NavLink, useParams } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";

const PumpSubscriptions = () => {
  const [loading, setLoading] = useState(false);
  const UserID = localStorage.getItem("AdminID");
  const AccessKey = localStorage.getItem("AdminAccessKey");
  const MemberType = localStorage.getItem("MemberType");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [Package, setPackage] = useState("");
  const [PumpCode, setPumpCode] = useState("");
  const [Email, setEmail] = useState("");
  const [Mobile, setMobile] = useState("");
  const [NozzleSale, setNozzleSale] = useState("");
  const [listOfLedger, setListOdLedger] = useState([]);
  const [ListOfPackages, setListOfPackages] = useState([]);

  // pagination
  const [PageNo, setPageNo] = useState(1);
  const [PageSize, setPageSize] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);



  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      startDate: startDate,
      endDate: endDate,
      PageNo: PageNo,
      PageSize: PageSize,
      Package: Package,
      PumpCode: PumpCode,

    };

    var api_config = {
      method: "post",
      url: config.base_url + "AdminUser/GetAllPurchases",
      header: {
        "Content-Type": "application/josn",
      },
      data: data,
    };


    axios(api_config)
      .then(function (response) {
        console.log(response.data)
        if (response.data.status_code == 1) {
          setListOdLedger(response.data.listofRecords);
          setTotalRecords(response.data.totalRecords);

          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        message.error("Network Error");
        console.log(error);
      });
  }, [startDate, endDate, Package, PumpCode, Email, Mobile, PageSize, PageNo]);

  useEffect(() => {

    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    }
    var api_config = {
      method: "post",
      url: config.base_url + "AdminUser/GetPackagesList",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {

        if (response.data.status_code == 1) {

          setListOfPackages(response.data.ListofRecords.map((item) => ({
            value: item.ID,
            label: item.Name,
          })));

          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        message.error("Network Error");
        console.log(error);
      });

  }, [])

  const columns = [
    // {
    //   title: "SR#",
    //   dataIndex: "Sr",
    //   key: "Sr",
    //   render: (_, record, index) => index + 1 + PageSize * (PageNo - 1),
    // },
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
    },
    {
      title: "Pump Code",
      dataIndex: "PumpCode",
      key: "PumpCode",
    },
    {
      title: "Start Date",
      dataIndex: "StartDate",
      key: "startdate",
      render: (_, record) =>
        dayjs(record.StartDate).format(config.date_format),
    },
    {
      title: "End Date",
      dataIndex: "EndDate",
      key: "enddate",
      render: (_, record) =>
        dayjs(record.EndDate).format(config.date_format),
    },

    {
      title: "Package",
      dataIndex: "PackageName",
      key: "PackageName",
    },


   
    {
      title: "Payment Mode",
      dataIndex: "PaymentMode",
      key: "PaymentMode",
    },
    {
      title: "Payment Received By",
      dataIndex: "PaymentReceivedBy",
      key: "PaymentReceivedBy",
    },
    {
      title: "Price",
      dataIndex: "PackagePrice",
      key: "PackagePrice",
    },
    {
      title: "Discount %",
      dataIndex: "DiscountPercentage",
      key: "DiscountPercentage",
    },
    {
      title: "Discount Value",
      dataIndex: "DiscountValue",
      key: "DiscountValue",
    },
    {
      title: "Net Receiveable",
      dataIndex: "NetReceiveable",
      key: "NetReceiveable",
    },

    
    {
      title: "Grace Period",
      dataIndex: "GracePeriod",
      key: "GracePeriod",
    },
    {
      title: "Action",
      key: "Action",
      fixed: 'right',
      width: 100,
      render: (_, record) => {
        return (
          <div style={{ textAlign: 'center', display: "flex", gap: 10 }}>
            <Tooltip title="Suspend">
              <DeleteOutlined />
            </Tooltip>
            <Tooltip title="View Detils">
              <NavLink to={`/controlbase/petrolpump/detail/${record.PumpID}`}>
                <IdcardOutlined />
              </NavLink>
            </Tooltip>
            <Tooltip title="Update Subscription">
              <NavLink to={`/controlbase/petrolpump/edit-subscription/${record.ID}`}>
                <EditOutlined />
              </NavLink>
            </Tooltip>
          </div>
        );
      }
    },
  ];

  const { RangePicker } = DatePicker;
  const handleDateChange = (date, value) => {

    setStartDate(value[0]);
    setEndDate(value[1]);
  };
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  const handleSearchByCode = (e) => {
    setPumpCode(e.target.value);
  };

  const handleSearchByEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSearchByMobile = (e) => {
    setMobile(e.target.value);
  };

  const onPageChange = (page, pageSize) => {
    setPageNo(page);
  };

  const onPageSizeChange = (current, size) => {
    setPageNo(1);
    setPageSize(size);
  };




  return (
    <>
      <div className="right-side-contents">
        <div className="page-content control-base-wrap">
          <div className="page-header">
            <h3 className="page-title">Subscriptions</h3>
          </div>
          <div className="filters-wrap">
            <Form>
              <Form.Item>
                <RangePicker
                  allowClear
                  onChange={handleDateChange}
                  disabledDate={disabledDate}
                />
              </Form.Item>
              <Form.Item>
                <Select
                  allowClear
                  showSearch
                  style={{
                    width: "200px",
                  }}
                  placeholder="Packge"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value) => setPackage(value)}
                  options={ListOfPackages}
                />
              </Form.Item>
              <Form.Item>
                <Input
                  allowClear
                  showSearch
                  placeholder="Pump Code"
                  value={PumpCode}
                  onChange={handleSearchByCode}
                />
              </Form.Item>
              <Form.Item>
                <Input
                  allowClear
                  showSearch
                  placeholder="Search By Email"
                  value={Email}
                  onChange={handleSearchByEmail}
                />
              </Form.Item>
              <Form.Item>
                <Input
                  allowClear
                  showSearch
                  placeholder="Mobile No"
                  value={Mobile}
                  onChange={handleSearchByMobile}
                />
              </Form.Item>
            </Form>
          </div>
          <Table
            size="small"
            scroll={{
              x: 'max-content',
            }}
            loading={loading}
            dataSource={listOfLedger}
            columns={columns}
            pagination={false}
            bordered
          />
          <div className="text-end mt-5">
            <Pagination
              current={PageNo}
              pageSize={PageSize}
              size="small"
              total={totalRecords}
              onChange={onPageChange}
              onShowSizeChange={onPageSizeChange} // Handle page size change
              showSizeChanger
              showQuickJumper
              showTotal={(total) => `Total ${total} items`}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PumpSubscriptions;
