import React, { useEffect, useState } from "react";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { Spin } from "antd";
import axios from "axios";
import config from "../../config";
import PrintHeaderAccount from "../../Pdf/PrintHeaderAccount";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

function PrintAccountList() {
  const params = useParams();

  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [listOfReading, setListOfReading] = useState([]);
  const [Printdata, setPrintdata] = useState([]);
  const [saleDetail, setSaleDetail] = useState([]);

  useEffect(() => {
    setLoading(true);

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: params.id,
    };
    // console.log(" data.UserID:", data);

    var api_config = {
      method: "post",
      url: config.base_url + "Sale/GetSaleByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        // console.log("get sale by id", response.data);
        if (response.data.status_code == 1) {
          setPrintdata(response.data);
          setListOfReading(response.data.listofReadings);
          setSaleDetail(response.data.listofSale);
          setLoading(false);
        } else {
          // Handle error
        }
        // setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const styles = StyleSheet.create({
    page: {
      //flexDirection: 'row',
      backgroundColor: "#FFF",
    },
    section: {
      margin: 10,
      padding: 10,
      flexDirection: "column",
      marginBottom: 20,
      //flexGrow: 1
    },
    header: {
      margin: 10,
      padding: 10,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottomWidth: 0.5,
    },
    title: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 10,
    },
    table: {
      display: "flex",
      width: "100%",
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
      marginBottom: 10,
    },
    tableHead: {
      flexDirection: "row",
      backgroundColor: "rgba(0,0,0,0.05)",
    },
    th: {
      padding: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
    },
    thValue: {
      fontSize: "9pt",
      fontWeight: "bolder",
    },

    row: {
      flexDirection: "row",
    },
    td: {
      padding: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
    },
    tdValue: {
      fontSize: "8pt",
    },
    topData: {
      margin: "0px 10px",
      padding: 10,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    thWidth: {
      width: "100%",
      fontSize: "8pt",
      fontWeight: "bold",
    },
  });

  // const generateData = () => {
  //     if (listofVouchers.length > 0) {
  //         return (
  //             listofVouchers.map((item, index) => {
  //                 return (
  //                     <View key={index} style={styles.row}>

  //                         <View style={styles.td}><Text style={styles.tdValue}>{item.DebitAccountID}</Text></View>
  //                         <View style={styles.td}><Text style={styles.tdValue}>{item.Amount}</Text></View>
  //                         <View style={styles.td}><Text style={styles.tdValue}>{item.Email_Address}</Text></View>
  //                         <View style={styles.td}><Text style={styles.tdValue}>{item.Mobile_No}</Text></View>
  //                         <View style={styles.td}><Text style={styles.tdValue}>{item.Account_Type_ID}</Text></View>
  //                         <View style={styles.td}><Text style={styles.tdValue}>{item.Balance}</Text></View>

  //                     </View>
  //                 )
  //             })
  //         )
  //     }

  // };

  const readingData = () => {
    if (listOfReading != null && listOfReading.length > 0) {
      return listOfReading.map((item, index) => {
        return (
          <View key={index} style={styles.row}>
            <View style={[styles.td, { width: 50 }]}>
              <Text style={styles.tdValue}>{index + 1}</Text>
            </View>

            <View style={[styles.td, { width: 50 }]}>
              <Text style={styles.tdValue}>{item.Pump_No}</Text>
            </View>
            <View style={[styles.td, { width: 100 }]}>
              <Text style={styles.tdValue}>{item.ProductName}</Text>
            </View>
            <View style={[styles.td, { width: 90 }]}>
              <Text style={styles.tdValue}>{item.Last_Reading}</Text>
            </View>
            <View style={[styles.td, { width: 90 }]}>
              <Text style={styles.tdValue}>{item.Current_Reading}</Text>
            </View>
            <View style={[styles.td, { width: 90 }]}>
              <Text style={styles.tdValue}>{item.Returned}</Text>
            </View>
            <View style={[styles.td, { width: 90 }]}>
              <Text style={styles.tdValue}>
                {item.Current_Reading - item.Last_Reading - item.Returned
                  ? item.Current_Reading - item.Last_Reading - item.Returned
                  : 0}
              </Text>
            </View>
          </View>
        );
      });
    }
  };

  const salesDetails = () => {
    console.log("sale", saleDetail);
    if (saleDetail != null && saleDetail.length > 0) {
      return saleDetail.map((item, index) => {
        return (
          <View key={index} style={styles.row}>
            <View style={[styles.td, { width: 50 }]}>
              <Text style={styles.tdValue}>{index + 1}</Text>
            </View>

            <View style={[styles.td, { width: 100 }]}>
              <Text style={styles.tdValue}>{item.Product_BE?.Name}</Text>
            </View>
            <View style={[styles.td, { width: 80 }]}>
              <Text style={styles.tdValue}>{item.Account_BE?.Name}</Text>
            </View>
            <View style={[styles.td, { width: 50 }]}>
              <Text style={styles.tdValue}>{item.Vehicle_No}</Text>
            </View>
            <View style={[styles.td, { width: 50 }]}>
              <Text style={styles.tdValue}>{item.Receipt_No}</Text>
            </View>
            <View style={[styles.td, { width: 50 }]}>
              <Text style={styles.tdValue}>{item.Quantity}</Text>
            </View>
            <View style={[styles.td, { width: 50, textAlign: "right" }]}>
              <Text style={styles.tdValue}>{item.Price}</Text>
            </View>
            <View style={[styles.td, { width: 50, textAlign: "right" }]}>
              <Text style={styles.tdValue}>
                {item.Price == true ? "YES" : "NO"}
              </Text>
            </View>
            <View style={[styles.td, { width: 80, textAlign: "right" }]}>
              <Text style={styles.tdValue}>{item.Amount}</Text>
            </View>
          </View>
        );
      });
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <PrintHeaderAccount />
        <View style={styles.topData}>
          <View>
            <Text style={{ fontSize: "12pt" }}>
              Invoice No:{Printdata.InvoiceNo}
            </Text>
          </View>
          <View>
            <Text style={{ fontSize: "12pt" }}>
              Date: {dayjs(Printdata.Dated).format("DD-MMM-YYYY").toLowerCase()}
            </Text>
          </View>
        </View>
        <View style={styles.topData}>
          <View>
            <Text style={{ fontSize: "12pt" }}>
              Description:{Printdata.Description}
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.title}>
            <Text style={{ fontSize: "12pt" }}>Sales Report</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHead}>
              <View style={[styles.th, { width: 50 }]}>
                <Text style={styles.thWidth}>Sr No</Text>
              </View>
              <View style={[styles.th, { width: 50 }]}>
                <Text style={styles.thValue}>Pump No</Text>
              </View>
              <View style={[styles.th, { width: 100 }]}>
                <Text style={styles.thValue}>Product</Text>
              </View>
              <View style={[styles.th, { width: 90 }]}>
                <Text style={styles.thValue}>Last Reading</Text>
              </View>
              <View style={[styles.th, { width: 90 }]}>
                <Text style={styles.thValue}>Current Reading</Text>
              </View>
              <View style={[styles.th, { width: 90 }]}>
                <Text style={styles.thValue}>Return</Text>
              </View>
              <View style={[styles.th, { width: 90 }]}>
                <Text style={styles.thValue}>Quantity</Text>
              </View>
            </View>

            {readingData()}
          </View>
        </View>

        <View style={styles.section}>
          <View style={styles.table}>
            <View style={styles.tableHead}>
              <View style={[styles.th, { width: 50 }]}>
                <Text style={styles.thWidth}>Sr No</Text>
              </View>
              <View style={[styles.th, { width: 100 }]}>
                <Text style={styles.thValue}>Product</Text>
              </View>
              <View style={[styles.th, { width: 80 }]}>
                <Text style={styles.thValue}>Account</Text>
              </View>
              <View style={[styles.th, { width: 50 }]}>
                <Text style={styles.thValue}>Vehicle No</Text>
              </View>
              <View style={[styles.th, { width: 50 }]}>
                <Text style={styles.thValue}>Receipt no</Text>
              </View>
              <View style={[styles.th, { width: 50 }]}>
                <Text style={styles.thValue}>Quantity</Text>
              </View>
              <View style={[styles.th, { width: 50, textAlign: "right" }]}>
                <Text style={styles.thValue}>Price</Text>
              </View>
              <View style={[styles.th, { width: 50, textAlign: "right" }]}>
                <Text style={styles.thValue}>Cash</Text>
              </View>
              <View style={[styles.th, { width: 80, textAlign: "right" }]}>
                <Text style={styles.thValue}>Total</Text>
              </View>
            </View>

            {salesDetails()}
            <View style={styles.tableHead}>
              <View style={[styles.th, { width: 480, textAlign: "right" }]}>
                <Text style={styles.thWidth}>Total</Text>
              </View>
              <View style={[styles.th, { width: 80, textAlign: "right" }]}>
                <Text style={styles.thValue}>
                  {Printdata.Cash_Total + Printdata.Credit_Total}
                </Text>
              </View>
            </View>
            <View style={styles.tableHead}>
              <View style={[styles.th, { width: 480, textAlign: "right" }]}>
                <Text style={styles.thWidth}>Cash Total</Text>
              </View>
              <View style={[styles.th, { width: 80, textAlign: "right" }]}>
                <Text style={styles.thValue}>{Printdata.Cash_Total}</Text>
              </View>
            </View>
            <View style={styles.tableHead}>
              <View style={[styles.th, { width: 480, textAlign: "right" }]}>
                <Text style={styles.thWidth}> Credit Total</Text>
              </View>
              <View style={[styles.th, { width: 80, textAlign: "right" }]}>
                <Text style={styles.thValue}> {Printdata.Credit_Total}</Text>
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            marginBottom: 20,
            marginLeft: 10,
            marginTop: 20,
            top: 10,
            bottom: 10,
          }}
          fixed
        >
          <Text
            style={{ fontSize: "8pt" }}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
          />
        </View>
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="accounts.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
}

export default PrintAccountList;
