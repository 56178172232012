import React, { useEffect, useState } from "react";

import axios from "axios";
import config from "../../config";
import logo from "../../assets/images/Logo/logo-white.svg"
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Button, message, Form, Input } from "antd";
import { MailOutlined } from "@ant-design/icons";

function Forget() {
  const [loading, setLoading] = useState(false);

  const [step, setStep] = useState(1);
  let navigate = useNavigate();

  useEffect(() => {
    document.title = "Forgot Password?";
  }, []);

  const handleSubmit = (formData) => {
    debugger;
    //console.log(formData);
    setLoading(true);

    var api_config = {
      method: "post",
      url: config.base_url + "Members/ForgotPassword",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);

        if (response.data.status_code == 1) {
          // Handle success
          message.success(response.data.status_message);
          setStep(2);

          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
          message.error(response.data.status_message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <>

      <div className="auth-wrap">
        <div className="auth-head">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <p>Welcome to</p>
          <h1>Fuelbook Manager</h1>
        </div>
        <div className="auth-card">
          {
            step == 1 ?
              <>
                <h4>Forgot Password?</h4>
                <Form
                  name="normal_login"
                  className="login-form"
                  size="large"
                  layout="vertical"
                  onFinish={handleSubmit}
                >
                  <Form.Item

                    name="User_Name"
                    label="Email Address"
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: "Please enter email address",

                      },
                    ]}


                  >
                    <Input
                      prefix={
                        <MailOutlined />
                      }
                      placeholder="Enter email address"
                      type="email"
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      style={{ width: "100%" }}
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      loading={loading}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
                <div className="text-center" style={{ marginTop: "20px" }}>
                  Back to <br />
                  <Link to="/login">Login!</Link>
                </div>
              </>
              :
              <>
                <h4>Password Reset Requested</h4>
                <p style={{color: "rgba(0,0,0,0.6)"}}>
                We have sent an email with your new password. Please check your inbox and use the new password to log in to your account. If you do not see the email, please check your spam or junk folder. <br /> <br />For security reasons, we recommend that you change this password after logging in.
                </p>
                <div style={{ marginTop: "20px" }}>
                 
                  <Link to="/login">
                      <Button size="large" shape="round" type="primary">Login Now</Button>
                  </Link>
                </div>
              </>
          }

        </div>

      </div>
    </>
  );
}

export default Forget;
