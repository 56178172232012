import React, { useEffect, useState } from "react";
import config from "../../config";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import LoginChecked from "../Shared/LoginChecked";
import { Table, DatePicker, Form, Button} from "antd";
import { NavLink, useParams } from "react-router-dom";
import moment from "moment";
import {PrinterOutlined} from "@ant-design/icons"
import dayjs from "dayjs";

const MachineReading = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const { RangePicker } = DatePicker;
  let params = useParams();
  const machine_id = urlParams.get("machine_id");
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [StartDate, SetStartDate] = useState(dayjs().subtract(30, 'day').format('YYYY-MM-DD'));
  const [EndDate, SetEndDate] = useState(dayjs());
  const [currentPage, setCurrentPage] = useState(1);
  const [listofMachine, SetlistofMachine] = useState([]);
  const[PumpMachineNo,setPumpMachineNo]=useState("");


  useEffect(() => {
    document.title = "Pump Reading";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      PageNo: currentPage,
      ID: machine_id,
    };
    console.log(data)

    var api_config = {
      method: "post",
      url: config.base_url + "/Product/GetReadings",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
      console.log(response.data)
        if (response.data.status_code == 1) {
          setPumpMachineNo(response.data.listofLedger?.[0]?.Pump_No)
          SetlistofMachine(response.data.listofLedger);
          setLoading(false);
        } else {
          
        }
       
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [StartDate, EndDate, currentPage]);
  console.log(PumpMachineNo);

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "Sr",
      key: "Sr",
      render: (_, record, index) => index + 1,
    },
    // {
    //   title: "ID",
    //   dataIndex: "ID",
    //   key: "ID",
    // },
    {
      title: "Dated",
      dataIndex: "Dated",
      key: "Dated",
      render: (date) => moment(date).format(config.date_format),
    },
    {
      title: "Pump Number	",
      dataIndex: "Pump_No",
      key: "Pump_No",
    },
    {
      title: "Product",
      dataIndex: "ProductCode",
      key: "ProductCode",
    },

    {
      title: "Last Reading",
      dataIndex: "Last_Reading",
      key: "Last_Reading",
    },
    {
      title: "Current Reading",
      dataIndex: "Current_Reading",
      key: "Current_Reading",
    },
    {
      title: "Return",
      dataIndex: "Returned",
      key: "Returned",
    },
    {
      title: "Used QTY",
      dataIndex: "UsedQuantity",
      key: "UsedQuantity",
    },
    {
      title: "Invoice Ref",
      dataIndex: "Invoice_ID",
      key: "Invoice_ID",
    },
    {
      title: "Created Date",
      dataIndex: "Created_Date",
      key: "Created_Date",
      render: (date) => moment(date).format(config.date_format),
    },
  ];

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h6 className="page-title">
              <NavLink to="/products/manage-machines">
                <ChevronLeftIcon />
              </NavLink>
              Nozzle Reading ({PumpMachineNo})
            </h6>
            <div className="header-actions">
              <NavLink
                to={`/product/print-machine-reading/?StartDate=${StartDate}&EndDate=${EndDate}&machine_id=${machine_id}`}
                target="_blank"
              >
                <Button
                  type="dashed"
                  size="large"
                  shape="round"
                  // style={{ marginBottom: 10 }}
                >
                  <PrinterOutlined />
                  Print Report
                </Button>
              </NavLink>
            </div>
          </div>

          <div className="filter-wrap">
            <Form>
              <Form.Item className="" name="Created_Date">
                <RangePicker
                  onChange={(dates) => {
                    if (dates) {
                      SetStartDate(dates[0]);
                      SetEndDate(dates[1]);
                    }
                  }}
                />
              </Form.Item>
            </Form>
          </div>

          <Table
            size="small"
            scroll={{
              x: "100%",
            }}
            bordered
            loading={loading}
            columns={columns}
            dataSource={listofMachine}
            pagination={false}
          />
        </div>
      </div>
    </>
  );
};

export default MachineReading;
