import React, { useState } from "react";
import {
  Row,
  Col,
  Breadcrumb,
  Button,
  Form,
  Input,
  Select,
  Space,
  Card,
  message,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";

import LoginChecked from "../Shared/LoginChecked";
import config from "../../config";
import axios from "axios";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
const { Option } = Select;

function AddUser() {
  const [messageApi, contextHolder] = message.useMessage();
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [form] = Form.useForm();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values) => {
    console.log("🚀 ~ handleSubmit ooooooooooooo ~ values:", values);

    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ...values,
    };
    console.log(data);
    const api_config = {
      method: "post",
      url: config.base_url + "Users/AddNewUser",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          setLoading(false);

          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });

          // Handle success
        } else {
          // Handle error
          setLoading(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {
        // setValidated(true);

        console.log(error);
      });
  };

  // const handleSubmit = async (values) => {
  //   try {
  //     setLoading(true);
  //     const data = {
  //       UserID: UserID,
  //       AccessKey: AccessKey,
  //       ...values,
  //     };
  //     console.log(data);

  //     const api_config = {
  //       method: 'post',
  //       url: config.base_url + 'account/AddNewAccount',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       data: data,
  //     };

  //     const response = await axios(api_config);

  //     console.log(response.data);
  //     if (response.data.status_code === 1) {
  //       message.success('User added successfully!');
  //     } else {
  //       // Handle error
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLoading(false);
  //   }

  //   setValidated(true);
  // };

  return (
    <>
      <LoginChecked />
      {contextHolder}

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">
              <NavLink to={`/security/manage-users`}>
                <ChevronLeftIcon />
              </NavLink>
              Add User
            </h3>
            <div className="page-actions"></div>
          </div>

          <Row gutter={[24, 24]}>
            <Col sm={24} md={12} lg={{ span: 12, offset: 6 }}>
            
                <Form
                  form={form}
                  noValidate
                  validated={validated}
                  onFinish={handleSubmit}
                  name="basic"
                  layout="horizontal"
                  size="large"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  autoComplete="off"
                  loading={loading}
                >
                  <Form.Item
                    name="FullName"
                    label="Full Name:"
                    rules={[
                      { required: true, message: "Please enter Full Name" },
                    ]}
                  >
                    <Input placeholder="Enter Full Name" />
                  </Form.Item>

                  <Form.Item
                    name="User_Mobile"
                    label="Mobile:"
                    rules={[{ required: true, message: "Please enter Mobile" }]}
                  >
                    <Input placeholder="Enter Mobile" />
                  </Form.Item>

                  <Form.Item
                    name="User_Name"
                    label="Email:"
                    rules={[{ required: true, message: "Please enter E-Mail" }]}
                  >
                    <Input placeholder="Enter Email" />
                  </Form.Item>

                  <Form.Item
                    name="Password"
                    label="Password:"
                    rules={[
                      { required: true, message: "Please enter Password" },
                    ]}
                  >
                    <Input.Password placeholder="Enter Password" />
                  </Form.Item>

                  <Form.Item
                    name="User_Type"
                    label="User Type:"
                    rules={[
                      { required: true, message: "Please select User Type" },
                    ]}
                  >
                    <Select placeholder="Select User Type">
                      <Option value="Admin">Admin</Option>
                      <Option value="Operator">Operator</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Button type="primary" size="large" htmlType="submit" shape="round" loading={loading}>
                      Add User
                    </Button>
                  </Form.Item>
                </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default AddUser;
