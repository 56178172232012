import React, { useEffect, useState } from "react";
import config from "../../config";
import axios from "axios";
import LoginChecked from "../Shared/LoginChecked";
import BoxCard from "../Shared/BoxCard";
import {
  Row,
  Col,
  Modal,
  Table,
  Form,
  Popconfirm,
  Pagination,
  Input,
  Select,
  DatePicker,
  Dropdown,
  Space,
  Button,
  message,
  Tooltip,
} from "antd";
import moment from "moment";
import {
  PrinterOutlined,
  EditOutlined,
  DownOutlined,
  IdcardOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import AccountDropDown from "../Shared/AccountDropDown";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";
import { ArrowDown, ArrowUp, Money, Users } from "../UserControls/Icons";
import { NumberFormat } from "../Shared/Utility";

function ManageVouchers() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [Data, setData] = useState("");
  const [StartDate, SetStartDate] = useState("");
  const [EndDate, SetEndDate] = useState("");
  const [OrderBy, SetOrderBy] = useState("1");
  const [AccountID, SetAccountID] = useState(0);

  const { RangePicker } = DatePicker;
  const [ModalType, setModalType] = useState("");
  const VoucherMode = 'General';

  const [voucherNo, setVoucherNo] = useState(0);
  const [VoucherId, setVoucherId] = useState('');
  const [debitAccountID, setdebitAccountID] = useState(0);
  const [form] = Form.useForm();

  const [total, setTotal] = useState(0);
  const [VoucherType, SetVoucherType] = useState("");
  const [DebitAccountID, SetDebitAccountID] = useState(0);

  const [listofVouchers, SetlistofVouchers] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [IsVoucherUpdated, setIsVoucherUpdated] = useState(false);
  const [Button_Lodding, setButton_Lodding] = useState(false);
  const accounts = AccountDropDown();

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [selectedVoucher, setSelectedVoucher] = useState(null);

  const [creditAccountID, setcreditAccountID] = useState(0);

  const [loadingAccounts, setLoadingAccounts] = useState(false);

  const [isDeleted, setIsDeleted] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      VoucherMode: VoucherMode,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "/Voucher/GetNextVoucherNo",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        // console.log(response.data,'---------------')
        if (response.data.status_code == 1) {
          setVoucherNo(response.data.NextVoucherNo);
        }
      })
      .catch(function (error) {
      });
  }, []);

  useEffect(() => {
    document.title = "Manage Vouchers";
    setIsDeleted(false);
    setIsVoucherUpdated(false);
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      OrderBy: OrderBy,
      PageNo: pageNo,
      PageSize: pageSize,
      AccountID: AccountID,
      VoucherType: VoucherType,
      VoucherMode: VoucherMode,
      Debit_Account_ID: DebitAccountID,
      Credit_Account_ID: creditAccountID,
    };

    // console.log("data----", data);

    var api_config = {
      method: "post",
      url: config.base_url + "Voucher/GetVouchersWithType",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        // console.log("vouchers list", response.data);
        if (response.data.status_code == 1) {
          // Handle success
          SetlistofVouchers(response.data.listofVouchers);
          setTotal(response.data);
          setTotalRecords(response.data.totalRecords);
          setData(response.data);

          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
      });
  }, [
    DebitAccountID,
    creditAccountID,
    AccountID,
    OrderBy,
    VoucherType,
    IsVoucherUpdated,
    StartDate,
    EndDate,
    pageSize,
    pageNo,
    isDeleted,
  ]);


  const handleTableChange = async (pagination, filters, sorter) => {
    // Make API request with sorting parameters
    const sortField = sorter.field;
    const sortOrder = sorter.order === "descend" ? "desc" : "asc";

    if (sortField == "Dated") {
      if (sortOrder == "asc") {
        SetOrderBy(6);
      } else {
        SetOrderBy(5);
      }
    } else if (sortField == "VoucherNo") {
      if (sortOrder == "asc") {
        SetOrderBy(2);
      } else {
        SetOrderBy(1);
      }
    }

  };

  const handelDelete = (ID) => {
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: ID,
    };
    const api_config = {
      method: "post",
      url: config.base_url + "Voucher/DeleteVoucher",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {
          setIsDeleted(true);
          setLoading(false);
          message.success(response.data.status_message);
        } else {
          setLoading(false);
          message.error(response.data.status_message);
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "SR",
      render: (_, record, index) => index + 1 + pageSize * (pageNo - 1),
      key: "Sr",
    },
    {
      title: "Dated",
      dataIndex: "Dated",
      key: "dated",
      render: (date) => moment(date).format(config.date_format),
      sorter: true,
    },
    {
      title: "No",
      dataIndex: "VoucherNo",
      key: "VoucherNo",
      sorter: true,
    },
    {
      title: "Debit Account",
      dataIndex: "Debit_Account",
      key: "Debit_Account",
      render: (_, record) => {
        return record.Debit_Account ? (
          <a
            target="_blank"
            href={`/accounts/account-ledger/?account_id=${record.Debit_Account_ID}`}
          >
            {record.Debit_Account.Name}
          </a>
        ) : (
          ""
        );
      },
    },

    {
      title: "Credit Account",
      dataIndex: "Credit_Account",
      key: "Credit_Account",
      render: (_, record) => {
        return record.Credit_Account ? (
          <a
            target="_blank"
            href={`/accounts/account-ledger/?account_id=${record.Credit_Account_ID}`}
          >
            {record.Credit_Account.Name}
          </a>
        ) : (
          ""
        );
      },
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "description",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      align: "right",
      render:(_,record)=>NumberFormat(record.Amount,0)
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a type="button" onClick={() => handelShowModal('Edit', record)}>
            <Tooltip title="Edit Voucher">
              <EditOutlined />
            </Tooltip>
          </a>
          <Popconfirm
            title="Sure to delete?"
            discription="Are You sure to delete this data"
            onConfirm={(e) => handelDelete(record.ID)}
          >
            <NavLink to={`#`}>
              <Tooltip title="Delete Voucher">
                <DeleteOutlined />
              </Tooltip>
            </NavLink>
          </Popconfirm>
          <NavLink
            to={`/accounts/print-voucher-list/?voucher_id=${record.ID}`}
            target="_blank"
          >
            <Tooltip title="Print Voucher">
              <PrinterOutlined />
            </Tooltip>
          </NavLink>
        </Space>
      ),
    },
  ];

  const onPageChange = (page) => {
    setPageNo(page);
  };

  const onPageSizeChange = (current, size) => {
    setPageNo(1); // Reset to the first page when changing page size
    setPageSize(size);
  };



  const handleAccountChange = (value) => {
    SetAccountID(value);
  };



  const handleDateChange = (date, value) => {
    SetStartDate(value[0]);
    SetEndDate(value[1]);
  };
  const disableDate = (current) => {
    return current && current > moment().endOf("day");
  };


  const handelShowModal = (type, record) => {

    // console.log(record);
    setModalType(type);
    const Voucher = record;


    Voucher.Dated = Voucher.Dated ? dayjs(Voucher.Dated) : null;
    const VoucherNo = Voucher.VoucherNo ? Voucher.VoucherNo : voucherNo;

    // console.log(VoucherNo)

    form.setFieldsValue({ voucherNo: VoucherNo });


    form.setFieldsValue(Voucher);
    setVoucherId(Voucher.ID)

    setOpen(true);
  }

  const handleCancel = () => {
    setSelectedVoucher(null);
    setOpen(false);
    setButton_Lodding(false);
    form.resetFields();
    setVoucherId('');

  };
  const handelVoucher = (formData) => {
    setIsVoucherUpdated(true); // Corrected line
    setButton_Lodding(true);
    setLoading(true);

    formData["Dated"] = dayjs(formData["Dated"]).format("YYYY-MM-DD");

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      VoucherMode: VoucherMode,

      Credit_Account_ID: creditAccountID,
      Debit_Account_ID: debitAccountID,


      ...formData,
    };

    if (ModalType === 'Edit') {
      data.ID = VoucherId;
    }

    const api_config = {
      method: "post",
      url: config.base_url + (ModalType === 'Edit' ? "Voucher/UpdateVoucher" : 'Voucher/AddNewVoucher'),
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {
          setIsVoucherUpdated(false);
          setButton_Lodding(false);
          setLoading(false);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });

          setConfirmLoading(true);
          setTimeout(() => {
            setOpen(false);
            form.resetFields();
            setConfirmLoading(false);
          }, 2000);
        } else {
          setLoading(false);
          setButton_Lodding(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {
        setLoading(false);
        setButton_Lodding(false);
        messageApi.open({
          type: "error",
          content: "Network Error",
        });
      });
  };

  return (
    <>
      <LoginChecked />

      <Modal
        title={ModalType == 'Edit' ? 'Update General Voucher' : 'Add General Voucher'}
        open={open}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="basic"
          layout="vertical"
          onFinish={handelVoucher}
          autoComplete="off"
          form={form}
        >
          <Row gutter={[16, 3]}>
            <Col span={8}>
              <Form.Item name="voucherNo" label="Voucher No"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="Amount" label="Amount"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="Dated" label="Date"
                rules={[{ required: true }]}
              >
                <DatePicker allowClear onChange={handleDateChange} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Debit Account"
                name="Debit_Account_ID"
                className="input-vertical"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Select Account"
                  optionFilterProp="children"
                  loading={loadingAccounts}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={accounts}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Credit Account"
                name="Credit_Account_ID"
                className="input-vertical"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Select Account"
                  optionFilterProp="children"
                  loading={loadingAccounts}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={accounts}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="Description" label="Description"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <div className="text-right">
                <Space wrap>
                  <Button
                    type="primary"
                    size="large"
                    shape="round"
                    loading={Button_Lodding}
                    htmlType="submit"
                  >
                    {ModalType == 'Edit' ? 'Update General Voucher' : 'Add General Voucher'}
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>

      {contextHolder}

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Vouchers</h3>
            <div className="header-actions">
              <NavLink
                to={`/vouchers/print-voucher-details/?StartDate=${StartDate}&EndDate=${EndDate}&AccountID=${AccountID}&VoucherMode=${VoucherMode}&VoucherType=${VoucherType}&Debit_Account_ID=${DebitAccountID}&Credit_Account_ID=${creditAccountID}`}
                target="blank"
              >
                <Button
                  type="dashed"
                  shape="round"
                  size="large"
                  icon={<PrinterOutlined />}
                >
                  Download / Print
                </Button>
              </NavLink>
              <Button type="primary" size="large" shape="round" icon={<PlusOutlined />} onClick={() => handelShowModal('Add', {})}>
                General Voucher
              </Button>

            </div>
          </div>

          <div className="card-total-detal">
            <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
              <Col md={6}>
                <BoxCard
                  loading={loading}
                  title="Total Vouchers"
                  amount={Data.TotalVouchers}
                  icon={<Users />}
                />
              </Col>
              <Col md={6}>
                <BoxCard
                  loading={loading}
                  title="Debits Count"
                  amount={Data.DebitCount}
                  icon={<ArrowDown />}
                />
              </Col>
              <Col md={6}>
                <BoxCard
                  loading={loading}
                  title="Credits Count"
                  amount={Data.CreditCount}
                  icon={<ArrowUp />}
                />
              </Col>
              <Col md={6}>
                <BoxCard
                  loading={loading}
                  title="Total Amount"
                  prefix="Rs."
                  amount={Data.Total}
                  icon={<Money />}
                />
              </Col>
            </Row>
          </div>
          
          
          <div className="filters-wrap">
            <Form>
              <Form.Item name="">
                <RangePicker
                  style={{
                    width: "100%",
                    marginLeft: 5,
                  }}
                  onChange={handleDateChange}
                  disabledDate={disableDate}
                />
              </Form.Item>

              <Form.Item name="">
                <Select
                  allowClear
                  showSearch
                  style={{
                    width: "160px",
                  }}
                  placeholder="Account"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  loading={accounts ? false : true}
                  options={accounts}
                  onChange={handleAccountChange}
                />
              </Form.Item>

              <Form.Item name="VoucherType">
                <Select
                  allowClear
                  style={{
                    width: "160px",
                  }}
                  placeholder="Voucher Type"
                  onChange={(value) => SetVoucherType(value)}
                  options={[
                    {
                      value: "Received Amount",
                      label: "Received Amount",
                    },
                    {
                      value: "Paid Amount",
                      label: "Paid Amount",
                    },
                    {
                      value: "Expenses",
                      label: "Expenses",
                    },
                  ]}
                />
              </Form.Item>

              <Form.Item name="Debit_Account">
                <Select
                  allowClear
                  showSearch
                  style={
                    {
                      // width: '80%',
                    }
                  }
                  placeholder="Debit Account"
                  onChange={(value) => SetDebitAccountID(value)}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  loading={accounts ? false : true}
                  options={accounts}
                />
              </Form.Item>

              <Form.Item name="Credit_Account">
                <Select
                  showSearch
                  allowClear
                  style={
                    {
                      // width: '80%',
                    }
                  }
                  placeholder="Credit Account"
                  onChange={(value) => setcreditAccountID(value)}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  loading={accounts ? false : true}
                  options={accounts}
                />
              </Form.Item>
            </Form>
           
          </div>

          {/* --------------------------------------------Table---------------------------------------- */}
          <br />

          <Table
            size="small"
            columns={columns}
            dataSource={listofVouchers}
            pagination={false}
            loading={loading}
            onChange={handleTableChange}
            bordered
            scroll={{
              x: "100%",
            }}
            summary={(pageData) => {
              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={6} align="right">
                    <b>Total Amount:</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    {NumberFormat(total.Total,0)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }}
          />
          <div className="text-end mt-5">
            <Pagination
              current={pageNo}
              pageSize={pageSize}
              size="small"
              total={totalRecords}
              onChange={onPageChange}
              onShowSizeChange={onPageSizeChange} // Handle page size change
              showSizeChanger
              showQuickJumper
              showTotal={(total) => `Total ${total} items`}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageVouchers;
