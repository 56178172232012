import React, { useEffect, useState } from "react";
import config from "../../config";
import axios from "axios";
import {
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Popconfirm,
  Space,
  Table,
  message,
  Pagination,
  Button,
  Tooltip,
} from "antd";
import LoginChecked from "../Shared/LoginChecked";
import { NavLink } from "react-router-dom";
import { NozzleOil } from "../UserControls/Icons";

function ManageMachines() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [ListofNozzels, setListofNozzels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Isdeleted, setIsdeleted] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    document.title = "Manage Machines";
    setLoading(true);
    setIsdeleted(false);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Product/GetPumpNozzls",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          // Handle success
          setListofNozzels(response.data.ListofNozzels);
          setLoading(false);
        } else {
          // Handle error
        }
        setLoading(false);
      })
      .catch(function (error) {
      });
  }, [Isdeleted]);

  const confirm = (ID) => {
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: ID,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Product/DeleteNozzle",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          // Handle success
          //console.log(response.data);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });
          setIsdeleted(true);
        } else {
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
      });
  };

  const columns = [

    {
      title: "Nozzle No",
      dataIndex: "Pump_No",
      key: "pump_no",
      render: (_, record) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <NozzleOil />
          <span>{record.Pump_No}</span>
        </div>
      ),
    },
    {
      title: "Product",
      dataIndex: "Selected_Product",
      key: "selected_product",
      render: (product) => {
        return product ? product.Name : "";
      },
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <NavLink to={`/products/machine-reading/?machine_id=${record.ID}`}>
            <Tooltip title="View Reading">
              <EyeOutlined />
            </Tooltip>
          </NavLink>
          <NavLink to={`/products/edit-machine/${record.ID}`}>
            <Tooltip title="Edit">
              <EditOutlined />
            </Tooltip>
          </NavLink>
          <Popconfirm
            title="Sure to delete?"
            description="Are you sure to delete this nozzel?"
            //onConfirm={handleDeleteMachine(record.ID)}
            //onCancel={cancel}
            onConfirm={() => confirm(record.ID)}
            okText="Yes"
            cancelText="No"
          >
            <NavLink>
              <Tooltip title="Delete">
                <DeleteOutlined />
              </Tooltip>
            </NavLink>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const onPageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const onPageSizeChange = (current, size) => {
    setCurrentPage(1); // Reset to the first page when changing page size
    setPageSize(size);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Change this to your desired page size

  // Calculate the start and end index of the data to display
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const ListofNozzel = ListofNozzels.slice(startIndex, endIndex);

  return (
    <>
      <LoginChecked />
      {contextHolder}

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h6 className="page-title">Manage Nozzles</h6>
            <div className="header-actions">
              <NavLink to="/products/add-pump-machine">
                <Button
                  type="primary"
                  size="large"
                  shape="round"
                  icon={<PlusOutlined style={{ color: "#fff" }} />}
                >
                  Add Nozzle
                </Button>
              </NavLink>
            </div>
            
          </div>
          <Table
            size="small"
            scroll={{
              x: "100%",
            }}
            bordered
            loading={loading}
            columns={columns}
            dataSource={ListofNozzel}
            pagination={false} // Remove table pagination
          />
          <div className="text-end mt-5">
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              size="small"
              total={ListofNozzel.length}
              onChange={onPageChange}
              onShowSizeChange={onPageSizeChange} // Handle page size change
              showSizeChanger
              showQuickJumper
              showTotal={(total) => `Total ${total} items`}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageMachines;
