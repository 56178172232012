import React, { useState, useEffect } from "react";
import config from "../../config";
import axios from "axios";
import {
  Button,
  Table,
  message,
  Form,
  Input,
  Space,
  Spin,
  Select,
  Tooltip,
  Row,
  Col,
  DatePicker,
  Skeleton,
  Pagination,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import {
  ChevronLeftIcon,
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  GlobeAltIcon,
  MapPinIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";

const AddSubscriptions = () => {
  const [loading, setLoading] = useState(false);
  const UserID = localStorage.getItem("AdminID");
  const AccessKey = localStorage.getItem("AdminAccessKey");
  const MemberType = localStorage.getItem("MemberType");
  const [PumpDetail, setPumpDetail] = useState({});
  const [LoadingPackages, setLoadingPackages] = useState(false);
  const [ListOfPackages, setListOfPackages] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  
  const [AccountByID, setAccountByID] = useState({});
  const [discountValue, setDiscountValue] = useState(0);
  const [netReceivable, setNetReceivable] = useState(0);


  const [listOfLedger, setListOdLedger] = useState([]);

  // pagination
  const [PageNo, setPageNo] = useState(1);
  const [PageSize, setPageSize] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);


  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      PumpID: params.id,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "AdminUser/GetPackagePurchaseByPumpID",
      header: {
        "Content-Type": "application/josn",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setListOdLedger(response.data.listofRecords);
          setTotalRecords(response.data.totalRecords);
          setLoading(false);
        } else {
          message.error(response.data.status_message);
          setLoading(false);
        }
      })
      .catch(function (error) {
        message.error("Network Error");
      });
  }, []);


  let params = useParams();

  const navigator = useNavigate();

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: params.id,
    }

    var api_config = {
      method: "post",
      url: config.base_url + "AdminUser/GetPackagePurchaseByID",
      header: {
        "Content-Type": "application/josn",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setAccountByID(response.data.PackagePurchased);
          setLoading(false);
        } else {
          message.error(response.data.status_message);
          setLoading(false);
        }
     
      })
      .catch(function (error) {
        message.error("Network Error");
      });
  }, [])

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: params.id,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "AdminUser/GetPetrolPumpInfo",
      header: {
        "Content-Type": "application/josn",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setPumpDetail(response.data);
          setLoading(false);
        } else {
          message.error(response.data.status_message);
          setLoading(false);
        }
       
      })
      .catch(function (error) {
        message.error("Network Error");
      });
  }, []);

  useEffect(() => {
    setLoadingPackages(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "AdminUser/GetPackagesList",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {

        if (response.data.status_code == 1) {
          setListOfPackages(
            response.data.ListofRecords.map((item) => ({
              value: item.ID,
              label: `${item.Name} (Rs.${item.Price})`,
              price: item.Price,
            }))
          );

          setLoadingPackages(false);
        } else {
          message.error(response.data.status_message);
          setLoadingPackages(false);
        }
       
      })
      .catch(function (error) {
        message.error("Network Error");
      });
  }, []);

 

  const handleSubmit = (formData) => {
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      PumpID: params.id,
      StartDate: startDate,
      EndDate: endDate,
      DiscountValue: discountValue,
      NetReceiveable: netReceivable,
      ...formData,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "AdminUser/AddPackagePurchase",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };



    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setLoading(false);
          message.success(response.data.status_message);

          setTimeout(() => {
            navigator('/controlbase/petrolpump')
          }, 3000);

        } else {
          setLoading(false);
          message.error(response.data.status_message);
        }
      })
      .catch(function (error) {
      });
  };

  const handleChangeEndDate = (date, value) => {
    setEndDate(value);
  };

  const handleChangeStartDate = (date, value) => {
    setStartDate(value);
  };

  const columns = [

    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
    },
    {
      title: "Pump Code",
      dataIndex: "PumpCode",
      key: "PumpCode",
    },
    {
      title: "Start Date",
      dataIndex: "StartDate",
      key: "startdate",
      render: (_, record) =>
        dayjs(record.StartDate).format(config.date_format),
    },
    {
      title: "End Date",
      dataIndex: "EndDate",
      key: "enddate",
      render: (_, record) =>
        dayjs(record.StartDate).format(config.date_format),
    },

    {
      title: "Package",
      dataIndex: "PackageName",
      key: "PackageName",
    },


    {
      title: "Price",
      dataIndex: "PackagePrice",
      key: "PackagePrice",
    },
    {
      title: "Payment Mode",
      dataIndex: "PaymentMode",
      key: "PaymentMode",
    },
    {
      title: "Payment Received By",
      dataIndex: "PaymentReceivedBy",
      key: "PaymentReceivedBy",
    },
    {
      title: "Discount %",
      dataIndex: "DiscountPercentage",
      key: "DiscountPercentage",
    },
    {
      title: "Discount Value",
      dataIndex: "DiscountValue",
      key: "DiscountValue",
    },
    {
      title: "Grace Period",
      dataIndex: "GracePeriod",
      key: "GracePeriod",
    },

  ];

  const onPageChange = (page, pageSize) => {
    setPageNo(page);
  };

  const onPageSizeChange = (current, size) => {
    setPageNo(1);
    setPageSize(size);
  };


  return (
    <>
      <div className="right-side-contents">
        <div className="page-content control-base-wrap">
          <div className="page-header">
            <h3 className="page-title">
              <NavLink to="/controlbase/petrolpump/subscriptions/">
                <ChevronLeftIcon />
              </NavLink>
              Add Subscription
            </h3>
          </div>

          <div className="white-box">
            {loading ? (
              <Skeleton />
            ) : (
              <>
                <div className="pd-head">
                  <div className="name">
                    <div className="logo">
                      <img
                        style={{ height: "50px" }}
                        src={config.images + PumpDetail.PumpDetails?.Logo_Reports}
                        alt=""
                      />
                    </div>
                    <div className="info">
                      <h3>{PumpDetail.PumpDetails?.Name}</h3>
                      <p>{PumpDetail.PumpDetails?.PumpCode}</p>
                    </div>
                  </div>
                  {/* <div className="pd-head-right">
                  <ul>
                    <li>
                      <span className="lbl">Registered On:</span>
                      <span className="val label">
                        {dayjs(PumpDetail.CreatedDate).format("DD MMM YYYY")}
                      </span>
                    </li>
                    <li>
                      <span className="lbl">Expiry Date:</span>
                      <span className="val label">
                        {dayjs(PumpDetail.ExpiryDate).format("DD MMM YYYY")}
                      </span>
                    </li>
                    <li>
                      <span className="lbl">Package:</span>
                      <span className="val label trial">
                        {PumpDetail.PackageType}
                      </span>
                    </li>
                  </ul>
                </div> */}
                </div>

                <div className="pd-body">
                  <Row>
                    <Col md={6} xs={24}>
                      <ul className="icon-info-list">
                        <li>
                          <EnvelopeIcon />
                          <span>{PumpDetail.PumpDetails?.Email}</span>
                        </li>
                        <li>
                          <DevicePhoneMobileIcon />
                          <span>{PumpDetail.PumpDetails?.Mobile}</span>
                        </li>
                        <li>
                          <PhoneIcon />
                          <span>{PumpDetail.PumpDetails?.Phone}</span>
                        </li>
                        <li>
                          <GlobeAltIcon />
                          <span>{PumpDetail.PumpDetails?.Website}</span>
                        </li>
                        <li>
                          <MapPinIcon />
                          <span>{PumpDetail.PumpDetails?.Address}</span>
                        </li>
                      </ul>
                    </Col>
                    <Col md={18} xs={24}>
                      <Form
                        name="basic"
                        layout="horizontal"
                        labelCol={{
                          span: 8,
                        }}
                        wrapperCol={{
                          span: 16,
                        }}
                        onFinish={handleSubmit}
                        autoComplete="off"
                        size="large"

                      >
                        <Row>
                          <Col md={12}>
                            <Form.Item
                              label="Package"
                              name="PackageID"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="Select Package"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                loading={LoadingPackages}
                                options={ListOfPackages}
                              />
                            </Form.Item>
                          </Col>
                          <Col md={12}>
                            <Form.Item
                              label="Discount %"
                              name="DiscountPercentage"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </Col>
                          <Col md={12}>
                            <Form.Item label="Discounted Amount" name='DiscountValue'>
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col md={12}>
                            <Form.Item label="Net Receivable" name='NetReceiveable'>
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col md={12}>
                            <Form.Item
                              label="Start Date"
                              name="StartDate"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <DatePicker
                                value={startDate}
                                onChange={handleChangeStartDate}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col md={12}>
                            <Form.Item
                              label="End Date"
                              name="EndDate"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <DatePicker
                                value={endDate}
                                onChange={handleChangeEndDate}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col md={12}>
                            <Form.Item label="Grace Period" name="GracePeriod">
                              <Input placeholder="" />
                            </Form.Item>
                          </Col>
                          <Col md={12}>
                            <Form.Item
                              label="Payment Mode"
                              name="PaymentMode"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Select placeholder="Select payment mode">
                                <Select.Option value="JazzCash">JazzCash</Select.Option>
                                <Select.Option value="Bank">Bank Transfer</Select.Option>
                                <Select.Option value="Cash">Cash</Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col md={12}>
                            <Form.Item label="Payment Details" name="PaymentDetails">
                              <Input placeholder="" />
                            </Form.Item>
                          </Col>
                          <Col md={12}>
                            <Form.Item
                              label="Payment Received By"
                              name="PaymentReceivedBy"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col md={24}>
                            <div className="text-right">
                              <Button
                                type="primary"
                                size="large"
                                shape="round"
                                htmlType="submit"
                                loading={loading}
                              >
                                Add Subscription
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>

                </div>
              </>
            )}
          </div>
          <div className="mt-5">
            <Table
              size="small"
              scroll={{
                x: 'max-content',
              }}
              loading={loading}
              dataSource={listOfLedger}
              columns={columns}
              pagination={false}
              bordered
            />
            <div className="text-end mt-5">
              <Pagination
                current={PageNo}
                pageSize={PageSize}
                size="small"
                total={totalRecords}
                onChange={onPageChange}
                onShowSizeChange={onPageSizeChange}
                showSizeChanger
                showQuickJumper
                showTotal={(total) => `Total ${total} items`}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSubscriptions;
