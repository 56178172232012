import React, { useEffect, useState } from "react";
import axios from "axios";
import PrintFooter from "../../../Pdf/PrintFooter";
import {PrintFormateNumber} from "../../../Pdf/PrintFormateNumber"
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import moment from "moment";
import config from "../../../config";
import PrintHeaderAccount from "../../../Pdf/PrintHeaderAccount";
import { Spin, Space } from "antd";
import pdfStyles from "../../../Pdf/pdfStyles";

function PrintProductLedger() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [ListofProductLedger, SetListofProductLedger] = useState([]);
  const [Product, SetProduct] = useState({});
  const[data,setData]=useState({});
  
  const urlParams = new URLSearchParams(window.location.search);
  const product_id = urlParams.get("product_id");
  const StartDate = urlParams.get("StartDate");
  const EndDate = urlParams.get("EndDate");
  const account_name = urlParams.get("name");

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      PageNo: 0,
      PageSize: 2000,
      ID: product_id,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Product/GetProductLedger",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        
        if (response.data.status_code == 1) {
          // Handle success
          SetListofProductLedger(response.data.listofProductLedger);
          setData(response.data);
          SetProduct(response.data.Product)

          setLoading(false);
        } else {
          setLoading(false);
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  
  

  const generateData = () => {
    if (ListofProductLedger?.length > 0) {
      return ListofProductLedger.map((item, index) => {
        return (
          <View key={index} style={pdfStyles.row}>
            <View style={[pdfStyles.td, {width:40 }]}>
              <Text style={pdfStyles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[pdfStyles.td,{width:70}]}>
              <Text style={pdfStyles.tdValue}>
                {moment(item.Transaction_Date).format(config.date_format)}
              </Text>
            </View>
            <View style={[pdfStyles.td,{width:290}]}>
              <Text style={pdfStyles.tdValue}>{item.Description}</Text>
            </View>
            <View style={[pdfStyles.td,{width:50, textAlign:'right'}]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.Debit,2)}</Text>
            </View>
            <View style={[pdfStyles.td,{width:50, textAlign:'right'}]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.Credit,2)}</Text>
            </View>
            <View style={[pdfStyles.td,{width:80, textAlign:'right'}]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.Balance,2)}</Text>
            </View>
          </View>
        );
      });
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <PrintHeaderAccount />

        <View style={pdfStyles.section}>
          <View style={pdfStyles.title}>
            <Text style={pdfStyles.pagetitle}>
              PRODUCT LEDGER FOR {Product.Name}
            </Text>
            <Text style={{ fontSize: "10pt" }}>
              Start Date : {StartDate} End Date: {EndDate}
            </Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width:40 }]}>
                <Text style={pdfStyles.thValue}>Sr. #</Text>
              </View>
              <View style={[pdfStyles.th, { width:70 }]}>
                <Text style={pdfStyles.thValue}>Dated</Text>
              </View>
              <View style={[pdfStyles.th, { width:290 }]}>
                <Text style={pdfStyles.thValue}>Description</Text>
              </View>
              <View style={[pdfStyles.th, { width:50, textAlign:'right' }]}>
                <Text style={pdfStyles.thValue}>In</Text>
              </View>
              <View style={[pdfStyles.th, { width:50 , textAlign:'right'}]}>
                <Text style={pdfStyles.thValue}>Out</Text>
              </View>
              <View style={[pdfStyles.th, { width:80, textAlign:'right' }]}>
                <Text style={pdfStyles.thValue}>Balance</Text>
              </View>
            </View>
            {generateData()}
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width:400,fontSize:'7pt' , textAlign:'right' }]}>
                <Text style={pdfStyles.thValue}>Total </Text>
              </View>
              <View style={[pdfStyles.th, { width:50, borderRight:'none' , textAlign:'right'}]}>
                <Text style={pdfStyles.thValue}>{PrintFormateNumber(data.totalPurchase,2)}</Text>
              </View>
              
              <View style={[pdfStyles.th, { width:50, textAlign:'right' }]}>
                <Text style={pdfStyles.thValue}>{PrintFormateNumber(data.totalSales,2)}</Text>
              </View>
              
              <View style={[pdfStyles.th, { width:80, textAlign:'right' }]}>
                <Text style={pdfStyles.thValue}>{PrintFormateNumber(Product.Balance,2)} {Product.BalanceType}</Text>
              </View>
              
            </View>
           
          </View>
          <View
            style={{ marginBottom: 60, marginTop: 0, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
        <PrintFooter />
      </Page>
    </Document>
  );

  return (
    <>
      {loading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
          <PDFViewer width="100%" height="100%" fileName="sample.pdf">
            <MyDocument />
          </PDFViewer>
        </div>
      )}
    </>
  );
}

export default PrintProductLedger;
