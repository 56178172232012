import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import config from "../../config";
import moment from "moment";
import dayjs from "dayjs";
import { Table, Pagination, Button, DatePicker, Form, Row, Col, Select,Input } from "antd";
import { NavLink } from "react-router-dom";
import { PrinterOutlined } from "@ant-design/icons";
import LoginChecked from "../Shared/LoginChecked";
import ProductsDropDown from "../Shared/ProductsDropDown";
import { NumberFormat } from "../Shared/Utility";

function PurchasesReport() {
  const [loading, setLoading] = useState(false);

  const [StartDate, SetStartDate] = useState(
    dayjs().subtract(60, "day").format("YYYY-MM-DD")
  );
  const [EndDate, SetEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [orderby, SetOrderBy] = useState("1");
  const [VehicleNo, setVehicleNo] = useState("");
  const [PurchaseReport, setPurchaseReport] = useState();
  const [ProductID,setProductID]=useState(0);
  const [SupplierAccounts, setSupplierAccounts] = useState([]);
  const [SupplierID,setSupplierID] = useState(0);
  
  const { RangePicker } = DatePicker;
  const productTypesArray = ProductsDropDown();

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(2000);
  const [totalRecords, setTotalRecords] = useState(0);
  const [PurchaseData, setPurchaseData] = useState([]);

  useEffect(() => {
    document.title = "Purchase Report";

    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      OrderBy: orderby,
      PageNo: pageNo,
      PageSize: pageSize,
      StartDate: StartDate,
      EndDate: EndDate,
      ProductID: ProductID,
      SupplierID: SupplierID,
      VehicleNo: VehicleNo,
    };



    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetPurchaseReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        
        if (response.data.status_code == 1) {
          setPurchaseReport(response.data.listofPurchase);
          setPurchaseData(response.data);

          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [StartDate, EndDate, pageNo, pageSize,ProductID,SupplierID]);


  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Account_Type_ID: 15,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountsByType",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
       
        if (response.data.status_code == 1) {
          let list = response.data.listofAccounts;

          const result = list.map((item) => ({
            value: item.ID,
            label: item.Name,
          }));

          // Handle success
          setSupplierAccounts(result);
          setLoading(false);
        } else {
          setLoading(false);
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  const columns = [
    {
      title: "Sr.#",
      dataIndex: "Sr",
      key: "SR",
      render: (_, record, index) => index + 1 + pageSize * (pageNo - 1),
    },
    {
      title: "Dated",
      dataIndex: "Created_Date",
      key: "Dated",
      render: (date) => moment(date).format("DD MMM YYYY"),
    },
    {
      title: "Supplier",
      dataIndex: "ProductCode",
      key: "Supplier",
      render: (_, record) => record.Account_BE.Name,
    },
    {
      title: "Product",
      dataIndex: "Product",
      key: "Product",
      render: (_, record) => record.Product_BE.Name,
    },
    {
      title: "Vehicle No ",
      dataIndex: "Vehicle_No",
      key: "VehicleNo",
    },
    {
      title: "Price",
      dataIndex: "Price",
      key: "Price",
      align: "right",
    },
    {
      title: "	Quantity",
      dataIndex: "Quantity",
      key: "	Quantity",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      align: "right",
      render:(_,record)=>NumberFormat(record.Amount,0)
    },
  ];

  const getFooterContent = () => (
    <Row gutter={[16, 16]}>
      {/* First Column */}

      {/* Second Column */}
      <Col md={8} xs={24}>
        <Row>
          <Col md={10} xs={20}>
            <p>
              <b>Total Petrol (Ltr):</b>
            </p>
          </Col>
          <Col md={6} xs={4}>
            <p>{PurchaseData.totalPetrol}</p>
          </Col>
        </Row>
      </Col>
      {/* Third Column */}
      <Col md={10} xs={24}>
        <Row>
          <Col md={10} xs={20}>
            <p>
              <b>Total Diesel (Ltr):</b>
            </p>
          </Col>
          <Col md={6} xs={4}>
            <p>{PurchaseData.totalDiesel}</p>
          </Col>
        </Row>
      </Col>

      <Col md={6} xs={24}>
        <Row>
          <Col md={17} xs={20}>
            <p>
              <b>Total Purchase:</b>
            </p>
          </Col>
          <Col md={6} xs={4}>
            <p>{PurchaseData.totalPurchase}</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  const handleDateChange = (date, value) => {
  
    SetStartDate(value[0]);
    SetEndDate(value[1]);
  };

  const disabledDate=(current)=>{
    return current && current >moment().endOf('day');
  }

  const handleProductChange = (value) => {
    setProductID(value);
  };

  const handelSupplierId = (value) => {
    setSupplierID(value);
  };

  const handelVehicleNo = (e)=>{
    setVehicleNo(e.target.value)
  }
  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Purchase Report</h3>

            <div className="header-actions">
              <NavLink
                to={`/report-Purchase/print-list/?StartDate=${StartDate}&EndDate=${EndDate}&Orderby=${orderby}&ProductID=${ProductID}&SupplierID=${SupplierID}&VehicleNo=${VehicleNo}`}
                target="_blank"
              >
                <Button
                  type="dashed"
                  size="large"
                  shape="round"
                  // style={{ marginBottom: 10 }}
                >
                  <PrinterOutlined />
                  Print Report
                </Button>
              </NavLink>
            </div>
          </div>
          <div className="filters-wrap">
            <Form>
              <Form.Item>
              <RangePicker allowClear onChange={handleDateChange} 
              disabledDate={disabledDate}/>
              </Form.Item>
              <Form.Item>
                <Select
                  allowClear
                  showSearch
                  style={{
                    width: "160px",
                  }}
                  placeholder="All Products"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  loading={productTypesArray ? false : true}
                  options={productTypesArray}
                  onChange={handleProductChange}
                />
              </Form.Item>
              <Form.Item>
                <Select
                  allowClear
                  showSearch
                  style={{
                    width: "160px",
                  }}
                  placeholder="All Suppliers"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  loading={SupplierAccounts ? false : true}
                  options={SupplierAccounts}
                  onChange={handelSupplierId}
                />
              </Form.Item>
              <Form.Item>
              <Input
                  placeholder="Vehicle No"
                  value={VehicleNo}
                  onChange={handelVehicleNo}
                />
              </Form.Item>
              
            </Form>
          </div>

          <Table
            size="small"
            scroll={{
              x: "100%",
            }}
            loading={loading}
            columns={columns}
            dataSource={PurchaseReport}
            footer={getFooterContent}
            // onChange={handleTableChange}
            pagination={false}
            bordered
          />
        </div>
      </div>
    </>
  );
}

export default PurchasesReport;
