import React from 'react';
import { NavLink } from 'react-router-dom';

function SecurityMenu(props) {
  return (
    <ul className="sub-menu">
      <li>
        <NavLink to="/security/change-password">Change Password</NavLink>
      </li>
      <li>
        <NavLink to="/security/change-company-info">Change Company Info</NavLink>
      </li>
      <li>
        <NavLink to="/security/login-logs">Login Logs</NavLink>
      </li>
      <li>
        <NavLink to="/security/manage-users">Manage Users</NavLink>
      </li>
      <li>
        <NavLink to="/security/adjust-ledger">Adjust Ledger</NavLink>
      </li>
      
    </ul>
  );
}

export default SecurityMenu;
